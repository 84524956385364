import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import ContentTracking from '../../../core/progress/ContentTracking';
import './backgroundimage.scss'
import DataHelper from '../../../utils/DataHelper';
import profile_icon from '../../../assets/img/profile_icon.png';
import ImageTag from '../../ui/ImageTag';

const debouncing = (selector, callback, isLongContent) => {
    const isView = isLongContent || null
    setTimeout(() => {
        let element = document.querySelector(selector);
        let position = element?.getBoundingClientRect();
        const isMobile = (window.matchMedia('max-width: 768px').matches || window.matchMedia('max-height: 450px').matches)
        // checking for partial visibility
        if ((position?.top <= window?.innerHeight && position?.bottom >= 0) && (isMobile || isView)) {
            callback()
        } else {
            // checking whether fully visible
            if (position?.top >= 0 && position?.bottom <= window.innerHeight) {
                callback()
            }
        }

    }, 100)
}

const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS


const BackgroundImage = ({ bgImageUrl, header, opacity, backgroundColor, backgroundPosition, padding, profileImage, description, track, handler, cename, background, textPosition,styleObj, tp_status }) => {
    const contentLayout = document.querySelector('.content')
    const [status, setStatus] = useState({ completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE })
    const topicRef = useRef('');
    const isQuotes = cename === 'Quotes';

    const getBgImageUrl = (img) => {
        return DataHelper.getResourcePath(0, img);
    }

    useEffect(()=>{
        if(tp_status > -1) updateProgress();
    },[tp_status])

    const updateProgress = () => {
        /**
         * View status, will update while navigating to next topic
         */
        track.state = { ...track.state, c: 1 }
        /**
         * Progress updated as and when its completed
         */

        if (!track.status) {
            track.status = 1;
            if (track.status) {
                setStatus({ completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE })
                handler({ type: UPDATE_STATUS, id: track.id, name: cename })
            }
        }
        /**
         * If anything to be intimated always
         * call this
         */
        // handler({ type: UPDATE_STATUS, id: track.id, name:cename })

    }

    
    contentLayout?.addEventListener('scroll', () => {
        DataHelper.scrollCompletionDebouncing(`section[name=${cename}]`, updateProgress, true)
    })



    const hex_to_RGB = (hex = "#ffffff") => {
    try{
        if (hex) {
            var m = hex != "#fff" ? hex?.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i) : "#ffffff".match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
            return {
                r: parseInt(m[1], 16),
                g: parseInt(m[2], 16),
                b: parseInt(m[3], 16),
            };
        }
    }catch(e){return '#000'}
    }
    const backgroundImageSrc = getBgImageUrl(bgImageUrl)
    const imgStyleObj = {
        width: "100%",
        height: "100%",
        background: ` url(${backgroundImageSrc}) ${background || '50% 50% / cover no-repeat'} `,
    }

    let profileStyle={
        height:`${styleObj?.imageSize}px`,
        width:`${styleObj?.imageSize}px`
       }
      
    let isHalf=styleObj?.imagePosition?.includes('half');

    const contentSection=()=>{
        return <>
                {/* <h2 className='snippet-head-tag'  style={styleObj}>{header}</h2> */}
                <div className=' row snippet-description' style={{ padding:padding * 10 || '30px', maxHeight:"450px", overflow:"hidden",overflowY:"auto" }}>
                    {isQuotes && <div className={`image-box ${styleObj?.imagePosition} ${isHalf ?'col-lg-2':'col-lg-12'  }`}>
                          <div className={`quotes-profile-img`} style={profileStyle} >
                            <img src={getBgImageUrl(profileImage)} onError={(e)=>{e.target.onerror = null; e.target.src=profile_icon}} ></img>
                          </div> 
                    </div>}
                    <div  className={`text-content ${isHalf ?'col-lg-10':'col-lg-12'}`} style={{maxHeight:"500px"}} dangerouslySetInnerHTML={{ __html: description }} ></div>
                </div>
        </>
    }

    return <>
        <Instruction isInstruction={false} completed={(track?.status === 1 || status?.completed)} title={(track?.status === 1 || status?.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track?.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} />

        <div  className='snippet-div bg-custom-text-position' ref={topicRef}>
          <ImageTag
           img={bgImageUrl}
           bgColor={backgroundColor}
           position={background}
           maxHeight={500}
           minHeight={500}
           opacity={opacity}
           children={<>
               {/* <div  className="hidden-text"> {contentSection()}</div> */}
               <div className='snippet-div-main' style={{ padding: '8px', position: "absolute", width: "100%", alignItems: textPosition }}> {contentSection()} </div>
           </>}
          >
         
          </ImageTag>
            {/* <div style={{ position: "absolute", width: "100%", height: "100%" }}>
                <div style={imgStyleObj}></div>
            </div>
            <div className="backgroundColor" style={{ width: "100%", height: "100%", backgroundColor: backgroundColor ? `rgba(${hex_to_RGB(backgroundColor).r},${hex_to_RGB(backgroundColor).g},${hex_to_RGB(backgroundColor).b},${opacity})` : "" }}></div> */}
            {/* <div className='snippet-div-main' style={{ padding: '8px', position: "absolute", width: "100%", alignItems: textPosition }}> {contentSection()} </div> */}
        </div>

    </>
}

BackgroundImage.propTypes = {
    bgImageUrl: PropTypes.string,
    /** Heading title of the component */
    header: PropTypes.string,
    /** Text content of component */
    description: PropTypes.string,
    /**  Tracking the component progress */
    track: PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler: PropTypes.func
}

export default BackgroundImage