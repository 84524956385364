const ComponentConstants = {
    STATUS_COMPLETE: 'Completed',
    STATUS_INCOMPLETE: 'Incomplete',
    UPDATE_STATUS: 'UPDATE_STATUS',
    SAVE_PROGRESS_STATUS: 'SAVE_PROGRESS_STATUS',

    WYSIWYG: 'WYSIWYG',
    SAVE_COMP_PROPS: 'SAVE_COMP_PROPS',
    SAVE_COMP_PROGRESS_PROPS: 'SAVE_COMP_PROGRESS_PROPS',

    INSTRUCTIONS_PASSED: 'You have completed this interactivity, you can proceed to next section',
    COMPLETED_CLASS: 'completed',
    RESOURCED_COMPONENTS: [
        "Accordion",
        "Audio",
        "BackgroundImage",
        "CardStackCarousel",
        "Carousels",
        "CourseCardFlip",
        "CourseImageFlip",
        "HalfImageText",
        "ImageText",
        "ImageSelect",
        "Tabs",
        "Video",
        "ArchiveVideo",
        "Attachments",
        "ImageMapper"
    ]
}

export default ComponentConstants;