import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
// import PropTypes from 'prop-types'
import './uploaderpanel.scss'
import { useToasts } from 'react-toast-notifications';
// import { CircularProgress } from '@material-ui/core';
// import { LinearProgress  } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';
import * as ceAssetsInfoAction from '../../../redux/actions/ceAssetsInfoAction';
import PlayerConstants from '../../../utils/PlayerConstants';
import AssetUploader from './AssetUploader/AssetUploader';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import DeleteIcon from '@mui/icons-material/Delete';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ColorPickr from "../ColorPickr/ColorPickr"
import CustomSweetAlert from "../../../components/ui/CustomSweetAlert/CustomSweetAlert"
import ACLHelper from '../../../utils/ACLHelper';
import BuildHelper from '../../../utils/BuildHelper';

/**
 * UploaderPanel Component
 * 
 * This component allows users to upload various types of assets (images, videos, audios)
 * and select assets from a library. It also provides UI for managing uploaded assets.
 */
const UploaderPanel = ({ accept, name, setUploadedData, multiple, fileName, isAssetUploader, styles, pChange, bgChange }) => {
    const { addToast } = useToasts();
    const [loader, setLoader] = useState(false)
    const [isUploaded, setUploadComplete] = useState(false);
    const [isAssetUpload, setIsAssetUpload] = useState(false);
    const [isDeleteWarning, setIsDeleteWarning] = useState(false);
    const {ceAssetsInfo , courseEdit:{target},ceAssetsInfo:{archiveCourses}} = useSelector((state) => state);
    const dispatch = useDispatch();
    let isCeAssets=PlayerConstants?.ASSETS_USED_COM?.indexOf(target?.cmp?.name||"");
    const keys = Object.keys(styles||{});
    const values = Object.values(styles||{});
    const isImageStyle=PlayerConstants?.IMAGESTYLES?.includes(target?.cmp?.name||"") && styles 

    let assetData = [];
    if (accept === 'image') { assetData = ceAssetsInfo?.images; };
    if (accept === 'video') { assetData = ceAssetsInfo?.videos; };
    if (accept === 'audio') { assetData = ceAssetsInfo?.audios; };


    /** on success reponse will be like this */
    //     let response =[{
    //     "result":1,
    //     "status":"success",
    //     "data":{
    //     "filename":"medical_card.png",
    //     "name":"618e025e9da34f0ca5af369c_5",
    //     "path":"https://s3.amazonaws.com/thumbs.contentenablers.com/rlm/images/medical_card.png"
    //     }
    // }]
    /**
     * Retrieves the appropriate upload URL based on the environment.
     */
    const getUploadUrl = () => {
        const apihost = PlayerConstants.STG_HOSTNAMES.indexOf(window?.location?.hostname) !== -1  ? 'https://tradecompliance.training/xapice/api/assets/staginguploadAssets' : 'https://api-cea.contentenablers.com/api/assets/uploadresource'
        // const apihost = window.location.href.includes("localhost") ? 'http://localhost:3000/api/resources/upload/' : 'https://tradecompliance.training/xapice/api/resources/upload/'
        return apihost;
    }

    const sendChunks = async (data, fname) => {
        //uploadLearningResources
        console.log(data?.byteLength)
        const CHUNK_SIZE = 10000//data.byteLength / 10 //1000;
        const CHUNK_COUNT = data?.byteLength / CHUNK_SIZE;
        // for (let chunkId = 0; chunkId < CHUNK_COUNT + 1; chunkId++) {
        //  const chunk = data.slice(chunkId * CHUNK_COUNT, chunkId * CHUNK_COUNT + CHUNK_SIZE);

        const response = await fetch(getUploadUrl(),
            {
                "method": "PUT",
                "headers": {
                    "content-type": "application/octet-stream",
                    "content-length": data?.length,
                    "upload-id": fname

                },
                "body": data
            }
        )


        // .then(response => {  console.log(response) ; return response})
        // .catch(err => console.error(err));

        //console.log((chunkId * 100/CHUNK_COUNT), '%')
        // }
        return response

    }

    const handleSubmission = async (file) => {
        setLoader(true);
        let srcName = name ? name : 'file';
        const formData = new FormData();
        formData?.append(srcName, file);
        // console.log(srcName, file)
        const reader = new FileReader()
        const chunks = []

        reader.onload = async (event) => {
            try {
                let fn = file?.name?.split(".");
                const fileName = `${srcName}.${fn[fn.length-1]}`
                // console.log(event,fileName)
                const data = await sendChunks(event.target.result, fileName);
                data.json().then(function (resJson) {
                    if (resJson?.status === 'success') {
                        setUploadedData(resJson?.data);
                        setLoader(false);
                        setUploadComplete(true)
                        addToast('Uploaded Successfully', { appearance: 'success' });
                    }
                })
            } catch (error) {
                console.log('Cant fetch file')
            }
            //     data.blob().then(function(myBlob) {
            //     console.log(myBlob)
            //   });

            // dispatch(utilityAction.assetUpload({data:event.target.result, file:srcName}));

        }
        reader.loadend = async (event) => {

        }
        reader.readAsArrayBuffer(file);
        /**
         * 
         */
        /* fetch(getUploadUrl(), { method: 'POST', body: formData } )
             .then( ( response ) => response.json() )
             .then( ( result ) => {
                 if(result && result.length > 0){
                     // console.debug("***************** Upload Success******************",result[0].data)
                     if(result[0].status === "success"){
                         setUploadedData(result[0].data);
                         setLoader(false);
                         setUploadComplete(true)
                         addToast('Uploaded Successfully', { appearance: 'success'});
                     }else{
                         addToast('Error', { appearance: 'error'});
                     }
                 }else{
                     addToast('Error', { appearance: 'error'});
                 }
             } )
             .catch( ( error ) => {
                 console.error( 'Error:', error );
                 addToast('Error', { appearance: 'error'});
             } );
             */
    };


    const maxSize = 10485766;

    const onDrop = useCallback((acceptedFiles) => {
        // console.log( 'dropped media is ', acceptedFiles[ 0 ] );
        // setUploadedData( acceptedFiles[ 0 ] );
        handleSubmission(acceptedFiles[0])
    }, []);

    const { isDragActive, getRootProps, getInputProps, isDragReject, acceptedFiles, rejectedFiles } = useDropzone({
        onDrop,
        accept: `${accept}/*`,
        minSize: 0,
        multiple: true
        // maxSize,
    });

    const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

    const modelHandle = () => {
        if (assetData?.length === 0) { dispatch(ceAssetsInfoAction?.ceAssetsRequest()); }
       if(target?.cmp?.name==="ArchiveVideo" && archiveCourses?.length <= 0 && accept ==="video") { dispatch(ceAssetsInfoAction?.getArchiveCourseRequest()); }
        setIsAssetUpload(!isAssetUpload);
    }

    const assetLink = (item) => {
       try{
        setUploadComplete(false);
        if (item) { // s3 cloud path
            let assetPath=item?.path||"";
            if( assetPath?.includes("https://s3.amazonaws.com")){
                const replace=assetPath?.split('.com')||[assetPath]
                assetPath = `${PlayerConstants?.S3ASSETSPATH + replace[replace?.length-1]}`
            }
            setUploadedData({ ...item, name: assetPath});
            setUploadComplete(true)
            addToast('Linked Successfully', { appearance: 'success' });
            setIsAssetUpload(!isAssetUpload);
        }
    }catch(e){}
    };

    const getFileName = (fileName) =>{
        try{
            if(fileName?.includes('via.placeholder.com')||fileName==="*" )
                return '';
            if(fileName?.includes('https')){
                let splitArr=fileName?.split('/');
                let file=splitArr[splitArr?.length-1];
                if(file?.includes('hls_')) file=`${splitArr[splitArr?.length-2]}/${file}`;
                return file
            }
            else{
                return ` ${fileName} `;
            }
        }catch(e){ }
    }
    const copyToClipboard = (param) => {
        if(param?.indexOf('library.contentenablers.com/manager/') !== -1){
            navigator?.clipboard.writeText(`https://ceassets.contentenablers.com/images/${getFileName(param).split('.')[0]}`)
        }
        else if(param.indexOf('thumbs.contentenablers.com/assetslib/') !== -1){
            navigator?.clipboard?.writeText(`https://assets.contentenablers.dev/images/${getFileName(param).split('.')[0]}`)
        }
        else{
            navigator?.clipboard?.writeText(getFileName(param))
        }
        
        addToast('File Name copied successfully', { appearance: 'success' });
    }
    const uploadType = accept?.includes(".pdf") ? "File" : accept

    const handleDelete=()=>{
        setUploadedData("*");
        setIsDeleteWarning(false);
    }

    const userAgent = navigator?.userAgent?.toLowerCase()||[];
    const colorPickr = {
      color: values[1] || '#000',
      saveEvent: (v) => bgChange({ target: { value: v, name: keys[1] } }),
      btn: {
        save: { isSave: true, name: 'Save', ishide: false },
        cancel: { isCancel: !userAgent.includes('firefox'), name: `&#x270E`, ishide: false, isch: true }
      },
      type: 'RGBA',
      icon:true
    };


    return (
<div className="upload-container">
    {isAssetUpload && <AssetUploader model={modelHandle} data={assetData} asset={(e) => assetLink(e)} type={accept} />}
    <div className="row">
        {(target?.cmp?.name === "ArchiveVideo" && accept === "video") ? null : (
            <div className={`col ${isImageStyle ? "image-style" : "default-style"}`}>
                <div className="uploader-panel" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {!isDragActive && (
                        <span className="upload-summery">
                            <CloudUploadIcon className="upload-icon" />
                        </span>
                    )}
                    {isDragActive && !isDragReject && "Drop it"}
                    {isDragReject && "File type not accepted, sorry!"}
                    {isFileTooLarge && (
                        <div className="text-danger mt-2">
                            File is too large.
                        </div>
                    )}
                </div>
            </div>
        )}
        {(isCeAssets > -1 || isAssetUploader) && ACLHelper.isFeatureEnabled(4, 2) &&
        !(BuildHelper?.isConfig() && PlayerConstants?.CONFIG_UNUSED_ASSETS?.some(item => item === accept)) && (
            <div className={`col ${isImageStyle ? "image-style" : "default-style"}`}>
                <div className="asset uploader-panel" onClick={modelHandle}>
                    <input {...getInputProps()} />
                    <span className="upload-summery">
                        <ArtTrackIcon className="upload-icon" />
                    </span>
                </div>
            </div>
        )}
        {isImageStyle && values[0] && (
            <div className="col position-select">
                <FormControl size="medium" className="common-position-select">
                    <InputLabel id="demo-select-small">Image Position</InputLabel>
                    <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={values[0] || ""}
                        label="Align Text"
                        onChange={pChange}
                        className="select-box"
                        name={keys[0] || ""}
                    >
                        {imagePosition.map((item, idx) => (
                            <MenuItem 
                                key={idx} 
                                disabled={item?.value === "select"} 
                                className="select-option-list" 
                                value={item.value}
                            >
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        )}
        {isImageStyle && (
            <div className="col icon-picker">
                <ColorPickr {...colorPickr} />
            </div>
        )}
        {/* {(fileName && getFileName(fileName) !== '') && (
            <div className="col file-name">
                <div>
                    <ContentCopyIcon onClick={() => copyToClipboard(fileName)} />
                    {getFileName(fileName)}
                    {(PlayerConstants?.IMAGEDELETE?.includes(target?.cmp?.name || '') || isAssetUploader) && (
                        <DeleteIcon title="delete" className="delete-icon" onClick={() => setIsDeleteWarning(true)} />
                    )}
                </div>
            </div>
        )} */}
    </div>
    {isDeleteWarning && (
        <div className="warning-popup danger-popup">
            <CustomSweetAlert
                warning
                showCancel
                className="confirm-popup"
                show={isDeleteWarning}
                confirmBtnText="Delete"
                confirmBtnBsStyle="danger"
                title={<p className="sweet-title">Are you sure you want to delete this image?</p>}
                onConfirm={() => handleDelete()}
                onCancel={() => setIsDeleteWarning(false)}
            />
        </div>
    )}
    { (fileName && getFileName(fileName) !== '') && <div className="copy-file-name pt-2">
        <ContentCopyIcon onClick={() => copyToClipboard(fileName)} />
         <span className='name'>{getFileName(fileName)}</span>
        {((PlayerConstants?.IMAGEDELETE?.includes(target?.cmp?.name) || isAssetUploader) && accept==="image") && (
            <DeleteIcon title="delete" className="delete-icon" onClick={() => setIsDeleteWarning(true)} />
        )}
    </div>}
     {(loader || (isUploaded &&  acceptedFiles?.length > 0))&&<div className="col uploaded-files-container">
            <ul className="uploaded-files">
                {loader && (
                    <div className="loader-info">
                        <LinearProgress color="success" />
                        <p style={{ fontSize: 12, marginTop: 3 }}>Uploading...</p>
                    </div>
                )}
                {isUploaded && acceptedFiles?.length > 0 && acceptedFiles.map((acceptedFile, index) => (
                    <li key={index} className="list-group-item list-group-item-success">
                        {acceptedFile?.name}
                    </li>
                ))}
            </ul>
    </div>}
</div>

        
    );
};

export const imagePosition = [
    { name: 'Select Position', value: 'select' },
    { name: 'Actual Size', value: 'center  / contain no-repeat' },
    { name: 'Place Left', value: ' left top / cover no-repeat' },
    { name: 'Place Right', value: 'right top / cover no-repeat' },
    { name: 'Spread', value: ' 100% 100% / cover no-repeat' },
    { name: 'Center & Spread', value: '50% 50% / cover no-repeat' },
    { name: 'Repeat', value: 'center / contain repeat' },
]

export default UploaderPanel;