import BaseActivity from "./StmtElements";
import CryptoJS from 'crypto-js';

class XAPIHelper {

    
	constructor(user, course )	{
		this.user = user;
		this.course = course;


		this.basestmt = {}
		this.basestmt.actor = {}
		this.basestmt.verb = {}
		this.basestmt.object = {}
		
		this.basestmt.actor.mbox = 'mailto:'+user.email
		this.basestmt.actor.name =  user.firstname + ' ' + user.lastname;

		this.platform = {url:'contentenablers.com'}

        const base = BaseActivity();
        this.base_activity = base.base_activity;
        this.base_verbs = base.base_verbs;
        this.xapi_actions = base.xapi_actions;


	}




	getStatement = (type,param) =>{
		let stmt = {}
		// deep cloning method
		stmt = JSON.parse(JSON.stringify(this.basestmt)); 
		let activity = JSON.parse(JSON.stringify(this.base_activity))
        const XAPIConstants = this.xapi_actions;

		switch(type)	{
			case XAPIConstants.LAUNCH:
				stmt.verb = this.base_verbs.launched
				activity.course.definition.name['en-US'] = this.course.course_name;
				activity.course.definition.description['en-US'] = this.course.course_name+" launched from "+ this.platform.url  + this.getFootprint(param);
				stmt.object = activity.course
			break

			case XAPIConstants.TOPIC :
				stmt.verb = this.base_verbs.experienced
				activity.topic.definition.name['en-US'] = param.name
				activity.topic.definition.description['en-US'] = param.name +' topic started with id '+param.id +'  '+ this.getFootprint(param);
				stmt.object = activity.topic

			break;

			case XAPIConstants.PAUSE:
				stmt.verb = this.player_verbs.paused
				activity.video.definition.name['en-US'] = param.name
				activity.video.definition.description['en-US'] = 'Video file '+param.file+ ' '+ 'paused at ' +param.time + ' and the topic is ' + param.name + ' ' +this.getFootprint(param);			
				stmt.object = activity.video
			break

			case XAPIConstants.PLAY :
				stmt.verb = this.player_verbs.played
				activity.video.definition.name['en-US'] = param.name
				activity.video.definition.description['en-US'] = 'Video file '+param.file+ ' '+ 'played at ' +param.time + ' and the topic is ' + param.name + ' ' + this.getFootprint(param);
				stmt.object = activity.video
			break

			case XAPIConstants.CC :
				stmt.verb = this.player_verbs['cc-subtitle-enabled']
				activity.video.definition.name['en-US'] = param.name
				activity.video.definition.description['en-US'] = 'Opened closed caption for '+param.file + ' and the topic is ' + param.name + ' ' + this.getFootprint(param);
				stmt.object = activity.video
			break

			case XAPIConstants.TXTCONTENT:
				stmt.verb = this.base_verbs.experienced
				activity.txtcontent.definition.name['en-US'] = param.name
				activity.txtcontent.definition.description['en-US'] = 'Opened text content for '+param.file + ' and the topic is ' + param.name + ' ' + this.getFootprint(param);
				stmt.object = activity.txtcontent
			break;

			case XAPIConstants.TOPICDONE:
				stmt.verb = this.base_verbs.experienced
				activity.topicdone.definition.name['en-US'] = param.name
				activity.topicdone.definition.description['en-US'] = param.name+ ' topic completed with id '+param.id +' '+ this.getFootprint(param);
				stmt.object = activity.topicdone
			break;
			case XAPIConstants.SCORED:
			case XAPIConstants.INTERACTED:
				let name=param?.file||'';
				let action= (type?.charAt(0)?.toUpperCase() || 'I') + (type?.slice(1)?.toLowerCase() || 'nteracted');
				stmt.verb = this.base_verbs[type]
				activity[type].definition.name['en-US'] = name
				activity[type].definition.description['en-US'] = action + ' with '+param.file +' in the topic '+ param.name + this.getFootprint(param);
				stmt.object = activity[type]
				// If the lrn_id is present, add it to lrn_id
				if(param && param?.lrn_id){
					name=param.file+'_'+param?.lrn_id;
					stmt.object.definition.name['en-US']=name;
					stmt.object.id=stmt.object.id+param?.lrn_id;
				}
				break;
			case XAPIConstants.COMPLETED:
				stmt.verb = this.base_verbs.launched
				activity.course.definition.name['en-US'] = this.course.course_name;
				activity.course.definition.description['en-US'] = this.course.course_name+" has been finished on "+ this.platform.url  + this.getFootprint(param);
				stmt.object = activity.course
			break;

			
		}

		return stmt;
	}
	/**
	* Unique id mapping on the course assignment
	**/
	getStateId = () => {
		let encoded = (this.user.pvtid+'_'+this.user.uid+'_'+this.course.course_Id);
		return CryptoJS.SHA1(encoded).toString();
	}

	getFootprint = (param) => {
		let topicId = param && param?.id || '0';
		let ln_id = param && param?.ln_id||'0';
		let lrn_type = param && param?.lrn_type||'0';
		ln_id=ln_id?.trim() //this.encodeBase64Utf8(ln_id?.trim()); 
		/*
		#### topicId.replace(/\D/g, "") - we removed it
		* While displaying the quiz score, we use the topic ID.
		* We remove non-numeric characters from the topic ID using topicId.replace(/\D/g, "").
		* We then replace it by encoding the string with btoa(unescape(encodeURIComponent(...))).
		* 
		*/
		topicId =topicId?.trim()
		if(param && param?.lrn_id){
			let lrnId =param?.lrn_id?.trim()// this.encodeBase64Utf8(param?.lrn_id?.trim()||"");
		    topicId = topicId+'_'+lrn_type+'_'+lrnId
			if(param?.file==="Quizzes"){ 
				 // Adding 'h' to topicId for easier Quiz fetching in the backend
				topicId = topicId+'_'+lrn_type+'h_'+lrnId
			}
			// topicId = topicId+'_'+lrnId;
			if(param?.track){
				let{id,state, ...others}=param?.track
				topicId+='^'+JSON.stringify({id,state})+'^'
		  }
		}
		//encoding it in UTF-8 to handle special characters
		let encoded = btoa(unescape(encodeURIComponent(this.user.pvtid+'_'+ln_id+'_'+this.user.uid+'_'+this.course.course_Id+'_'+topicId)));
	
		return '|+|'+encoded
	
	}

	// getFootprint = (param) => {
	// 	let topicId = param && param.id || '0';
	// 	topicId = topicId.replace(/\D/g, "");
	// 	if(param && param.lrn_id){
	// 		let lrnId = param?.lrn_id?.replace(/\D/g, "")||"";
	// 	    topicId = topicId+'_'+lrnId
	// 	}
	// 	let encoded = btoa(this.user.pvtid+'_'+this.user.uid+'_'+this.course.course_Id+'_'+topicId);
	// 	return '|+|'+encoded
	// 	// return '|+|'
	// }

	getBaseState = () =>	{
		return {
			agent : '{"mbox":"'+this.basestmt.actor.mbox+'"}',
			activityId : "http://adlnet.gov/expapi/activities/collection",
		}
	}
		

	}

export default XAPIHelper;