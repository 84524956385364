import React, { useEffect, useState } from 'react'
import './coursepanel.scss'
import collections from '../../../core/template/collections'
import { Button,Select,Modal } from '@material-ui/core';
import { useDispatch,useSelector } from 'react-redux';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import SweetAlert from 'react-bootstrap-sweetalert';
import ArchiveVideo from '../../../utils/ArchiveVideo';
import DataHelper from '../../../utils/DataHelper';
import ContentTracking from '../../../core/progress/ContentTracking';
import TEMPLATE_MODEL from '../../../utils/TemplateModel';
import PlayerConstants from '../../../utils/PlayerConstants';
import CustomSweetAlert from '../../../components/ui/CustomSweetAlert/CustomSweetAlert'

const CoursePanel = ( {data} ) => {
    const dispatch = useDispatch();
    let tp = collections
    let courselist = [];
    const [ selectedCollection, setCollection ] = useState(tp[0])
    const [ selectedJuri, setJuri ] = useState([])
    const [ selectedCourse, setCourse ] = useState("")
    const [ selectedTopic, setTopic ] = useState("")
    const courseInfo = useSelector(state => state.courseInfo);
    const userInfo = useSelector(state => state.userInfo);

    const [isAddConfirm,setIsAddConfirm] = useState(false)

    tp.forEach((item)=>{
        courselist.push(item.name)
    })

    // setCollection(tp[0])

    const selectTopic = ( el ) => {
        console.log("selectTopic",el)
        setTopic(el)
        console.log("dispatch action")
        dispatch(courseInfoAction.getVideoAddonItems(el.id))
        console.log("courseInfo",courseInfo)
    }

    const onCollectionChange = (e)=>{
        var collection = tp.filter(function(item) {
            return item.name == e.target.value
        })
        setCollection(collection)
        setCourse("")
        setJuri([])
        
    }
    

    const onJurisdictionChange = (e)=>{
        let juri = selectedCollection[0].countries[e.target.value]
        setJuri(juri)
        setCourse("")
    }

    const onCourseChange = (e)=>{
        setCourse(e)
    }
    const onAddCancel = () =>{
        setIsAddConfirm(false)
    }

    const closeDrawer = ()=>{
        dispatch(courseEditAction.toggleDrawer({open:false, drawerWidth: 0}))
    }

    const addLearningAid = ()=>{
        try{
            let props = generateProps()
            setIsAddConfirm(false)
            let archivevideo = new ArchiveVideo({id:20,name:"ArchiveVideo",props:props,status:2,ccid:selectedTopic.id});
            addLearningAidItem(archivevideo)
            setCollection(tp[0])
            setJuri("")
            setCourse("")
            setTopic("")
            closeDrawer()
        }catch(e){
            console.log(e);
        }
    }

    /** 
     * Adding section and concat with exisiting markup 
     * compid will be lrncontent length 
     * 2 API has to used to differntciate type = "course" && type == config
     */
     const addLearningAidItem = (aidItem) => {
        try {
            let tp = courseInfo?.topic;
            let markup = tp?.markup;
            const newLrn = DataHelper.getNewLrn(data.compId, tp.markup, aidItem);
            aidItem.compid = newLrn.cmpid
            if (aidItem?.props) {
                /**TODO  cid*/
                let cid = PlayerConstants.getComponentByName(data.name).id +  ":" + aidItem.compid;
                console.debug("cid",cid)
                let trackobj = new ContentTracking(cid, 0, {})
                aidItem.props.track = trackobj;
            }
           
            // tp.markup = newLrn.markups; 
            // tp.lrncontent.push(aidItem);

            // console.debug(aidItem,"<=============topic =================>",newLrn);
            // console.debug(tp,"<=============topic =================>",newLrn);
            dispatch(courseEditAction.addNewLearningAid(Object.assign(newLrn,{lrnItem:aidItem, topic:tp, topicid:tp.id, author:userInfo.user.uid})));
        } catch (e) {
            console.log(e);
        }
    }
    
    const CourseTree = ( items,  treeElememt) => {
        // console.log("items",items)
        if(items != undefined){
            items.forEach((item, index) => 
            {   
                if(item.children ) {
                    CourseTree(item.children, treeElememt) 
                }
                else if(item.file !== "0")
                    treeElememt.push(<li key={item.id} className={ Number(item.id) == selectedTopic.id ? 'topic-li-active' : 'topic-li' }  onClick={ () => selectTopic( item, index )} >
                        <div className="item-name">{item.name}</div>
                        {/* {Number(item.id) === Number(selectedTopic.id) && <div className="btn-list">
                        <Button variant="outlined" className="mr10" color="secondary">Preview</Button>
                        <Button variant="outlined" className="archive-video" color="secondary" onClick={()=>{setIsAddConfirm(true)}}>Add</Button></div>}  */}
                    </li>)
            });
        }
       
        return treeElememt;
    }

/**
 * 
 * quiz,cuepoints(widget,supplement) ,content text and updating from the API 
 * template_id - 0 - supplement 
 * template_id - 1 - textwidget
 * template_id - 2 - imagewidget
 * template_id - 3 - docwidget
 * template_id - 4 - videowidget
 */
    const generateProps = () => {
        let cues = [];
        let index = 1;
        let module_text = ''
        if(Object.keys(courseInfo.addon).length>0){
            let cueList = courseInfo.addon?.cuepoints;
            if ( cueList.length > 0 ) {
                cueList.forEach( ( cueitem ) => {
                    let content = [];
                    if ( cueitem.template_id > 1 ) {
                        content = { filename: cueitem.filename, filepath: cueitem.filepath }
                    } else {
                        content = cueitem.widget_text;
                    }
                    cues.push( {
                        id: index,
                        type: cueitem.template_id,
                        data: {
                            header: cueitem.widget_title,
                            content: content
                        },
                        time: cueitem.cue_ms
                    } )
                    index++;
                } )
            }


            if ( courseInfo.addon.quiz.length > 0 ) {
                courseInfo.addon.quiz.forEach( ( quiz ) => {
                    cues.push( {
                        id: index,
                        type: 5,
                        data: { header: quiz.q_text, content: quiz.q_info },
                        time: null
                    } )
                    index++;
                } )
            }

            if ( courseInfo.addon.content.module_text != '' ) {
                module_text = courseInfo.addon.content.module_text
            }
        }
        let props = { "description" : "",
                        "src" : "https://d37bn5iomyf0su.cloudfront.net/"+selectedTopic.ccid
                                +"/"+selectedTopic.file+"/hls_1m_.m3u8",
                        "poster" : "/posters/display/128.jpg",
                        "cuepoints" : cues,
                        "content" : module_text,
                        "subtitleurl" : "https://apps.contentenablers.com/serviceapi/index.php/api/playerdata/getsubtitlevtt/id/"+selectedTopic.ccid+"/filename/"+selectedTopic.file+".vtt"}
        return props;
    }


    return <div className='course-panel'>
                <select  className='collection' id="collection" onChange={onCollectionChange }   >
                    { tp.map( ( el, index ) => <option value={ el.name } key={ index } >{ el.name }</option> ) }
                </select>
                {selectedCollection.length > 0 &&
                    <select className='jurisdiction' id="jurisdiction" onChange={ onJurisdictionChange }   >
                        { selectedCollection != null && selectedCollection.length>0 && Object.keys(selectedCollection[0].countries).map( ( el, index ) => <option value={ el } key={ index } >{ el }</option> ) }
                    </select>
                }
                {selectedJuri.length > 0 && 
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            <div className='title'>Available Courses</div>
                            <ul className='courselist'>
                                {selectedJuri.map((el,index)=>
                                    <li key = {index} className={ Number(el.id) === selectedCourse.id ? 'course-li-active' : 'course-li' } onClick={() => onCourseChange( el, index ) }>{el.cname}</li>
                                )}
                            </ul>
                        </div>
                        {selectedCourse !== '' && 
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='title'>Videos of {selectedCourse.cname}</div>
                                <ul className='topiclist'>
                                    {CourseTree((selectedCourse?.data != undefined) ? selectedCourse?.data?.children :
                                                (selectedCourse?.track?.children != undefined ? selectedCourse?.track?.children:selectedCourse?.track),[])}
                                </ul>
                            </div>  
                        }
                    </div>
                }
                { selectedTopic !== '' &&
                    <div className='row mb10 mr5 mt10'>
                        <div className='col-lg-8 col-md-8 col-sm-6'></div>
                        <div className='col-lg-4 col-md-4 col-sm-6'>
                            <div className='row'>
                                <button className='col-lg-4 col-md-4 col-sm-6 btn-cancel' onClick={closeDrawer}>Cancel</button>
                                <button className='col-lg-8 col-md-8 col-sm-8 btn-add' onClick={()=>{setIsAddConfirm(true)}}>Add Selected Video</button>
                            </div>
                        </div>
                    </div>
                }
               
               <div className='warning-popup'>
                <CustomSweetAlert 
                    warning
                    showCancel
                    show={ isAddConfirm }
                    confirmBtnText="Add"
                    confirmBtnBsStyle="success"
                    title="Are you sure you want to add this as Learning Aid ?"
                    text="Are you sure you want to add this as Learning Aid ?"
                    onConfirm={ () => addLearningAid() }
                    onCancel={ () => onAddCancel() }/>
                </div>
            </div>
}
export default CoursePanel;