/* eslint-disable default-case */
/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import './editdrawer.scss'
import AuthorInfo from '../AuthorInfo/AuthorInfo';
import { useSelector, useDispatch } from 'react-redux';
import * as courseEditAction from '../../../redux/actions/courseEditAction';
import PropertyPanel from '../PropertyPlaceHolder/PropertyPanel';
import TemplatePanel from '../TemplatePanel/TemplatePanel'
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import ComponentConstants from '../../../utils/ComponentConstants';
import PrintCourseMap from '../../../components/ui/PrintCourseMap/PrintCourseMap';
import TopicHistory from '../TopicHistory/TopicHistory';
import PlayerConstants from '../../../utils/PlayerConstants';
import CoursePanel from "../CoursePanel/CoursePanel";
import SaveTemplatePanel from '../SaveTemplatePanel/SaveTemplatePanel';
import TopicPanel from '../TopicPanel/TopicPanel';
import SearchLearningAids from '../SearchLrnAids/SearchLearningAids';
import DataHelper from '../../../utils/DataHelper';
import GlobalPropertyPanel from '../GlobalPropertyPanel/GlobalPropertyPanel';
import LearningAidComments from '../LRNComments/LearningAidComments';
import TopicValidationPanel from '../TopicValidationPanel/TopicValidationPanel';

/*
    drawer component that is used to edit / save the info about the content components
*/

const EditDrawer = ( props ) => {
    /*
      Here getting the drawer state and applying whether it should open/close 
    */
    const courseEdit = useSelector( state => state.courseEdit )
    const userInfo = useSelector( state => state.userInfo );
    const courseInfo = useSelector( state => state.courseInfo )
    const trackInfo = useSelector( state => state.courseTracking )
    const itemInfo = courseEdit.itemInfo;
    const width = courseEdit.drawerWidth

    const dispatch = useDispatch()

    useEffect( () => {
        console.log( 'updated!!!!' )
    }, [] )

    /*
      will toggle between the action of if it open/close
    */


    const toggleDrawer = ( value ) => {
        dispatch( courseEditAction.toggleDrawer( value ) )
    }

    const componentRenderer = ( infoProp ) => {
        switch ( courseEdit.panel ) {
            case 1:
                return <AuthorInfo itemInfo={ infoProp } handler={ authorHandler } />
            case 2:
                return <PropertyPanel data={ infoProp } handler={ propertiesHandler } />
            case 3:
                return <TemplatePanel data={ infoProp } panel={3} />
            case 4:
                return <PrintCourseMap />
            case 12: //publish version history
            case 5:
                return <TopicHistory />
            case 6:
                return <CoursePanel data={ infoProp } />
            case 7:
                return <SaveTemplatePanel />
            case 8:
                return <TopicPanel />
            case 9:
                return <SearchLearningAids data={ infoProp } />
            case 10:
                return <GlobalPropertyPanel data={ infoProp } />
            case 11:
                return <LearningAidComments data={ infoProp } />
            case 13:
                return <TemplatePanel data={ infoProp } panel={13} />
            case 14:
               return <TopicValidationPanel />
            default:
                return
        }
    }
    // updating publish latest data in courseEditingInfo reducer
    const updateLatestPublish=(val)=>{
        dispatch(courseEditAction.publishCourseInfo(val))
    }
  
    const propertiesHandler = ( eventInfo ) => {
        switch ( eventInfo.type ) {
            case ComponentConstants.WYSIWYG:
                dispatch( courseInfoAction.wysiwygLearningAid( { id: itemInfo?._id, data: eventInfo?.data } ) )
                break;
            case ComponentConstants.SAVE_COMP_PROPS:
                if(DataHelper?.isPublish()){
                    DataHelper.publishFormatting(eventInfo?.data,courseEdit,courseInfo,trackInfo,updateLatestPublish);
                    toggleDrawer( { open: false } );
                    return ;
                }
                dispatch( courseInfoAction.saveLearningAid( { id: itemInfo?._id, data: eventInfo?.data } ) )
                break;
        }
    }


    const authorHandler = ( eventInfo ) => {
        switch ( eventInfo.type ) {
            case 'close':
                toggleDrawer( { open: false } );
                break;
            case 'save_comment':
                dispatch( courseInfoAction.saveCommentRequest( eventInfo.data ) )
                break;
            case 'delete_comment':
                dispatch( courseInfoAction.deleteCommentRequest( eventInfo.data ) )
                break;
            case 'update_comment':
                dispatch( courseInfoAction.updateCommentRequest( eventInfo.data ) )
                break;
            default:

        }
    }

    /** UI title */
    const getTitle = ( name ) => {
        //PlayerConstants.COMPONENTS_LIST[itemInfo?.compid]?.title
        //let title;
        //const list = PlayerConstants.COMPONENTS_LIST;
        //for ( let key in list ) {
        //     if ( list[ key ].name === name ) {
        //         title = list[ key ].title
        //     }
        // }
        return PlayerConstants?.LRN_RE_NAME[name]
    }

    const getLearninAidId = () => {
        console.log( "itemInfo", itemInfo )
        try {
            if ( itemInfo)
                return DataHelper.getLearningAidId( courseInfo?.courseAttributes?.coursename,
                    courseInfo.topic?.nodeId, itemInfo?.compid, itemInfo?._id )
        } catch ( e ) {

        }
    }

    let overlay = courseEdit.isOpen && courseEdit.panel !== 10 ? 'open' : ''



    return (
        <div className='edit-drawer-component'>
            <div className="overlay-container">
                <div id="overlay" className={overlay} onClick={ () => toggleDrawer( { open: false, drawerWidth: 0 } ) }></div>
                <div id="edit-drawer-menu" className={ `${ courseEdit.isOpen ? 'open' : '' }` } style={ { width: width + '%' } }>
                    <div className='ce-drawer-header'>
                        <h5 className='ce-drawer-text-header'>{ getTitle( itemInfo?.name ) ? getTitle( itemInfo?.name ) : itemInfo?.name }</h5>
                        {itemInfo?._id && <p className='prop-id'>ID No: <span className='prop-hash-id'>
                            {getLearninAidId()}</span></p>}
                        <span className='ce-drawer-close-icon' onClick={ () => toggleDrawer( { open: false, drawerWidth: 0 } ) }><CloseIcon /></span>
                    </div>

                    <div className={ `ce-drawer-main-ui` }>
                        {/* {courseEdit.panel === 1 ?  <AuthorInfo itemInfo={itemInfo} /> :  } */ }
                        { componentRenderer( itemInfo ) }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditDrawer
