import * as types from '../reducers/types'

export function requestCoursInfo(courseId) {
    return {
        type: types.COURSE_INFO_REQ,
        payload:courseId
    }
}

export function responseCourseInfo(response)    {
    return{
        type:types.COURSE_INFO_RES,
        payload:response
    }
}

export function requestProgress(payload) {
    return {
        type : types.CRS_PROGRESS_REQ,
        payload
    }
}



export function selectedSection(section)    {
    return{
        type:types.SELECTED_SECTION,
        section:section
    }
}


export function selectedTopic(topic) {
    return{
        type:types.SELECTED_TOPIC,
        topic: topic
    }  
}



export function setSideBar(value) {
    return {
        type : types.SET_SIDEBAR,
        value
    }
}

export function setDrawer(value) {
    return {
        type : types.SET_DRAWER,
        value,
    }
}

export function updateQuizScore(value) {
    return {
        type : types.UPDATE_QUIZ_SCORE,
        value: value
    }
}

export function resumeQuiz(value) {
    return {
        type : types.RESUME_QUIZ,
        value: value
    }
}

export function saveCommentRequest(payload) {
    return {
        type : types.SAVE_COMMENT_REQ,
        payload
    }
}


export function saveCommentResponse(payload) {
    return {
        type : types.SAVE_COMMENT_RES,
        payload
    }
}

export function wysiwygLearningAid(payload) {
    return {
        type : types.WYSIWYG_LEARNING_AID,
        payload
    }
}

export function saveLearningAid(payload) {
    return {
        type : types.SAVE_LEARNING_AID,
        payload
    }
}

export function videoCurrentTime(time) {
    return {
        type: types.VIDEO_CURRENT_TIME,
        time
    }
}

export const setAssignedTemplate = (payload)=>{
    return {
        type: types.SET_ASSIGNED_TEMPLATE,
        payload
    }
}
export const learningAidAdded = (payload) =>{
    return{
        type:types.LEARNING_AID_ADDED_RES,
        payload
    }
}

export const learningAidRemoved = (payload) =>{
    return{
        type:types.LEARNING_AID_REMOVED_RES,
        payload
    }
}

export const cloneLearningAid = ( payload ) => {
    return {
        type: types.CLONE_LEARNING_AID_REQ,
        payload
    }
}

export const learningAidCloned = ( payload ) => {
    return {
        type: types.LEARNING_AID_CLONED_RES,
        payload
    }
}

export const getVideoAddonItems = (payload) =>{
    return{
        type:types.GET_VIDEO_ADDON,
        payload
    }
}

export const setVideoAddonItems = (payload) =>{
    return{
        type:types.SET_VIDEO_ADDON,
        payload
    }
}

export const editTopic = (payload)=>{
    return{
        type:types.EDIT_TOPIC,
        payload
    }
}

export const updateTopic = (payload)=>{
    return{
        type:types.UPDATE_TOPIC,
        payload
    }
}


export function deleteCommentRequest(payload) {
    return {
        type : types.DELETE_COMMENT_REQ,
        payload
    }
}



export function updateCommentRequest(payload) {
    return {
        type : types.UPDATE_COMMENT_REQ,
        payload
    }
}

export function requestSupplimentaries(payload) {
    return {
        type:types.SUPPLIMENTARIES_REQ,
        payload
    }
}

export function responseSupplimentaries(response)    {
    return{
        type:types.SUPPLIMENTARIES_RES,
        payload:response
    }
}
export const updateMarkup = (payload) =>{
    return{
        type:types.UPDATE_MARKUP,
        payload
    }
}

export function publishAddNewLRN(payload) {
    return {
        type : types.ADD_NEW_LRN_IN_PUBLISH,
        payload
    }
}

export function publishDeleteLRN(payload) {
    return {
        type : types.DELETE_LRN_IN_PUBLISH,
        payload
    }
}

export function translationJson(payload) {
    return {
        type : types.TRANSLATION_JSON,
        payload
    }
}

export function responseLrnStatus(payload) {
    return {
        type : types.RESPONSE_LRN_STATUS_IN_BULK,
        payload
    }
}

