import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  withRouter,useHistory } from "react-router-dom";
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import * as courseTrackingAction from '../../../redux/actions/courseTrackingAction'
import CommonHelper from '../../../utils/CommonHelper';
import PlayerConstants from '../../../utils/PlayerConstants';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import useForceUpdate from 'use-force-update';
import { Tooltip } from '@material-ui/core';
import DataHelper from '../../../utils/DataHelper';
import { useToasts } from 'react-toast-notifications';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import t from '../../../translation/useTranslate';

/**
 * TopicAccordion will render the tree based structure on multiple levels
*/
const TopicAccordion = ( props ) => {
    let depth = props.depth;
    let isLinear=props?.isLinear ? true : false;
    /** 
     * using tracking info flatten for tree precentage and status consuming  
     */
    
    const dispatch = useDispatch();
    const history = useHistory()
    const {courseInfo, globalStyle} = useSelector( state => state );
    const [selectedTopic, setTopic ] = useState( courseInfo.topic ? courseInfo.topic.nodeId : 0 );
    const forceUpdate = useForceUpdate()
    const [isLastTopic,setIsLastTopic] = useState(false);
    const { addToast } = useToasts();

    let flatten = courseInfo?.flatten || props?.trackInfo?.flatten;

    // Temp fix for scorm TODO 
    if(Object.keys(props.trackInfo.flatten).length  === 0 ){
        flatten = courseInfo.flatten;
    }
    
    useEffect(()=>{
            try{
                const flatten = courseInfo.flatten;
                if(flatten){
                    /** Setting first section inside first topic as active */
                    if(CommonHelper.isObjEmpty(courseInfo.topic)){
                        let crsTracking = props?.trackInfo?.courseTracking
                        if ( crsTracking) {
                            if ( crsTracking.visited ) {
                                    const flatten = courseInfo.flatten;
                                    if(Object.keys(crsTracking.visited).length > 0){
                                            setTopicResume(flatten,crsTracking);
                                    }else{
                                        dispatch( courseInfoAction.selectedTopic( getFirstTopic(courseInfo.flatten) ) );
                                    }
                            }else{
                                dispatch( courseInfoAction.selectedTopic( getFirstTopic(courseInfo.flatten) ) );
                            }
                        }

                    }else{
                        setTopic(courseInfo.topic.nodeId);
                    }

                    /** Activating Very First TreeItem 
                     * if Last visited topics is not available
                    */
                    if(props && props.navigationHandler){
                        props.navigationHandler({type:'TREE', node:flatten[courseInfo.topic.nodeId]})
                    }
                    
                }
            }catch(e){
                console.debug("Error in TreeItem topic set",e)
            }
    },[courseInfo, props?.trackInfo?.courseTracking]);


    const setTopicResume = (flatten,crsTracking)=>{
        let nodeId = crsTracking.visited.last;
        if(nodeId){
            if(courseInfo.topic.nodeId !== nodeId){
                console.log("Last Visited topic Set:"+nodeId,courseInfo);
                dispatch(courseInfoAction.selectedTopic(flatten[nodeId]));
            }
        }
    }



    
    const getFirstTopic = ( flatten ) => {
        let klist = Object.keys(flatten);
        let fId = klist.find((key)=>{
        if( flatten[ key ].lrncontent && DataHelper.checkEmptyTopic(flatten[ key ].lrncontent) ) {
                     return key;
                    }
            });
            return flatten[fId];
    }

  
    const scrollTop =() =>{
        if(props.elmentItem){
            props.elmentItem.current.scrollTo({top:20, behavior: 'smooth'});
        }
    }


    /** Topic item 
     * setting the current topic
     * Event bubbling handled
     */
    const isCEAuthor = () => {
        // return (window?.ce?.ceuser?.role_id === 1 || window?.ce?.ceuser?.role_id === -1) ? true : false
        return (window?.ce?.ceuser?.role_id !== 4) ? true : false
    }

    const getTopicItem1 = ( e, node ) => {

    }

    const handleOpenNodes =(e, node )=>{
        if(node.children){
            let uId = 'item_'+node.nodeId.split('.').join('_');
            let elm = document.getElementById(uId);
            if(elm.children[0].classList.length < 2){
                elm.children[0].classList.add('hide-child');
                node.open = false;
            }else{
                elm.children[0].classList.remove('hide-child');
                node.open = true
            }
        }
        e.stopPropagation();
    }
//     const isPublished = () => {
//         return !window?.ce?.platform_scorm?.ispublished && isCEAuthor()
//    }
   const isPublished = () => {
        return (window.ce.platform_scorm.isconfigured && !window?.ce?.platform_scorm?.ispublished) && isCEAuthor()
    }
    const getTopicItem = ( e, node ) => {
        dispatch(courseTrackingAction.saveCourseProgress(props?.trackInfo));
        const isLearner=!isCEAuthor()
        if((globalStyle?.snc && node?.status===undefined) && isLearner) {
            setIsLastTopic('strict-nav');
            e.stopPropagation();
            return;
        }
        if(props?.trackInfo?.courseTracking?.lock_course || isPublished()) return;
        if(node.nodeId === DataHelper.getLastTopic(courseInfo?.flatten)?.nodeId && !DataHelper.isAllTpCompletedExceptLast(courseInfo?.flatten) && isLearner ){
            setIsLastTopic('last-topic');
            e.stopPropagation();
            return;
        }

        // if(node.children){
        //     let uId = 'item_'+node.nodeId.split('.').join('_');
        //     let elm = document.getElementById(uId);
        //     if(node.open){
        //         elm.children[0].classList.add('hide-child');
        //         node.open = false;
        //     }else if(!node.open){
        //         elm.children[0].classList.remove('hide-child');
        //         node.open = true
        //     }
        //     console.debug("flobj=========>",node)
        // }
        // forceUpdate();
     
        
        e.stopPropagation();
        scrollTop();
        setTopic( node.nodeId );

        if ( history.location.pathname === '/') {
            history.push( '/course' );
        }

        if(selectedTopic === node.nodeId ) {
            return;
        }

        try{

            let np = getTpWithContent(node);
            let keys = Object.keys(courseInfo.flatten);
            let key;
            /** Selected node lrncontent is empty then looking for the next key and setting as current topic */
            if(!np){
                let index = keys.indexOf(node.nodeId)+1
                key = keys[index];
            }else{
                key = np.nodeId;
            }
            let fnode = courseInfo.flatten[key];
            let ffnode = getTpWithContent(fnode);
            dispatch( courseInfoAction.selectedTopic( ffnode ) );
            if(window.innerWidth < 1100) {
                dispatch(courseInfoAction.setSideBar(false))
            }
            props.navigationHandler({type:'TREE', node:node})
        }catch(e){
            console.log('status set error',e)
        }
    }

    /** check for the node lrncontent 
     * if topic content is not found then
     * find the and return from children
     */
    const getTpWithContent=(node)=>{
        if(node.lrncontent.length > 0){
          if(DataHelper.checkEmptyTopic(node.lrncontent)){
            return  node;
          }
          else{
            if(node.children){
              return node.children.find((el)=>{
                  return el.lrncontent
              })
          }
          else{
            return  node;
          }
        }
        }
        else if(node.children){
            return node.children.find((el)=>{
                return el.lrncontent
            })
        }
    }

    const courseProgress = (node) => {
        if (node.children && node.children.length > 0) {
            let completed = Number(node?.p||0) === 100 ? 1 : 0;
            node.children.forEach((key) => {
                if (Number(key.p) === 100) {
                    completed++;
                }
            })
            return (completed / (node?.children?.length+1)) * 100;
        }
        return node.p;
    }

    useEffect( () => {
        const handleToastMessages = () => {
          switch (isLastTopic) {
            case 'last-topic':
              addToast(t("LAST_TOPIC_MSG"), { appearance: 'error' });
              break;
              case 'strict-nav':
                addToast(t("STRICT_TOPIC_MSG"), { appearance: 'error' });
                break;
            // Add more cases here as needed
            default:
              break;
          }
          setIsLastTopic(false);
        };
    
        if(isLastTopic) handleToastMessages();
      }, [ isLastTopic ] );

    /**
     * 
     * @param {*} nodes 
     * @returns 
     * depends on the node level class added 
     * after the certain level all the class will be deeper most childs
     * Depends on the topic status added to class to change the circle colors 
     */
    const treeItem = ( nodes ) => {
        if ( nodes.length > 0 ) {
            return <ul className={`cus-tree-list ${ depth <= 5 ? 'short-depper-list' : '' }`} >
                {nodes?.length > 0 && nodes.map( ( node ) => {
                    return <li key={ node.nodeId } id={ getIdByNode( node ) } className={ `list-item ${levelClass(flatten,flatten[node.nodeId],depth) } ${UItopic(selectedTopic, node)}` }
                    >
                        <div  className={`${accToggle(node,isLinear,props?.isHome)} ${UIclass(selectedTopic,node)}`  } 
                        // onClick={ ( e ) => getTopicItem( e, node ) }
                         >
                         

                        {isLinear  && <span className='acc-node-icon'>{(Number(courseProgress(node)) !== 100) ? <CircularProgressbar
                                value={courseProgress(node)}
                                className="circular-progress-icon"
                                styles={buildStyles({
                                    rotation: 1.5 + (1 - 20 !== undefined ? 20 : 0 / 100) / 2
                                })} /> : <CheckCircleIcon className='check-circle-icon' />}</span>}

                            <Tooltip title={node.title} placement="right">
                                <div className={ `tp-title ${ props.isOpen }` } >
                                        <div className='tp-text-area'  onClick={ ( e ) => getTopicItem( e, node ) }>
                                            { node.title }
                                        </div>
                                    {(node.children && node.children.length > 0) && <div className='expand-menu-icon' onClick={(e)=>handleOpenNodes( e, node)} > <ExpandLess /></div> }
                                </div>
                            </Tooltip>
                        </div>
                        { node.children && node.children.length > 0 &&
                            treeItem( node.children )
                        }
                    </li>
                } ) }
            </ul>
        }
    }
    return <div>{ treeItem( props.nodes ) }</div>

}

function accToggle(node,isLinear,isHome){
    if(isLinear && !isHome) return node.open === true ? 'hide-child' :''
    return node.open === false ? 'hide-child' :''
}
function UIclass(tp,node){
    return tp === node.nodeId ? 'tp-info tp-active': 'tp-info'
}

// Inner and Deep Topic active check
function UItopic(tp, node) {
    let headTopic = tp?.length > 0 ? tp?.split('.') : [0, 0, 0, 0];
    let nodeTopic = node?.nodeId?.length > 0 ? node?.nodeId?.split('.') : [0, 0, 0, 0];
    let isActiveIn = `${nodeTopic[0]},${nodeTopic[1]}` === `${headTopic[0]},${headTopic[1]}`;
    let isActiveDp = `${nodeTopic[0]},${nodeTopic[1]},${nodeTopic[2]}` === `${headTopic[0]},${headTopic[1]},${headTopic[2]}`;
    let className=''
    if(isActiveIn){
        className=`${className} in-tp-active`
    }
    if(isActiveDp){
        className=`${className} dp-tp-active`
    }
    return className
}


/** UI helpers */
const getIdByNode = ( node ) => {
    if ( node.nodeId )
        return 'item_' + node.nodeId.split( '.' ).join( '_' )
}
const levelClass = (flatten,node,depth ) => {
    let nodeSize= node?.nodeId?.split('.')?.length
    if(depth  <= 5){
        if ( nodeSize === 2 ) {
            return `inner-child ${ getStatus(node ) }`
        } else if ( nodeSize === 2  ) {
            return `deeper-most-child ${ getStatus(node ) }`
        } 
        else if ( nodeSize > 2 ) {
            return `depth-child ${ getStatus(node ) }`
        }
    }
    else{
        if (  nodeSize === 2  ) {
            return `inner-child ${ getStatus(node ) }`
        } else if ( nodeSize === 3 ) {
            return `deeper-child ${ getStatus(node ) }`
        }else if ( nodeSize === 4 ) {
            return `deeper-most-child ${ getStatus(node ) }`
        }
        else if ( nodeSize > 4 ) {
            return `depth-child ${ getStatus(node ) }`
        }
    }
}

const getStatus = (node)=>{
    if(Number(node.p) === 100 && node.status == 1){
        return PlayerConstants.TOPIC_STATUS[1]
    }
    if(node.status  === undefined){
        return PlayerConstants.TOPIC_STATUS[-1]
    }
    return PlayerConstants.TOPIC_STATUS[node.status] 
}



export default withRouter(TopicAccordion);