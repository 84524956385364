
class LearnAidItem {
    _id = 0
    constructor ( { id, name, props, status } ) {
        this.id = id;
        this.name = name;
        this.props = props;
        this.status = status;
    }

    /** _id will be the learningaidId latter will be replaced */
    setLearningAidId ( _id ) {
        this._id = _id;
    }
}
export default LearnAidItem
