import React, { useState, useRef , useEffect} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
import Celogo from '../../../assets/img/ce_author.png'
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import Search from '../Search/Search';
import EditMenuDropdown from '../../../editor/components/EditMenuDropDown/EditMenuDropDown';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction'
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import * as courseTrackingAction from '../../../redux/actions/courseTrackingAction'
import { useSelector, useDispatch } from 'react-redux';
//import IconButton from '@mui/material/IconButton';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import CustomPopover from '../CustomPopover/CustomPopover';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
// import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
// import TabletMacIcon from '@material-ui/icons/TabletMac';
// import DesktopMacIcon from '@material-ui/icons/DesktopMac';
// import LaptopIcon from '@material-ui/icons/Laptop';
// import NotesData from '../Drawer/notes.json'
// import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
// import ListAltIcon from '@material-ui/icons/ListAlt';
// import MenuIcon from '@mui/icons-material/Menu';
// import InfoIcon from '@mui/icons-material/Info';
// import CloseIcon from '@mui/icons-material/Close';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Tooltip } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import DataHelper from '../../../utils/DataHelper';
import ACLHelper from '../../../utils/ACLHelper';
import { useHistory } from 'react-router-dom';
import CePlayer from '../../../assets/img/ce_logo_skill_comp.png'
import './navbar.scss';
import './linearHeader.scss';
import profile_icon from '../../../assets/img/profile_icon.png';
import SettingsIcon from '@mui/icons-material/Settings';
import useStorage from '../../../utils/hooks/useStorage';
import CustomSweetAlert from "../CustomSweetAlert/CustomSweetAlert";
import MuiIcons from '../MuiIcons/MuiIcons';
import t from '../../../translation/useTranslate';
import BuildHelper from '../../../utils/BuildHelper';

const NavBar = ({templateList, resolutionList, handleAttestation, componentList, help, profile, navigateHome, handleStyle, courseCompleted}) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const deviceRef = useRef('')
    const templateRef = useRef('')
    const compRef = useRef('')
    const printRef = useRef('')
    const profileRef = useRef('')
    const [ref, setRef]= useState('')
    const [icon, setIcon] = useState(<MuiIcons iconName='desktop'/>)
    const [open, setOpen] = useState(false);
    const isLearner=BuildHelper?.isLearner();

    const [isPublish, setIsPublish] = useState(false);
    // const [isCnPublish, setIsCnPublish] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const courseEdit = useSelector(state => state.courseEdit)
    const courseInfo = useSelector(state => state.courseInfo);
    const userInfo = useSelector(state => state.userInfo);
    const {globalStyle,courseTracking} = useSelector(state => state);
    const arrowRef = useRef(null);

    const  updates = courseInfo?.supplementaries?.updates
    const [styleObj, setStyle] = useState({})
    const [menuList, setMenu] = useState([]);

    const [editMenuOpened, setEditMenu] = useState('');
    const [closeAlert,setCloseAlert] = useState(false)
    const [isSmallMenu,setIsSmallMenu] = useState(false);
    const configId = window?.ce?.rlm?.config_id || "1";
    const storage = useStorage({ 
      keys: [configId], 
      defaultValue:{
      mail:"",
      attestation:"-1"
    },
    storageType: 'session',});
    let crsStorage =storage &&  storage[`${configId}`]?.get();
    const  VIEW_STATE = {
      HOME :'NAV_GO_HOME', RESPONSIVE:'NAV_RESPONSIVE_VIEW'
    }
    const [logoPath, setLogoPath] = useState(window.ce.isStandalone ? window.ce.siPath : (!isLearner ? Celogo : CePlayer));
    useEffect(() => {
      try{
        if(isLearner){
          const clientId = userInfo?.user?.pvtid
          const fileExtension = userInfo?.user?.logo ? userInfo?.user?.logo.split(".").pop() : 'png';
          if(clientId && fileExtension && Number(clientId)!==139 && Number(clientId)!==222){
                  setLogoPath('https://dg7zdxz92ywv.cloudfront.net/platform/clientlogo/'+clientId+'.'+fileExtension)
          }
        }
          }catch{}
    }, [userInfo])

    /** Only for the course learning element search should come 
     *  if its a course then pusing the item after saveastemplate
     */
    useEffect(() => {
      const featureEnabled3_1 = ACLHelper.isFeatureEnabled(3, 1, -1);
      const featureEnabled3_2 = ACLHelper.isFeatureEnabled(3, 2);
    
      const existingNames = new Set(templateList.map(item => item.name));
      const isCourse=DataHelper?.isCourse();
    
      if (isCourse) {
        const searchElement = {
          name: "Search Learning elements",
          editDrawer: {
            width: 80,
            name: 'Search Learning elements',
            panel: 9
          }
        }
        if (!existingNames.has(searchElement.name)) {
          templateList.splice(2, 0, searchElement);
        }
        // publish while config time and role id if 1
      }
    
      if (!isLearner) {
          const GlobalElement = {
            name: "Global Settings",
            editDrawer: {
              width: 36,
              name: 'GLOBAL SETTINGS',
              panel: 10
           }
        };
    
        const LrnCommentsElement = {
          name: "Comments",
          editDrawer: {
            width: 80,
            name: 'Learning element Comments',
            panel: 11
          }
        };    
    
        if (!existingNames.has(GlobalElement.name)) {
          templateList.push(GlobalElement);
        }
    
        if (!existingNames.has(LrnCommentsElement.name)) {
            templateList.push(LrnCommentsElement);
        }

        if(courseEdit?.publishVersion?.length > 0){
          let publish={
            name:"Publish Version",
            editDrawer: {
              width: 80,
              name: 'Publish Version History',
              panel: 12
            }
          }
          if (!existingNames.has(publish.name)) {
           templateList.push(publish);
          }
        }

        if(BuildHelper?.isConfig()){
          const personalizeHistory = {
            name: "List Of Customization",
            editDrawer: {
              width: 75,
              name: 'List Of Customization',
              panel: 13
            }
          }
          if (!existingNames.has(personalizeHistory.name)) {
              templateList.push(personalizeHistory);
           }
         }




      }
    
      if (featureEnabled3_2 && !DataHelper.isPublish()) {
        const applyTemplate = {
          name: 'Content Templates',
          editDrawer: {
            width: 80,
            name: 'Content template Panel',
            panel: 3
          }
        };
    
        if (!existingNames.has(applyTemplate.name)) {
          templateList.unshift(applyTemplate);
        }
      }
    
      if (featureEnabled3_1 && !DataHelper.isPublish()) {
        const createTemplate = {
          name: "Create Template",
          editDrawer: {
            width: 25,
            name: 'Create Template Panel',
            panel: 7
          }
        };

    
        if (!existingNames.has(createTemplate.name)) {
          templateList.unshift(createTemplate);
        }
      }
    }, [ACLHelper.isFeatureEnabled(3, 1, -1), ACLHelper.isFeatureEnabled(3, 2), courseEdit?.publishVersion]);

    

    const handleMenuToggle = (ref, data, name) => {
        dispatch(courseEditAction.isMenuDropDown(true))
        setRef(ref)
        setMenu(data)
        setEditMenu(name);
        //menu arrow icon position
        if (ref.current && arrowRef.current) {
          // Get the dimensions of the iconRef and the arrow
          const iconRef = ref.current.getBoundingClientRect();
          const popperLeft = iconRef.left;

          // Calculate the exact position for the arrow
          const arrowLeft = popperLeft+18;
          const arrowTop = iconRef.height+15; // Adjust this based on the arrow
          arrowRef.current.style.position = 'absolute';
          arrowRef.current.style.left = `${arrowLeft}px`;
          arrowRef.current.style.top = `${arrowTop}px`;
          arrowRef.current.style.transform = 'translateX(-50%)';
        }
    }
    //  publish action
    const handleIsPublish=()=>{
    try{
      setIsPublish(false);
      // formatting publish json payload
      const publishJson={
        publish:{
          courseid:window?.ce?.rlm?.config_id||"",
          version_json:{...(courseEdit?.publish||{})},
          author:userInfo?.user?.uid||"",
        },
        flatten:courseInfo?.flatten,
      }
      publishJson.versionList=[...courseEdit?.publishVersion,publishJson?.publish];
      dispatch(courseEditAction.publishChangesToCourse(publishJson));
      goHome();
      if (window && window?.ce && window?.ce?.platform_scorm) {
          window.ce.platform_scorm.ispublished = false;
       }
    }catch(e){}
    }
    

    const handlePopover=()=>{
      setAnchorEl(null)
      if(!window?.ce?.platform_scorm?.ispublished) return;
      setIsPublish(true);
    }

    const handleClose = () =>{
      setIsPublish(false);
    }

    // const handlePublish = () => {
    // try{
    //   const lt = courseEdit?.publish || {};
    //   if (!lt) return; //publish or crsStorage is null or undefined
    //   const publish = { lt, ar: {} };
    //   // archived data creation
    //   for (const [key1, topic] of Object.entries(publish.lt ?? {})) {
    //     for (const [key2, lrn] of Object.entries(topic?.lrncontent ?? {})) {
    //       const crtTopic = trackInfo.flatten[lrn?.nodeId];
    //       // find the corresponding LRN in the topic's 'lrncontent'
    //       const arLRN = crtTopic?.lrncontent?.find(e => e?._id === key2);
    //       const arTopic = publish.ar[key1] || {};
    //       publish.ar[key1] = { markup:crtTopic?.markup??"",lrncontent:{...(arTopic?.lrncontent||{}),[key2]:{...(arLRN?.props ?? null)}}  };
    //     }
    //   }
    //   console.log("publish", publish);
    // }catch(e){
    //   console.error("******handlePublish at NavBar.js **********")
    // }
    
    // };
    
    
      const deviceResolution = (style) => {
        setStyle(style)
        handleStyle(style)
        switch(style.name) {
            case 'Laptop':
                setIcon(<MuiIcons iconName="laptop"  />)
                break 
            case 'Mobile':
                setIcon(<MuiIcons iconName="phone"  />)
                break 
            case 'Tablet':
                setIcon(<MuiIcons iconName="tablet"  />)
                break 
              case 'Desktop':
                setIcon( <MuiIcons iconName="desktop"  />)
                break;
            default: 
                setIcon(<MuiIcons iconName="desktop"  />)
                break 
          }
        navigate(VIEW_STATE.RESPONSIVE,style)
      }
    
    const handleClick = () => {
        dispatch(courseInfoAction.setSideBar(!courseInfo.sideBar))
    }

    const handleDrawer = (topic, width='40%') => {
        const mediaEle = document.querySelectorAll('video, audio')
        if (mediaEle.length !== 0)
          for (const media of mediaEle) {
                media.pause()
          }
        dispatch(courseInfoAction.setDrawer({value: true, topic, width}))
      }

    const navigate = (type,...args) => {
        navigateHome({type:type, data:{view:args[0]}})
    }

    const closeCourse = ()=>{
      setCloseAlert(true);
    }

    const userCfClose = ()=>{
      setCloseAlert(false);
      let topWindow = (window.top === window.self) ? false : true;
				if(topWindow){
					window.top.close();
				}else{
					window.close();
				}

      // set lms finish here
    }

  const toggleMenuList = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const closeMenu = (event, topic, width) => {
    if (printRef.current && printRef.current.contains(event.target)) {
      return;
    }
    if (topic !== undefined) {
      handleDrawer(topic, width)
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      printRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

    const userCancel =()=>{
      setCloseAlert(false);
    }

    const goHome =()=>{
      history.push('/');
    }
    const goLanguage =()=>{
      history.push('/');
      dispatch(courseTrackingAction.hasLanguage());
    }

  //  const isCertificate=()=>{
  //   if(Number(courseInfo?.flatten[1]?.status) !== 1 || crsStorage?.attestation !=="3") return true;
  //    return false
  //  }
  const timerRef = useRef(null);
  const handleMouseEnter = (e) => {
    let element=e.currentTarget
    timerRef.current = setTimeout(() => {
      if (element) {
        setAnchorEl(element);
      }
    },600); 
  };

  const handleMouseLeave = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

   const getCertificate=(e)=>{
    crsStorage=storage[`${configId}`]?.get();
    const completed=Number(courseInfo?.flatten[1]?.status) === 1
    if(!completed||(crsStorage?.attestation !=="3" && BuildHelper.isConfig() && globalStyle?.attest?.is) ){
     if(completed)handleAttestation("1");
     return;
   }
    closeMenu(e, 'Certificate', '80%');
   }
    const popId =  Boolean(anchorEl) ? 'simple-popover' : undefined;
    // style={{'--global-font-family': `${globalStyle?.font || 'RobotoLight'}`}}
    return (
    <>
        <Box sx={{ flexGrow: 1 }} >
          {/*  use "tree-header" class for the ce header  class ${globalStyle?.isLinear ? 'linear-header' : 'tree-header'} */}
            <AppBar position="static" className={`linear-header `} >
                <Toolbar variant="dense" className="header-container">
                    {/* <IconButton className='left-menu' edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => { handleClick() }} > */}
                       <MuiIcons iconName="menu"  className='left-menu' edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={() => { handleClick() }} />
                    {/* </IconButton> */}
                    <Typography variant="h6" color="inherit" component="div" className={`img-container ${Number(window?.ce?.ceuser?.role_id) === 1 && "edit-icon"}`} sx={{ flexGrow: 1 }}  >
                    <img src={logoPath} alt='' onClick={() => navigate(VIEW_STATE.HOME)} style={{ cursor: 'pointer' }}/>
                    </Typography>
                    {(isLearner && globalStyle?.headers?.s) && <Search />}
                    {isLearner && 
                     <div className='small-device-menu'>
                        {/* <IconButton  className='right-menu' edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={()=>setIsSmallMenu(!isSmallMenu)}> */}
                            {isSmallMenu ? <MuiIcons iconName="close"  className='right-menu' onClick={()=>setIsSmallMenu(!isSmallMenu)}  />  : <MuiIcons iconName="menu"  className='right-menu' onClick={()=>setIsSmallMenu(!isSmallMenu)}  />}
                        {/* </IconButton> */}
                        {isSmallMenu && <div className='small-device-menu'>
                            {isLearner &&<>
                              {globalStyle?.headers?.m && <Button className="header-course-map"  color="inherit" onClick={(e) => closeMenu(e, 'CourseMap', '100%')}>{t("P_CRS_MAP")}</Button>}
                              {globalStyle?.headers?.c && <Button className="header-certificate" color="inherit" onClick={(e) => getCertificate(e)} disabled={courseInfo?.flatten['1']?.status!==1}>{t("P_CERTIFICATE")}</Button> }
                               {(globalStyle?.headers?.n && courseInfo?.supplementaries?.notes?.length > 0) && <Button color="inherit" onClick={() => handleDrawer('CE Notes')}>{t("CE_NOTE")}</Button>}
                               {(globalStyle?.headers?.v && courseInfo?.supplementaries?.vignettes?.length > 0 )&& <Button color="inherit" onClick={() => handleDrawer('vignettes')}>{t("VIGNETTES")}</Button>}
                               {(globalStyle?.headers?.u && courseInfo?.supplementaries?.updates?.length > 0 )&& <Button color="inherit" onClick={() => handleDrawer('updates')}>{t("UPDATE")}</Button>}
                              </> }
                          </div>}
                          <Tooltip title="Close course" aria-label="close" placement='top'>
                           <span><MuiIcons iconName='power_settings' className='power-icon' onClick={() =>closeCourse()}  /></span>
                          </Tooltip>
                      </div>
                      }
                   
                    <div className={`edit-tool-menu`}>
                      {(courseEdit?.publish && Object.keys(courseEdit?.publish)?.length > 0) && <>

                        {/* <span className="header-home info-icon" color="inherit" aria-describedby={idx}  onMouseEnter={(e)=>setAnchorEl(e.currentTarget)} onClick={(e)=>setAnchorEl(e.currentTarget)} >
                        <InfoIcon />
                      </span> */}
                        <Button className='btn publish-btn' aria-describedby={popId}  onMouseLeave={(e)=>handleMouseLeave(e)} onMouseEnter={(e)=>handleMouseEnter(e)} onClick={(e)=>setAnchorEl(e.currentTarget)} > {t("PUBLISH")}  
                          {/* <CloudUploadIcon />  */}
                          <MuiIcons iconName='cloud_upload' />
                           </Button>
                        {/* <Popover
                          id={popId}
                          className='publish-popover'
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={(e)=>setAnchorEl(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                    >
                      <div className='sweet-alert popover'>
                        <p>{t("PUBLISH_POP")}</p>
                        <div className='popover-btn'>
                        <Button className='btn-link' onClick={(e)=>setAnchorEl(null)}>{t("CANCEL")}</Button> <Button className='btn btn-lg btn-primary' onClick={handlePopover}>{t("OK")}</Button>
                          </div>  
                      </div>
                        
                    </Popover> */}
                      </>
                      }
                      {courseTracking?.crsProgress?.player_config_json?.i18n?.languages?.length > 1 && <span className="language-icon" color="inherit" aria-describedby={popId} onMouseLeave={(e)=>handleMouseLeave(e)} onMouseEnter={(e)=>handleMouseEnter(e)} onClick={(e)=>setAnchorEl(e.currentTarget)}>
                         <MuiIcons iconName='translate' />
                        {/* <GTranslateIcon/> */}
                      </span>}
                      <span className="header-home" color="inherit" onClick={()=>goHome()}>
                        <MuiIcons iconName="home"/>
                        {/* <HomeIcon/> */}
                      </span>
                      {isLearner &&<>
                        {globalStyle?.headers?.m && <Button className="header-course-map fw-500"  color="inherit" onClick={(e) => closeMenu(e, 'CourseMap', '80%')}>{t("P_CRS_MAP")}</Button>}
                        {globalStyle?.headers?.c && <Button className="header-certificate fw-500" color="inherit" onClick={(e) => getCertificate(e)} disabled={courseInfo?.flatten['1']?.status!==1}>{t("P_CERTIFICATE")}</Button>}
                      </>}
                    </div>

                    {isLearner ? <div className='top-bar-menu'><div className='supplementary_updates'>
                    {(globalStyle?.headers?.n && courseInfo?.supplementaries?.notes?.length > 0) && <Button color="inherit" onClick={() => handleDrawer('CE Notes')}>{t("CE_NOTE")}</Button>}
                    {(globalStyle?.headers?.v && courseInfo?.supplementaries?.vignettes?.length > 0 ) && <Button color="inherit"  onClick={() => handleDrawer('vignettes')}>{t("VIGNETTES")}</Button>}
                    {(globalStyle?.headers?.u && courseInfo?.supplementaries?.updates?.length > 0 ) && <Badge className='badge-ce-updates' badgeContent={updates?.length} color="primary">
                    <Button color="inherit" onClick={() => handleDrawer('updates')}>{t("UPDATE")}</Button>
                    </Badge>}</div>
                    {/* <Button color="inherit" className={`help-icon`} title="Print" ref={printRef} onClick={toggleMenuList}>Print</Button> */}
                    <ArrowDropUpIcon className={`arrow-drop-up ${open && 'show-print-arrow'}`} />
                    {/* <MuiIcons iconName='drop_up_arrow'  className={`arrow-drop-up ${open && 'show-print-arrow'}`} /> */}
                    <Popper open={open} anchorEl={printRef.current} transition disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper className='menu-list-popper'>
                            <ClickAwayListener onClickAway={closeMenu}>
                              <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                <MenuItem onClick={(e) => closeMenu(e, 'CourseMap', '80%')}>{t("COURSE_MAP")}</MenuItem>
                                <MenuItem onClick={(e) => getCertificate(e)} disabled={courseInfo?.flatten['1']?.status!==1}>{t("CERTIFICATE")}</MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                    {/* <Tooltip title="Close course" aria-label="close" placement='top'>
                        <Button color="inherit" onClick={() =>closeCourse()} className='close-course-btn'><PowerSettingsNewIcon className='power-icon' /></Button>
                    </Tooltip> */}
                    <Tooltip title={t("CLOSE_CRS")} aria-label="close" placement='top'>
                    <span color="inherit" onClick={() =>closeCourse()} className='close-course-btn'>
                       <MuiIcons iconName='power_settings' className='power-icon' />
                    </span>
                    </Tooltip>
                 <div className='warning-popup'> <SweetAlert warning
                    showCancel
                    show={closeAlert}
                    style={{color:'#000'}} 
                    confirmBtnText={t("CLOSE_CRS")}
                    cancelBtnText={t("CANCEL")}
                    confirmBtnBsStyle="success"
                    title={t("CLOSE_CRS_MSG")}
                    onConfirm={ () => userCfClose() }
                    onCancel={ () => userCancel() }>
                  </SweetAlert>
                   </div>
                    </div> :

                    <div className={`edit-tool-menu`}>
                     {/* {globalStyle?.isLinear  ? */}
                    <span color="inherit" className={`template-icon ${courseEdit.menuDropDown && editMenuOpened === 'Template' && 'highlight-template'}`}title="Add Template" onClick={() => handleMenuToggle(templateRef, templateList, 'Template')} ref={templateRef}>
                      <SettingsIcon />
                    </span>
                    {/* :
                    <Button  color="inherit" className={`template-icon ${courseEdit.menuDropDown && editMenuOpened === 'Template' && 'highlight-template'}`} title="Add Template" onClick={() => handleMenuToggle(templateRef, templateList, 'Template')} ref={templateRef}>{t("TOPIC")}</Button>}
                     <span className={`device-icon ${courseEdit.menuDropDown && editMenuOpened === 'Device' && 'highlight-device'}`} title="View in Devices" onClick={() => handleMenuToggle(deviceRef, resolutionList, 'Device')} ref={deviceRef}>{icon}</span> */}
                    {/* <span className={`component-icon ${courseEdit.menuDropDown && editMenuOpened === 'Component' && 'highlight-component'}`} title="Template List" onClick={() => handleMenuToggle(compRef, componentList, 'Component')} ref={compRef}><ListAltIcon /></span> */}
                    {/* {!globalStyle?.isLinear && 
                    // <IconButton
                    //     aria-label="account of current user"
                    //     aria-controls="menu-appbar"
                    //     aria-haspopup="true"
                    //     onClick={() => handleMenuToggle(profileRef, profile, 'Profile')} ref={profileRef}
                    //     color="inherit"
                    //     className={`profile-icon ${courseEdit.menuDropDown && editMenuOpened === 'Profile' && 'highlight-profile'}`}
                    // ></IconButton>
                        <MuiIcons 
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={() => handleMenuToggle(profileRef, profile, 'Profile')} ref={profileRef}
                          color="inherit"
                          className={`profile-icon ${courseEdit.menuDropDown && editMenuOpened === 'Profile' && 'highlight-profile'}`}
                          iconName='account_circle' 
                       />
                    } */}
                    <div  className={`user-profile  ${courseEdit.menuDropDown && editMenuOpened === 'Profile' && 'highlight-profile'}`}>
                    {/* {globalStyle?.isLinear && <> */}
                    <span className={`profile-pic`}>
                      <img src={profile_icon} alt='profile' />
                    </span>
                    <div className='user-detail' onClick={() => handleMenuToggle(profileRef, profile, 'Profile')} ref={profileRef}><p className='user-name'>{window.ce?.ceuser?.firstname}</p> <p className='user'>{ACLHelper.getRolName().name }</p></div>
                     <MuiIcons iconName='down_arrow' className={`arrow-dowen`}/>
                     {/* </>} */}
                    <ArrowDropUpIcon ref={arrowRef} className={`arrow-drop-up ${editMenuOpened} ${courseEdit.menuDropDown && 'show-arrow'}`} />
                     {/* <MuiIcons iconName='arrow_drop' className={`arrow-drop-up ${editMenuOpened} ${courseEdit.menuDropDown && ' show-arrow'}`} /> */}
                    <EditMenuDropdown  menuData={menuList} isOpen={courseEdit.menuDropDown} anchor={ref} handleParent={deviceResolution}/>
                    </div>
                    {/* <ArrowDropUpIcon className={`arrow-drop-up ${editMenuOpened} ${courseEdit.menuDropDown && 'show-arrow'}`} />
                    <EditMenuDropdown  menuData={menuList} isOpen={courseEdit.menuDropDown} anchor={ref} handleParent={deviceResolution}/> */}
                    </div>}
                    <CustomPopover 
                          id={popId}
                          anchorEl={anchorEl}
                          className='publish-popover'
                          open={Boolean(anchorEl)}
                          onClose={(e)=>setAnchorEl(null)}
                          message={DataHelper.isEditor() ? t("PUBLISH_POP") : t("LANGUAGE_POP")}
                          cancelText={t("CANCEL")}
                          confirmText={t("OK")}
                          handleConfirm={isLearner? goLanguage : handlePopover}
                        />
                        
                    <div className='publish-info-popup warning-popup application-info'>

                        <CustomSweetAlert
                         warning
                         showCancel
                         show={isPublish}
                         title={"Publish Version"}
                         style={{color:'#000'}}
                         confirmBtnText={"Confirm"}
                         confirmBtnBsStyle="success"
                         onConfirm={()=>handleIsPublish()}
                         onCancel={()=> handleClose()}
                         children={<span className='info-details'>
                          <p className='msg'>{t("PUBLISH_MSG")}</p>
                         </span>} />

                         {/* <CustomSweetAlert
                          warning
                          showCancel
                          show={isCnPublish}
                          style={{color:'#000'}}
                          confirmBtnText={"Confirm"}
                          confirmBtnBsStyle="success"
                          onConfirm={()=>handleIsPublish()}
                          onCancel={()=> setIsCnPublish(false)}
                          children={<span className='info-details'>
                            <p className='msg'>
                              Your modifications will reflect to the learners enrolled in the course. Online learners will receive notifications within their course interfaces. Please note that this process may require some time to complete. Click "Confirm" to publish this version or "Cancel" if further updates are pending.
                            </p>
                          </span>} /> */}

                     </div>
                </Toolbar>
            </AppBar>
      </Box>
      </>
    )
}

NavBar.defaultProps = {
    help: [
      {
        name: 'CourseMap',
        editDrawer: {
          width: 80,
          name: 'Print CourseMap',
          panel: 4
        }
      },
      {
        name: 'Certificate',
        editDrawer: {
          width: 80,
          name: 'Print Certificate',
          panel: 5
        }
      }
      ],
      profile: [
        {
          name: 'Profile'
        }, {
          name: 'Account'
        }
      ],
      templateList: [
        
        { name: "Status",
        editDrawer: {
          width: 80,
          name: 'Status',
          panel: 5
        }}],
      resolutionList: [
        {name: 'Mobile', type: 'Mobile', image: 'phone_icon.png'},
        {name: 'Tablet', type: 'Tablet', image: 'tablet_icon.png'},
        {name: 'Laptop', type: 'Laptop', image: 'laptop_icon.png'},
        {name: 'Desktop', type: 'Desktop', image: 'default_icon.png'}
      ],
      componentList: [
       {name: 'Accordion', image: 'Accordion_comp.png'},
       {name: 'TopicDescription', image: 'Topicdesc_comp.png'},
       {name: 'Video', image: 'Video_comp.png'},
       {name: 'Audio', image: 'Audio_comp.png'},
       {name: 'OrderedList', image: 'Ordered_comp.png'},
       {name: 'UnorderedList', image: 'Unordered_comp.png'},
       {name: 'ImageMapper', image: 'Imagemapping_comp.png'},
       {name: 'Carousels', image: 'Carousel_comp.png'},
       {name: 'Tabs', image: 'Tab_comp.png'}
      ]
}

export default NavBar
