import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types'
import ContentTracking from "../../../core/progress/ContentTracking";
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import Congratulate from '../../../assets/img/Congratulate.png';
import Confetti from "react-confetti";
import './KnowledgeCheck.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Close from '@material-ui/icons/Close';
import DataHelper from '../../../utils/DataHelper';
import AppButton from '../../ui/AppButton/AppButton';
import t from '../../../translation/useTranslate';

function KnowledgeCheck(props) {


  const [questions, setQuestions] = useState(props && props.items && props.items.length > 0 ? props.items : [])
  const [activeStep, setActiveStep] = useState(0);
  const [selectItem, setSelectItem] = useState([]);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [finalAnswer, setFinalAnswer] = useState([])
  const maxSteps = questions.length;
  const [instruction, setInstruct] = useState(props.instructions);
  const [status, setStatus] = useState({ completed: false, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE })
  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS
  const [stopConfetti, setStopConfetti] = useState(false)
  const { cename, handler, track } = props;
  const [isFeedBack, setIsFeedBack] = useState(false);

  useEffect(()=>{
    if (!stopConfetti && showScore && (score / questions.length) * 100 >= 80){
      setStopConfetti(true)
      setTimeout(() => {
        setStopConfetti(false)
      }, 15000);
 }
  },[showScore])
  const handleNext = () => {
   if(questions[activeStep].explanation && !isFeedBack)  setIsFeedBack(true);
    else if (maxSteps) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      if (selectItem.length > 0 && (!(selectItem.includes(undefined))) && selectItem.length == questions.length) {
        getResult();
      }
      setIsFeedBack(false)
    } else {
      setActiveStep(0);
    }

  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    setQuestions(props?.items?.length > 0 ? props.items : [])
  }, [props])


  useEffect(() => {
    let tempFinalAns = []
    questions.forEach(element => {
      let tempAns = []
      element?.answer?.forEach((item,index) => {
        if (item.isCorrectAnswer) {
          tempAns.push(index)
        }
      })
      tempFinalAns.push(tempAns);
    });
    setFinalAnswer([...tempFinalAns])
  }, [questions])

  const getImageUrl = (path)=>{
    return DataHelper.getResourcePath(0,path);
  }

  const onHandleSelect = (e, type) => {
    try{
      if(!isFeedBack){
        let value=Number(e.target.value)
          if (type === 2) {
            let temp = [...selectItem];
            if(temp[activeStep]){
              if(temp[activeStep].some(e=>e==value)) temp[activeStep] = temp[activeStep].filter(e=> e != value)
              else  temp[activeStep].push(value)
            }else temp[activeStep] = [value]
            setSelectItem([...temp]);
          }
          else {
            let temp = [...selectItem];
            temp[activeStep] = [value]
            setSelectItem([...temp]);
          }
      }
      updateProgress("")
    }catch(e){}
  }

  const getFeedBackResult= (index)=>{
    try{
       let selectedValues =selectItem[index]?.map(e=>Number(e)).sort();
       let answerValues =finalAnswer[index]?.map(e=>Number(e)).sort();
       if (JSON.stringify(selectedValues) == JSON.stringify(answerValues)) {
        return true
       }
       return false
     }catch(e){}
   }

  const getResult = () => {
  try{
    let score = 0;
    for (let i = 0; i < finalAnswer.length; i++) {
      let selectedValues =selectItem[i]?.map(e=>Number(e))?.sort();
      let answerValues =finalAnswer[i]?.map(e=>Number(e))?.sort();
      if (JSON.stringify(selectedValues) == JSON.stringify(answerValues)) {
        score++;
      }
    }
    setScore(score)
    setShowScore(true)
    updateProgress("true")
  }catch(e){}
  }
  

  const updateProgress = (currentItem) => {

    track.state = { ...track.state}

    if (currentItem === "true") {
      track.status = 1
      handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: cename })
    }

    handler({ type: UPDATE_STATUS, id: track.id, name: cename })

  }
  const getScorePercentage=()=>{
    return Math.round(String((score / questions?.length) * 100))
}

  return (<>
    <div>

      <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text} />


      <Paper className='paper-data' style={{padding: `${(score / questions.length) * 100 > 80 ? '0px 0px 0px 0px' : '25px 35px 30px 35px'}`}}>
        {showScore ? (
          <div style={{overflow:'hidden'}}>
            <div  className='confetti'>
              {(score / questions.length) * 100 >= 80 && <Confetti numberOfPieces={stopConfetti ? 400:0} width='1800px' height='250px' style={{ position: 'absolute' }} />}
            </div>
            <div className="img_congratualte">
            {(score / questions.length) * 100 >= 80 &&<img src={Congratulate} height='150px' width='150px' />}
              <div className="Text_congratulate">
                {(score / questions.length) * 100 >= 80 && <div className="font_congratulate">{t("CONGRATULATION")}</div>}
                <div className="font_congratulate_1">{t("COMPLETED__MSG_KN")}!</div>
                {(score / questions.length) * 100 >= 80 && <div className="font_congratulate_2">{t("GREAT_JOB")}</div>}
                <div className="font_congratulate_3">{t("YOUR_SCORE")}<b> {getScorePercentage()}%</b> {t("WITH")} <b>{score}</b>{t("OUT_OF")}<b>{questions.length}</b>{ t("CORRECT_ANS")}</div>
                {(score / questions.length) * 100 < 80 && <div className="font_congratulate_4">{t("REQUIRED_PASS_PERCENT")} : 80%</div>}
              </div>
            </div>
          </div>

        ) : (
          <>
          <div className={` ${questions[activeStep||0]?.img && "quiz-box"}`}>
              {questions[activeStep]?.img && <div className="img">
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img src={getImageUrl(questions[activeStep||0]?.img||"")} />
            </div>} 
          <div className={` ${questions[activeStep||0]?.img && "question-box"}`}>
            <div key={activeStep} >
              <FormControl className='group-selection'>
                <FormLabel id="demo-radio-buttons-group-label" className='title text-content'> {questions[activeStep].questionText}</FormLabel>

                {questions[activeStep] && questions[activeStep].questionType && questions[activeStep].questionType === "multiple" ?
                  <FormGroup >
                    {questions[activeStep]?.answer?.map((ans, index) =>
                      <FormControlLabel key={index}
                        className="radio_multi text-content"

                        control={
                          <Checkbox className="radio" checked={selectItem[activeStep]?.some(e=>e==index)||false} value={index} onChange={(e) => onHandleSelect(e, 2)} name={ans.answerText} />
                        }
                        label={ans.answerText}
                      />
                    )}

                  </FormGroup> :
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className='group-selection'
                    value={selectItem && selectItem[activeStep] ? selectItem[activeStep][0] : ""}
                    onChange={(e) => onHandleSelect(e,1)}>
                    {questions[activeStep]?.answer?.map((ans, i) => (
                      <FormControlLabel key={i} className={`${questions[activeStep].questionType ==="true/false" && "true_false "} radio_multi text-content`} value={i} control={<Radio className='radio' />} label={ans.answerText} />
                    ))}
                  </RadioGroup>}

              </FormControl>
              <div>
              {( questions[activeStep].explanation && selectItem[activeStep] && isFeedBack) && <div className='feed-back'>
                {getFeedBackResult(activeStep) ? <>
                  <div className='inner-box'><span><CheckCircleIcon className='correct' /></span><p className='feedback_text' dangerouslySetInnerHTML={{ __html: questions?.[activeStep]?.rightExplanationText }}></p></div>
                </>
                  : <>
                    <div className='inner-box'><span><Close className='wrong' /></span> <p className='feedback_text' dangerouslySetInnerHTML={{ __html: questions?.[activeStep]?.wrongExplanationText }}></p></div>
                  </>
                }
                </div>}
                {/* {questions[activeStep].explanation ? selectItem && selectItem[activeStep] && finalAnswer && finalAnswer[activeStep] ? JSON.stringify(selectItem[activeStep]) === JSON.stringify(finalAnswer[activeStep]) ? <div className="response-main">{questions[activeStep].rightExplanationText}</div> : <div className="response-main">{questions[activeStep].wrongExplanationText}</div> : null : null} */}
              </div>
            </div >
            </div>
            </div>
            <div className='bottom'>
              <div className="buttons">
                < AppButton size="small"  onClick={handleBack} disabled={activeStep === 0}>
                  {t("BACK")}
                </AppButton >
                <AppButton
                  size="small"
                  onClick={handleNext}
                  disabled={selectItem && (selectItem[activeStep] === undefined || selectItem[activeStep] === null)}>
                  {questions[activeStep].explanation&& !isFeedBack ? t("SUBMIT") : t("NEXT")}
                </AppButton>
              </div>
              <div className="pagination_Numbers">
                <Stack spacing={2}>
                  <Pagination count={questions.length} className="pagination" color="primary" size="medium" hidePrevButton hideNextButton page={activeStep + 1} />
                </Stack>
              </div>
          </div>
        </>
        )}
      </Paper>
    </div></>
  );
}

KnowledgeCheck.defaultProps = {
  instructions: {
    text: 'Select Correct Option',
    enabled: false
  },
}

KnowledgeCheck.propTypes = {
  /** Heading title text of the component */
  title: PropTypes.string,
  /** Description text is used for displaying explanation over the topic  */
  description: PropTypes.string,
  /** Question for the radioSelect component  */
  question: PropTypes.string,
  /** options array  */
  options: PropTypes.array,
  /** right or wrong information */
  explanation: PropTypes.object,
  /** answer id in number */
  answer: PropTypes.number,

  /** Tracking the component progress */
  track: PropTypes.instanceOf(ContentTracking),
  /** Func description for tracking*/
  handler: PropTypes.func
}

export default KnowledgeCheck;