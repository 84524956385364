import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import CommentIcon from '@mui/icons-material/Comment';
import moment from 'moment';
import './topichistory.scss'
import PlayerConstants from '../../../utils/PlayerConstants';
import ListItemText from '@mui/material/ListItemText';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction';
import * as courseEditAction from '../../../redux/actions/courseEditAction';
import DataHelper from '../../../utils/DataHelper';
import CustomSweetAlert from '../../../components/ui/CustomSweetAlert/CustomSweetAlert';
import useToastNotifications from '../../../utils/hooks/useToastNotifications'
// import DataHelper from '../../../utils/DataHelper';
// import CustomSweetAlert from '../../../components/ui/CustomSweetAlert/CustomSweetAlert';

const TopicHistory = ({ logs }) => {


    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [statusChange, setStatusChange] = useState({ is:false, change:null});
    const open = Boolean(anchorEl);
    const {showSuccess} = useToastNotifications();
    const [expanded, setExpanded] = useState([]);
    const courseInfo = useSelector(state => state.courseInfo);
    const courseEdit = useSelector(state => state.courseEdit);
    const userInfo = useSelector(state => state?.userInfo);
    const isPublishHis= Number(courseEdit?.panel) === 12;

    const [topics, setTopicsInfo] = useState([])
    const [tpByStatus, setTpByStatus] = useState(JSON.parse(JSON.stringify(PlayerConstants.STATUS_LRN_AIDS)))
    const [isShowList,setStatusList] = useState(true)


    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
        setStatusList(true)
    };

    const handleMenuItemClick = (event, index,laval) => {
        if(selectedIndex!==index){
            if(laval===1){
                setSelectedIndex(index);
            }else{
                setStatusChange({...statusChange,change:index})
                return;
            }
            setStatusChange({})
        }
        setAnchorEl(null);
        openAcc(null);
        setStatusList(false)
    };

    const handleCancel=()=>{
        setStatusChange({})
    }

    const handleBulkChange=()=>{
        if (!courseInfo?.flatten || !tpByStatus[selectedIndex]) return;

        const updatedFlatten = { ...courseInfo.flatten }; // Create a shallow copy to avoid direct mutation
        const selectedTopics = tpByStatus[selectedIndex]?.topics || {};

        // flatten topic ids and update statuses in one go
        const lrnids = Object.values(selectedTopics)?.flatMap(({ node, list }) => {
            if (!updatedFlatten[node]) return []; 

            // update statuses
            updatedFlatten[node].lrncontent = updatedFlatten[node]?.lrncontent?.map(e => ({
            ...e,
            status:Number(e?.status) === Number(selectedIndex)
                ? Number(statusChange?.change || selectedIndex)
                : e?.status||1,
            })).filter(Boolean);

            // extract ids
            return list.map(e => e?._id).filter(Boolean); // filter to avoid undefined ids
        });

        const payload = {
           payload:{
            lrnids, //flattened using flatMap
            status: Number(statusChange?.change || selectedIndex),
           },
           response:{...updatedFlatten}
        };
        dispatch(courseEditAction.updateLrnStatusInBulkStatus(payload));
        handleCancel();
        dispatch(courseEditAction.toggleDrawer({ drawerWidth: 0,open:false}))
        showSuccess("Status Updated Successfully")
    }


    const handleClose = () => {
        setAnchorEl(null);
    };





    useEffect(() => {
        if (courseInfo.flatten && courseEdit.panel !== 12) {
            let activetps = [];
            Object.keys(courseInfo.flatten).forEach(tp => {
                const tpc = courseInfo.flatten[tp];
                if (tpc.lrncontent.length > 0) {
                    activetps.push(tpc)
                    tpc.lrncontent.forEach(le => {
                        try {
                            if (!tpByStatus[le.status].topics) { tpByStatus[le.status].topics = {} }
                            if (!tpByStatus[le.status].topics[tpc.id]) { tpByStatus[le.status].topics[tpc.id] = { node: tpc.nodeId, title: tpc.title, list: [] } }
                            if(!isItemExist(tpByStatus[le.status].topics[tpc.id].list,le)){
                                tpByStatus[le.status].topics[tpc.id].list.push(le)
                            }
                        }
                        catch (e) { console.log(e) }
                    })
                }
            })
            setTopicsInfo(activetps);
        //    console.log(tpByStatus[selectedIndex]?.topics)
        }

    }, [logs]);

    const isItemExist = (list,item) => {
        try{

                const found = list.filter((el)=>{
                    return el._id === item._id && el.compid === item.compid
                });
                if(found.length > 0){
                    return true
                }
                return false
        }catch(e){
            console.log(e);
        }
    }

    const getCurrentTopic=(id)=>{
        for (const [key, val] of Object.entries(courseInfo.flatten)) {
            if (val.id === id) {
                console.log("getCurrentTopic",val);
              return {...val}
            }
          }  
    }

    const renderLEStatus = (crsHistoryList, key, ...rest) => {
       let updatedData= (crsHistoryList?.markup) ? delete crsHistoryList?.markup : crsHistoryList;
       const authorName=rest[0]||{} //Inside a loop, retrieve the author's name and publish time
    try{
        return <div>
        {(crsHistoryList) ?
            Object.entries(updatedData).map(([tpcs, val], index)=> {
                const foundEntries = getCurrentTopic(tpcs);
                let topicTitle =foundEntries;
                let currentTopic={...val,_id:tpcs};
                const node = topicTitle?.node ?? topicTitle?.nodeId ?? val?.node ?? false;
                const heading= (topicTitle?.title ?? `${moment(val?.updatedAt).format('MMMM D, YYYY')} (${moment(val?.updatedAt).fromNow()})`)
                return <Accordion key={tpcs} expanded={expanded[key] === index} onChange={() => openAcc(index,key)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${tpcs}-content`}
                        id={`panel-${tpcs}-header`}
                    >
                        <Typography  >{(node ? node + " - " : "") + heading}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div">
                            {(crsHistoryList[tpcs]?.list?.length > 0 && !isPublishHis) ? renderLEItems(crsHistoryList[tpcs]) : renderPublishHistory(val,authorName,currentTopic)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            })
            : <p className='no-data'>No topic history found</p>
        }
    </div>
    }catch(e){
        console.log("renderLEStatus ***",e);
        
     }
    }

    const handleStatusChange = (e,topic,lrn)=>{
        try{
            lrn.status = Number(e.target.value);
            // console.debug(lrn,topic?.list,"selectedStatusCode",selectedIndex)
        }catch(e){
            console.log(e)
        }
    }


    const topicSelected = (tp,lrn) =>{
        try{
            dispatch(courseEditAction.toggleDrawer({open:false, drawerWidth: 0}))
            let node = courseInfo.flatten[tp.node];
            dispatch( courseInfoAction.selectedTopic( node ) );
            setTimeout(() => {
                DataHelper.scrollToLrnElement(lrn);
            }, 800);
        }catch(e){

        }
    }

    
    const renderLEItems = (topic) => {
        let lrnItems = topic.list;
        return (<ul>
            {lrnItems?.map((lrn, index) => {
                return <li key={index} className="flex-item">
                    <div className='lrnaid_col'>{index+1}. {PlayerConstants?.LRN_RE_NAME[lrn?.name]||lrn?.name}</div>
                    <div className='author'>By  {lrn?.author?.lname} {lrn?.author?.fname}</div>
                    {/* Once the Api is ready, we need to enable this as per Kim's request. */}
                    <p className="select-status">{ PlayerConstants.STATUS_LRN_AIDS[lrn?.status]?.status}</p>

                    {/* <select  className="select-status"
                    //  value={lrn.status}
                    defaultValue={lrn.status}
                    onChange={(e)=>handleStatusChange(e,topic,lrn)}
                    >
                    {Object.keys(PlayerConstants.STATUS_LRN_AIDS).length > 0 && Object.keys(PlayerConstants.STATUS_LRN_AIDS)?.map((key)=>{
                         return <option key={key} value={PlayerConstants.STATUS_LRN_AIDS[key].id} >
                         {PlayerConstants.STATUS_LRN_AIDS[key].status}
                     </option>
                    })}
                    </select> */}

                    <div className='update-at'> {moment(lrn.updatedAt).format('MM/DD/YYYY h:mm')}</div>
                    {/* <div><CommentIcon/></div> */}
                    <div className='edit-icon'><EditIcon className="tp-edit" onClick={()=>topicSelected(topic,lrn)}/></div>
                </li>
            })}
        </ul>)
    }
    
    const getStatus=(val)=>{
        if(val?.lt===null) return "Deleted";
        if(val?.ar===null) return "Added";
        return "updated"
    }

    const renderPublishHistory = (list,author,tdata) => {

        try{
           if(list?.version_json){
            // send the author info
             return renderLEStatus(list?.version_json, 2, list?.author);
           }

        //    function handleRestore(lrn,type,lrnKey){
        //         let lrnElement={
        //             lrn:lrn||{},
        //             topic:tdata
              
        //         }
        //          lrnElement.lrn.ar={...lrn?.ar,_id:lrnKey}
        //          setSelectedLRN(lrnElement)
        //          setIsRestore(true);
        //    }
         
            return (<ul>
                {!list?.lrncontent ? <li className="flex-item">
                    <div className='lrnaid_col'>The position of LearningAid has been updated</div>
                    </li> : Object.entries(list?.lrncontent)?.map(([ket, val], index) => {
                    let lrn=val?.lt
                    let type=getStatus(val);
                    let name=lrn?.name||lrn?.cname||val?.ar?.cname||val?.ar?.name
                    return <li key={index} className="flex-item">
                        <div className='lrnaid_col'>{PlayerConstants?.LRN_RE_NAME[name]||name} </div>
                        {/* displaying the published time and author's name */}
                        <div>By  {author?.name || userInfo?.user?.firstname|| "Author Name"}</div>
                        <div> {type}</div>
                        {/* <div><CommentIcon/></div> */}
                        {/* <div><RestoreIcon className="tp-edit" onClick={()=>handleRestore(val,type,ket)}/></div> */}
                    </li>
                })}
            </ul>)
        }catch(e){}
    }

/**
 * 
 * @param {*} level 
 * @returns 
 * level -1 normal change
 * level -2 Bulk update change using api
 */
const StatusList = (level) => {
    let hasLevel=(level===2);
    const hasData=tpByStatus[selectedIndex]?.topics;
    return (
        <ul className={`${hasLevel && 'status-change'}  status-list`}>
        {Object.values(tpByStatus)?.length > 0 &&
            Object.values(tpByStatus)?.map((option, index) => {
            let isSelected=option?.id === selectedIndex;
            const hasUpdate=(!hasLevel && isSelected);

            return ((!hasLevel||!isSelected) && <li key={option?.id}>
                <span
                className={isSelected ? 'status-selected fw-600' : 'list-item'}
                onClick={(event) => handleMenuItemClick(event, option?.id, level)}
                >
                {option?.status}
                </span>
                {(hasUpdate && hasData )&& <span onClick={()=>setStatusChange({...statusChange,is:true})}>Update to</span>}
            </li>)
            })}
        </ul>
    );
    };
      



    const topicStatusList = () => {
        return (
            <>
                <div onClick={handleClickListItem} className="tp-top-head">
                  <ListItemText primary="Status of learning elements" secondary={`Update the selected status from ${tpByStatus[selectedIndex]?.status} to`} />
                </div>
               {isShowList && StatusList(1)}
               {statusChange?.is && StatusList(2)}
            </>
        );
    }

    const openAcc = (el,key) => {
        if(expanded[key] === el){
                setExpanded((prevExpanded) => ({
                  ...prevExpanded,
                  [key]:false,
                }));
        }else{
            setExpanded((prevExpanded) => ({
                ...prevExpanded,
                [key]:el,
              }));
        }
        setStatusList(false);
    }

    
    return (
        <div className='log-history'>
            {!isPublishHis && topicStatusList()}
            <br></br>
            {isPublishHis ? renderLEStatus(courseEdit?.publishVersion,1) : renderLEStatus(tpByStatus[selectedIndex]?.topics,1)}
          {statusChange?.change && <CustomSweetAlert 
              warning
              showCancel
              show={true}
              confirmBtnText="Update"
              confirmBtnBsStyle="danger"
              title={<p className="sweet-title">{`Are you sure you want to update all learning elements to ${tpByStatus[statusChange?.change]?.status}?`}</p>}
              onConfirm={() =>handleBulkChange()}
              onCancel={() =>handleCancel()}
            />}
        </div>
    )
}




export default TopicHistory
