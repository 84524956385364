
const ACLHelper = {
    permission: { // static reference 
        1:'add',
        2:'edit',
        3:'delete',
    },
    acl:{},
    selectedRole:{},
    initialize:(data,roleId)=>{
        ACLHelper.acl = data;
        console.debug("::::::::::::::::::::::::::::::ACL INITIALIE:::::::::::::::::::::",data);
        ACLHelper.acl.selectedRole = data?.acl[roleId];
        ACLHelper.acl.selectedRole = data?.acl[roleId];

        const nameRole = Object.keys(data?.roles).find(i =>{ return Number(data?.roles[i].id) === Number(roleId)})
        let roleInfo = {_id:null, id:null, name:null};
        roleInfo =  data?.roles[nameRole]
        ACLHelper.acl.selectedRole.roleInfo = roleInfo
        console.log( roleInfo)
    },
    /** 
     * featureid and permission id input 
     * if the featureid matched & check the grants 
     */
    isFeatureEnabled:(fid,pId)=>{
        try{
            // console.debug("FEATURE:"+ACLHelper.acl?.features[fid]?.id+":"+ACLHelper.acl?.features[fid]?.name,pId);
            // console.debug("PERMISSION:"+ACLHelper.permission[pId]);

            const acl = ACLHelper.acl?.selectedRole?.access?.filter((item)=>{
                    return item.fid === fid
            });

            if(acl && acl?.length > 0){
                return acl[0]?.grants.indexOf(pId) > -1 ? true:false
            }
            return false 
        }catch(e){
            console.log("e",e)
        }
    },
    getRolName:(id) => {
        if(ACLHelper.acl?.selectedRole?.roleInfo !== undefined)    {
            return ACLHelper.acl.selectedRole.roleInfo
        }
        return {_id:0, id:0, name:'Editor / Author'};
    }
     
}

export default ACLHelper