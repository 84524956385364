import React from 'react';
import './loader.scss';

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loading-spinner">
            <div className="loading-text">Loading</div>
        <div className="dots-container">
          <div className="dot1"></div>
          <div className="dot2"></div>
          <div className="dot3"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
