import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types'
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Acc from '@material-ui/core/Accordion';
import CheckIcon from '@material-ui/icons/Check';
import { AddCircle } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import {FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

/**
 * 
 * RadioSelectPanel editor used to modify the propertis of question and title of the component
 */
/**
 * 
 * @param {title and question} param0 
 * @returns 
 */
const RadioSelectPanel = ( { title, description, question, handleProperties, options,answer,cename,explanation} ) => {

    const [value, setValue] = React.useState(0);
    const [ editMode, setEdit ] = React.useState( null );
    const [ list, setList ] = React.useState( options !== undefined ? JSON.parse( JSON.stringify( options ) ) : [] )

    const [info,setExplination] = useState(explanation)
    const [ data, setData ] = useState( { title, description } )

    const handleChangeInput = ( e ) => {
        setData( {
            ...data,
            [ e.target.name ]: e.target.value
        } )
    }

    useEffect( () => {
        handleProperties( data )
    }, [ data ] );


    const eventHandlers = ( value ) => {
        setData( {
            ...data,
            question: value
        } )
        handleProperties( data )
    }



    const handleOpInputChange = ( e, id ) => {
        list[ id ][ e.target.name ] = e.target.value
        setList( list )
        handleProperties( { options: list } )
    }

    const handleEdit = ( e, index ) => {
        setEdit( index )
        e.stopPropagation()
    }

    const handleSave = ( e, index ) => {
        setEdit( null )
        e.stopPropagation()
    }

    const handleDelete = ( e, index ) => {
        const removed = list.filter( ( e, i ) => i !== index )
        setList( removed )
        handleProperties( { options: removed } )
        e.stopPropagation()
    }

    const addAccordion = () => {
        let max_count = 3;
        const maxid = Math.max(...list?.map(item => item?.id), 0)||1;
        if ( max_count > list.length ) {
            list.push( {
                id: maxid + 1,
                title: 'Title',
                value: maxid + 1
            } )
            setList( list )
            handleProperties( { options: list } )
        }
    }


       
    const handleRadioChange = (e)=>{
        let value = Number(e.target.value)
        setValue(value);
        handleProperties( { answer: value } )
    }


    const changeRInformation = ( value ) => {
        setExplination( {
            ...info,
            right: value
        } )
        setData( {
            ...data,
            explanation:info
        } )

        handleProperties( data )
    }

    const changeWInformation = ( value ) => {
        setExplination( {
            ...info,
            wrong:value
        } )

        setData( {
            ...data,
            explanation:info
        } )

        handleProperties( data )
    }
   
    return (
        <div className='radio-prop-panel x-align'>
            <TextField className='question-header' id="outlined-basic" spellCheck={ false } name='title' label="Title" variant="outlined" defaultValue={ title } onClick={ ( e ) => e.stopPropagation() } onChange={ ( e ) => handleChangeInput( e ) } />
            <div className='mt10'>
                <label className='label'>Description</label>
                <RichTextEditor data={ question } eventHandler={ eventHandlers } />
            </div>
            <div className='mt10'>
                <label className='label'>Answer Options</label>
            </div>
            <div className='inner-accordion-continuer'>
                <div className={ `` }>
                    {
                        options && options.length > 0 && options.map( ( el, index ) => {
                            return <Acc key={ index }>
                                <AccordionSummary  className='inner-summary' aria-controls="panel1a-content" id="panel1a-header" >
                                    { editMode === index ? <>
                                        <TextField id="outlined-basic" className='list-title'  spellCheck={ false } name='title'
                                         defaultValue={ el.title }
                                        onClick={ ( e ) => e.stopPropagation() } 
                                        inputProps={ { maxLength: 30 } }
                                        onChange={ ( e ) => handleOpInputChange( e, index ) } />
                                        <CheckIcon className='save-prop-accordion' onClick={ ( e ) => handleSave( e, index ) } />
                                    </> : <><Typography className={ `label-bold` }>{ el.title }  </Typography><EditIcon className='edit-prop-accordion' onClick={ ( e ) => handleEdit( e, index ) } /> <DeleteIcon className='delete-prop-accordion' onClick={ ( e ) => handleDelete( e, index ) } /> </> }
                                </AccordionSummary>
                            </Acc>
                        } ) }
                        <br/>
                    <div className='add-new-slide' title={ 'Add Option' } onClick={ addAccordion }><AddCircle  className='add-accordion' /> Add Answer Option </div>
                </div>
            </div>
            <div>
                <label className='label-bold' id="demo-radio-buttons-group-label">Select Correct Answer</label>
                <RadioGroup 
                row
                aria-label="Options"
                name="radio-options"
                value={answer}
                className='row-radio-group'
                onChange={handleRadioChange}
                >
                    {options && options?.length > 0 && options.map((el) => <FormControlLabel  
                    value={el?.value} 
                    control={<Radio/>} 
                    className='radio-chk'
                    label={el?.text || el?.title} key={el?.id}/>)}
                </RadioGroup>
            </div>
            <div>
                <p className='label'>Right Answer Feedback</p>
                <RichTextEditor data={ explanation?.right } eventHandler={changeRInformation }  styles={{minHeight:80}}/>
            </div>
            <div>
                <p className='label'>Wrong Answer Feedback</p>
                <RichTextEditor data={ explanation?.wrong } eventHandler={ changeWInformation }   styles={{minHeight:80}}/>
            </div>
        </div>
    )
}

RadioSelectPanel.propTypes = {
    title: PropTypes.string,
    question: PropTypes.string,
    options:PropTypes.array,
    explanation:PropTypes.object,
    answer:PropTypes.number,
    handleProperties: PropTypes.func
}

export default RadioSelectPanel