import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import PropTypes from 'prop-types'
import videojs from "video.js";
import "video.js/dist/video-js.css";
import hotkeys from "hotkeys-js";
import PlayerConstants from '../../../utils/PlayerConstants';
// import { pathCreater,ASSET_PATH } from "../../../utils/MediaConfig";
import './videocontainer.scss'
import './videocontrols.scss'
import './overlaypropsetter.scss'
import CuePoints from '../Video/CuePoints'
import OverLayRender from '../Video/OverLayRender';
import VideoContentText from "../Video/VideoContentText";
import Instruction from "../../ui/Instruction/Instruction";
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import CuePoint from "../../../utils/Cuepoint";
import DataHelper from "../../../utils/DataHelper";
import BuildHelper from "../../../utils/BuildHelper";
import { pathCreater } from "../../../utils/MediaConfig";
import ComponentConstants from '../../../utils/ComponentConstants';

/**
 * 
 * @param {*} src 
 * @returns video source url of the component
 */

/**
 * 
 * @param {*} cuepoints 
 * @returns cuepoints array for the video for the displaying message
 */

/**
 * 
 * @param {*} color 
 * @returns display colors of the cuepoints
 */

/**
 * 
 * @param {*} poster 
 * @returns Url for background overlay image
 */

/**
 * 
 * @param {*} readable 
 * @returns innerhtml string for rendering some content
 */

/**
 * video player interactivity content component can be customized using below props 
*/
const ArchiveVideo = ({ src, cuepoints, colors, poster, readable, track, handler, cename, instructions, description, content, subtitleurl, topic_id,ccid, forward_rewind }) => {
    const userInfo = {};
    userInfo.user = window?.ce?.ceuser;
    const videoPlayerRef = useRef(null);
    if (track === undefined || handler === undefined) {
        track = {}
        handler = () => {

        }
    }
    // console.log('track video', track, handler)
    const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
    const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

    const [playerReady, setPlayerReady] = useState(false);
    const [isOverLay, setOverLay] = useState(false)
    const [duration, setDuration] = useState(0)
    const [status, setStatus] = useState({ completed: false, text: 'Pending' })
    const [isDragEnabled, setDragEnabled] = useState(false)
    const [instruction, setInstruct] = useState(instructions)
    const [showTextVersion, setTextVersionVersion] = useState(false)
    const [TextVersion, setTextVersion] = useState('');
    const [currentCue, setCurrentCue] = useState({})
    const [player, setPlayer] = useState({});
    const [viewInDevice, setViewDevice ] = useState("Desktop");
    const [showCCTextVersion, setCCTextVersion] = useState(false)
    const [controlArea, setControlArea ] = useState(null);
    const videoAsp = () => {
        let contentAreaWidth = document?.getElementsByClassName('content')[0]?.clientWidth;
        return DataHelper.getVideoAspectRatio(contentAreaWidth)
    }
    const [videAsp, setVideoAsp] = useState(videoAsp);

    useLayoutEffect(() => {
        function updateSize() {
            setVideoAsp(videoAsp);
            // console.debug("videAsp",videAsp)
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    // useEffect(()=>{
    //     console.log("position i am in",);
    //     let element = document.getElementsByClassName('vjs-progress-control');
    //     setProgressBarWidth(element?element[0]?.clientWidth:0)
    // },[])
    // // console.log("position i am window",this.displayRef)

    if(track === undefined || handler === undefined) {
        track = {}
        handler = () => {

        }
    }

    const enablehotKeys = () => {
        hotkeys('ctrl+alt+7, ctrl+alt+d', function (event, handler) {
            switch (handler.key) {
                case 'ctrl+alt+7':
                    setDragEnabled(true)
                    //console.log('enabled video seek', isDragEnabled)
                    break;
                case 'ctrl+alt+d':
                    setDragEnabled(false)
                    //console.log('disabled video seek', isDragEnabled)
                    break;
                default: return 1;
            }
        });
    }

    let videoJSOptions = {
        playbackRates: [1],
        controls: true,
        autoplay: false,
        responsive: true,
        muted: false,
        // poster: DataHelper.getResourcePath(0,poster),
        userActions: { hotkeys: true },
        controlBar: {
            volumePanel: true,
            pictureInPictureToggle: true
        }
    };

    if(!["","*"].includes(poster)){
     videoJSOptions = {
        ...videoJSOptions,
        poster: DataHelper.getResourcePath(0,poster)
    }
    }



    // const [overlayColor, setOverlayColor] = useState(colors.length > 0 ? colors[0].color: []);
    const [overlayColor, setOverlayColor] = useState([]);


    /**
    * Manage the component update 
    * progress logic in this method
    * 
    * Update the view status when ever the user interacts
    * Update the progess status ONLY ONCE, when status completes
    * 
    */
    const updateProgress = (currentItem) => {
        // console.log('track is', track)
        switch (currentItem.type) {
            case 'END':
                if (track?.status === 0) {
                    console.log('track video', track)
                    track.status = 1
                    setInstruct({
                        ...instruction,
                        text: `You have completed this interactivity`,
                        className: 'completed'
                    })
                    setStatus({ completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE })
                    handler({ type: SAVE_PROGRESS_STATUS, id: track?.id, name: cename })
                }
                break;
            case 'PLAYHEAD':
                if (!track?.status) {
                    track.status = 0
                } else if (!track?.state) {
                    track.state = { ...track.state, t: currentItem.time }
                }
                handler({ type: UPDATE_STATUS, id: track?.id, name: cename })
                break;
        }
    }

    const closeOverLay = () => {
        setOverLay(false);
        videoPlayerRef.current.play();
    }

    const showCuePoints = (cue) => {
        // cue.shown = true;
        // setCurrentCue(cue)
        if (cuepoints[cue].ctype != 5) {
            cuepoints[cue].shown = true;
            setCurrentCue(cuepoints[cue])
            /* Need to show content here */
            setOverLay(true)

            videoPlayerRef.current.pause();
            setOverlayColor([getColorByType(cuepoints[cue].ctype)])
        }

    }

    const getColorByType = (type) => {
        let colors = {
             0: { c: '#ffffff' },
             1: { c: '#ffffff' },
            2: { c: '#F3C465' },
            3: { c: '#81b84d' },
            4: { c: '#81b84d' },
            5: { c: '#81b84d' }
        }
        return colors[type].c
    }

    const renderCuePoints = (time) => {
        const tolerance = 0.12; // Adjust this value as needed
        for (const i in cuepoints) {
            const cuePointTime = Number(cuepoints[i].point);
            const isWithinTolerance = Math.abs(time - cuePointTime) < tolerance;
    
            if (isWithinTolerance ) {
                showCuePoints(i);
            }
        }
    };
    const getResourceUrl = (path)=>{
        return DataHelper.getResourcePath(1,path)
    }
/** check the url is absolute or only video name is given */
const getAbsUrl = (src)=>{
    try{
        if(src)
        return src.startsWith('http') ? src:getResourceUrl(src)
    }catch(e){
        console.log(e)
    }
}
    const getVideoUrl = () => {
    try{
        let selected = { url: src, type: 'video/mp4' };
        let ar = src?.split('.');
        let ext = ar[ar.length - 1];
        if (ext === "m3u8") {
            selected.url = src;
            selected.type = 'application/x-mpegURL';
        }
        if(BuildHelper.isOnScorm() && ext === "m3u8")    {
            let vpath = src?.split('/')
            let spath = pathCreater(1)+vpath[vpath?.length-2]+'/'+vpath[vpath?.length-1];
            selected.url = spath;
            console.log("***********|||||||***********",selected)
        }
        return selected;
    }catch(e){
        // return {url:getAbsUrl(src),type:'video/mp4'};
        console.log("m3u8 not supported")
    }
    }
    /** if API serves file path then use this is get the TextVersion */

    // const getContentText = () => {
    //     let path = require(`../../../assets/data/text/${getVideoFileName(src)}.txt`).default
    //     fetch(path)
    //     .then(response => response).then(res => res.text())
    //     .then(text => setTextVersion(text))
    //     .catch(err => setTextVersion('No Content Found'))
    // }

    /** Should be there in middleware services 
     * TODO
    */

    // const getSubtitle = () => {
    //     const options = {
    //         headers :{
    //             'Authorization': window.ce?.platform_scorm?.authtoken
    //         }
    //     }
    //     let path = "https://d14rwvged4djyq.cloudfront.net/type3/87/subtitlevtt/CEIntro_IMP_S2_M1.vtt";
    //     fetch(path, options.headers)
    //     .then(data => data.json())
    //     .then(res => console.log('subtitle =>', res.data))
    //     .catch(err => console.log('Error in subtitle response'))
    // }

    const cueRender=(playerEle)=>{
        const progressControl = playerEle.controlBar.progressControl.seekBar.el();
        const customDiv = document.createElement("div");
        customDiv.className = "custom-progress-div";
        const customProgressDiv = document.querySelector(".custom-progress-div");
        //if the element exists before attempting to removing it
        if (customProgressDiv) {customProgressDiv.remove();}
        setControlArea({parentEel:progressControl,cueEle:customDiv});
    }

    useEffect(() => {
        // getContentText()
        // getSubtitle()
        if (videoPlayerRef) {
            let videoInfo = getVideoUrl();
            let player = videojs(videoPlayerRef.current, videoJSOptions, () => {
                player.src({
                    src: `${videoInfo?.url}#t=0.5`,
                    type: videoInfo?.type,
                });
                player.on('loadedmetadata', () => { })
                player.on('loadstart', () => { });
                player.on('play', (e) => {
                    setPlayerReady(true);
                    addCuePoint();
                });
                player.on('playing', (e) => {
                    // console.log('duration', Math.floor(videoPlayerRef.current.duration))
                    setDuration(Math.floor(videoPlayerRef.current.duration))
                    const mediaEle = document.querySelectorAll('video, audio')
                    for (const media of mediaEle) {
                        if (media !== e.target.children[0]) {
                            media.pause()
                        }
                    }
                })
                player.on("ended", () => {
                    updateProgress({ type: 'END' });
                    if (player.isFullscreen()) {
                        setTimeout(() => {
                          player.exitFullscreen(); 
                        }, 100); // Wait for the duration
                      }
                    for (const i of cuepoints) {
                        i.shown = false
                    }
                });

                player.on("timeupdate", () => {
                    updateProgress({ type: 'PLAYHEAD', time: player.currentTime() })
                    if (cuepoints?.length > 0) {
                        try {
                            renderCuePoints(player.currentTime())
                        } catch (e) {
                            console.debug(e);
                        }
                    }
                });
            });
            cueRender(player)
            setPlayer(player);
        }
    }, [src]);

    useEffect(()=>{ 
        try{ cueRender(player);}catch(e){} 
    },[cuepoints])

    useEffect(() => {
        enablehotKeys()
        let controlBar = document.querySelectorAll('.vjs-progress-control')
        if (isDragEnabled) {
            controlBar.forEach(el => el.style.pointerEvents = 'initial')
        } else {
            controlBar.forEach(el => el.style.pointerEvents = 'none')
        }
    }, [isDragEnabled])

    const handleTextVersion = () => {
        setTextVersionVersion(true)
        videoPlayerRef?.current?.pause();
    }

    const closeTextOverlay = () => {
        setTextVersionVersion(false)
        videoPlayerRef?.current?.play();
    }

    const getCueList = (list) => {
        const mapped = list?.map((el) => {
            return new CuePoint(el)
        });
        return mapped;
    }

    const getFileName = (src) => {
        let srcUrl = src?.split('/')
        return { 'filename': srcUrl[4], 'CCID': srcUrl[3], }
    }

    /**
     * Archived video file is served from streamed location 
     * Need to plan for subtitle handling
     * Previous version of player .srt files ..current version player supports only .vtt files
     * TODO
     */
    //  https://d14rwvged4djyq.cloudfront.net/type3/87/subtitlevtt/CEIntro_IMP_S2_M1.vtt
    const getSubtitlePath = () => {
        try {
            // https://assets.contentenablers.com/rlm/topics/616d1ae6424d275113d542bb/TCO_UK_FI_Intro.vtt
            if(src && !BuildHelper.isOnScorm()){
                let formatted = getFileName(src);
                let path = PlayerConstants.CC_TXT_PATH + formatted.CCID + '/subtitlevtt/' + formatted.filename + '.vtt';
                return path;
            }
            else if(BuildHelper.isOnScorm()){
                let ar = src.split('/');
                let file = ar[ar?.length-1];
                let fn = file.split('.')
                // let formatted = getFileName(src);
                return BuildHelper.getExternalPath() + './content/en_US/subtitlevtt/'+ fn[0] + '.vtt';
            }
            else{
                return "http://d14rwvged4djyq.cloudfront.net/type3/81/subtitlevtt/NZL_IMP_S3_M8.vtt"
            }
          

        } catch (err) {
            console.log("err", err);
            setCCTextVersion(false);
            return require(`../../../assets/data/subtitle/subtitle_eng.vtt`).default
        }

    }

    const timeSeek = (secs) => {
        let time = player.currentTime() + secs;
        if (time < 0) {
            time = 0;
        }
        player.currentTime(time);
    }

    const forward = () => {
        timeSeek(10);
    }

    const rewind = () => {
        timeSeek(-10);
    }
   

  const onResize = (e) => {
    if(e.target?.innerText?.trim()=="Mobile"||e.target?.innerText?.trim()=="Tablet"||e.target?.innerText?.trim()=="Laptop"||e.target?.innerText?.trim()=="Desktop"){
        setViewDevice(e.target?.innerText?.trim())
    }
}

  React.useEffect(() => {
    window.addEventListener("mousedown", onResize);
    return () => {
      window.removeEventListener("mousedown", onResize);
    };
  }, []);

/* Cue Point add & edit */
const editPanelOpen = (edit) => {
    if( Number(window.ce.ceuser.role_id || 1) < 4){
        let editItem = edit
        if (edit !== -1) { editItem = { ...edit }; player.currentTime(edit?.time);};
        let curentTime=(Math.round(player.currentTime() * 100) / 100).toFixed(2);
        handler({ type: ComponentConstants?.SAVE_COMP_PROPS, data: { cename, topic_id, cduration:curentTime, isEdit: editItem } })
        if (edit === -1) setCurrentCue(cuepoints?.length - 1);
         // if(edit===-1){
        //     let newCue = {
        //         data:{content: "Content",},
        //         content: "Content",
        //         cuePlot: 40,
        //         id: cuepoints.length + 1,
        //         shown: false,
        //         time: player.currentTime(edit?.time),
        //         title: "",
        //         type: 1
        //     }
        //     cuepoints.push(newCue);
        // }
    }
}



/* add cuepoints */
const addCuePoint=()=>{
    if( Number(window.ce.ceuser.role_id || 1) < 4){
        setDragEnabled(true);
        let isButtonCheck = document.querySelectorAll('.vjs-playing .vjs-play-progress .add-video-sub');
        if (playerReady && !isButtonCheck[0]) {
            let playProgress = document.querySelectorAll('.vjs-playing .vjs-play-progress');
            let html = `+<br><span class="current-time">${formatTime(videoPlayerRef.current.currentTime)}</span>`; // Display current time
            let div = document.createElement('div');
            for (var i = 0; i < playProgress?.length; i++) {
                playProgress[i].appendChild(div);
            }
            div.addEventListener('click', function (event) {
                player.pause();
                editPanelOpen(-1);
                event.preventDefault();
            });
            div.innerHTML = html;
            div.classList.add("add-video-sub");
            div.setAttribute("data-gloss", 'Add  Cuepoints');
            setInterval(() => {
                const currentTimeSpan = div.querySelector('span');
                if (currentTimeSpan) {
                    currentTimeSpan.textContent = formatTime(videoPlayerRef.current.currentTime);
                }
            }, 1000);
            
        }
    }
}
function formatTime(seconds) {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = Math.floor(seconds % 60);
    return `${padZero(minutes)}:${padZero(remainingSeconds)}`;
}
function padZero(num) {
    return (num < 10 ? '0' : '') + num;
}
const isCueCheck = (cue) => {
    try {
        for (var i in cuepoints) {
            if (cuepoints[i]?.point === cue?.point) {
                return true
            }
        }
        videoPlayerRef.current.play();
        return false;
    } catch (e) { }
}


    return (
        <>
            <Instruction isInstruction={instruction?.enabled} completed={(track?.status > 0 || status?.completed)} title={(track?.status > 0 || status?.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track?.status > 0 || status?.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track?.status > 0 || status?.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text}/>
            <div className="video-container" onMouseOver={addCuePoint}>
                <video
                    ref={videoPlayerRef}
                    className="video-js"
                    data-setup='{"fluid": true}'
                >

<track kind='captions' src={getSubtitlePath()} srcLang='en' label='English' default={false}
        mode={showCCTextVersion ? 'showing' : 'hidden'} />
                </video>
                <div className='vjs-video-text-version arc-text-version' onClick={handleTextVersion} title="Text-version">
                    <SubtitlesIcon className='video-text-icon' />
                </div>
                {playerReady && cuepoints?.length !== 0 && <CuePoints data={getCueList(cuepoints)} duration={duration} viewInDevice={viewInDevice} onClick={(i) => editPanelOpen(i)} parent={controlArea} />}
                {isOverLay && isCueCheck(currentCue) && currentCue?.ctype != 5 && <OverLayRender type={currentCue?.ctype} data={currentCue} bgcolor={overlayColor} close={() => closeOverLay()} userInfo={userInfo}/>}
                {showTextVersion && <VideoContentText contentText={content} close={() => closeTextOverlay()} />}
                {(readable || description) && <div className='readable-text' dangerouslySetInnerHTML={{ __html: description ? description : readable }}></div>}
            </div>
            {forward_rewind && <div className="forward-backward-btns">
                <img alt='forward-icon' className="forward" src={require(`../../../assets/img/forward-ten_1.png`).default} onClick={() => forward()} />
                <img alt='backward-icon' className="backward" src={require(`../../../assets/img/back-ten_1.png`).default} onClick={() => rewind()} />
            </div>}
        </>
    );
};


ArchiveVideo.defaultProps = {
    instructions: {
        text: 'Please view the video to proceed.',
        enabled: false
    }
}

ArchiveVideo.propTypes = {
    /** Video source url of the component */
    src: PropTypes.string.isRequired,
    /** Poster Url for background overlay image */
    poster: PropTypes.string,
    // /** Innerhtml string for rendering some content* */
    readable: PropTypes.string,
    /** Cuepoints array for the video for the displaying message */
    cuepoints: PropTypes.array,
    /** Display colors of the cuepoints overlay*/
    colors: PropTypes.array,
    /** Tracking the component progress */
    track: PropTypes.object,
    /** Func description for tracking*/
    handler: PropTypes.func,
    /** Cename describes name of component*/
    cename: PropTypes.string

};

export default ArchiveVideo