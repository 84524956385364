import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  setBlockData,
  getSelectedBlocksMetadata,
} from 'draftjs-utils';
import './RichTextEditor.scss';
import { EditorState, Modifier } from 'draft-js';

export default class CustomStyle extends Component {
  constructor(props) {
    super(props);
    const { editorState, modalHandler, type } = props;
    const currentSelectedValue = getSelectedBlocksMetadata(editorState)?.get(type);
    // If any style needs to be added, add separate check
    const option = type === 'line-height' ? options?.lineHeight : options?.listStyle;
    this.state = {
      expanded: false,
      currentSelectedValue: editorState
        ? option?.map(el=>el.value).indexOf(Number(currentSelectedValue))+1
        : undefined,
        option:option
    };
    modalHandler.registerCallBack(this.expandCollapse);
  }

  

  componentDidUpdate(prevProps) {
    const { editorState,type } = this.props;
    if (editorState && editorState !== prevProps.editorState) {
      const currentSelectedValue = getSelectedBlocksMetadata(editorState).get(type);
      // let index=this.state.option?.map(el=>el.value).indexOf(currentSelectedValue)
      const index = this?.state?.option?.findIndex(el => el?.value === currentSelectedValue);
      this.setState({
        currentSelectedValue: this.state.option[index]?.title
      });
    }
  }

  componentWillUnmount() {
    const { modalHandler } = this.props;
    modalHandler.deregisterCallBack(this.expandCollapse);
  }

  onExpandEvent = () => {
    this.signalExpanded = !this.state.expanded;
  };

  expandCollapse = () => {
    this.setState({
      expanded: this.signalExpanded,
    });
    this.signalExpanded = false;
  };

toggleInlineHeight =(value) => {
  try{
    const { editorState, onChange, type } = this.props;
    const currentStyleMap = getSelectedBlocksMetadata(editorState)
    let nextStyleObject = {};
    let text_align=getSelectedBlocksMetadata(editorState)?.get('text-align');
    let padding_left=getSelectedBlocksMetadata(editorState)?.get('padding-left');
    currentStyleMap.forEach((value, key) => {
      nextStyleObject[key] = value;
    });
    let styleObject={}
    if(type === 'line-height'|| type ==='list-style-type'){
      let textCheck=text_align ? {'text-align': `${text_align}`} :{'text-align':'left',};
      let paddingCheck=padding_left ? ' ' : {'padding-left': '4px',};
      let listStyle=(type==='list-style-type') ? {'list-style-type': value,...paddingCheck,"list-style-position":'outside'} : {'line-height': value};
       styleObject={
        ...textCheck,
        ...listStyle,
      }
    }else{
      let padding={'text-align':'left'};
       const paddingValue=Number(padding_left?.split('px')[0])
      if(value==='ADD'){
        if(paddingValue >= 60 && paddingValue <=300 ){
          padding={...padding,'padding-left': `${paddingValue+60}px`}
        }else if(paddingValue < 60 || !paddingValue) padding={...padding,'padding-left':'60px'}
      }else{
        if(paddingValue >= 60){
          padding={...padding,'padding-left': `${paddingValue-60}px`}
        }else{
          padding={}
        }
      }
      styleObject={
        ...padding
      }
    }
   nextStyleObject = Object.assign({}, nextStyleObject, { ...styleObject});
  
    const newState = setBlockData(editorState, nextStyleObject);
    if (newState) {
      onChange(newState);
    }
  }catch(e){}
  };

  
   addQuote =(quote)=>{
    const { editorState, onChange} = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      quote,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  }

   renderModal=()=> {

    return (<ul
      className='rdw-dropdown-optionwrapper'
    >
      {this.state.option?.map((el, index) => {
        return <li
        onClick={(e) => this.toggleInlineHeight(el.value)}
         className='rdw-dropdownoption-default rdw-fontsize-option customoption-drop-dowen'
          key={index}
          title={el.title}
        >{el.title}</li>
      })}
    </ul>
    )
  }

  getIcons = () => {
    const { expanded, currentSelectedValue } = this.state;
    const { type } = this.props;
    const defaultLineHeight = Number(this.state.option?.map(el => el.value).indexOf(1.2))
    const selectedValue = (currentSelectedValue || expanded) && (this.state.option?.length > 0 && defaultLineHeight >= 0 && 2);
    let title = type;
    // eslint-disable-next-line default-case
    if (type === 'quote')
      return <><div className='quote-symbol' title='Quotes' onClick={() => this.addQuote('❝')}> ❝ </div>
        <div className='quote-symbol' title='Quotes' onClick={() => this.addQuote('❞')}> ❞ </div> </>;

    else if (type === 'line-height' || type === 'list-style-type')
      return <>
        <div className='rdw-block-wrapper custom-lineheight' onClick={this.onExpandEvent}>
          <div
            aria-expanded={expanded}
            className='rdw-dropdown-wrapper rdw-fontsize-dropdown demo-option-custom-medium line-height-dropdown'
            aria-label="rdw-dropdown"
            title={title}
          >
            <a className='rdw-dropdown-selectedtext' title={title}>
              {(selectedValue || currentSelectedValue) ?
                <span>{currentSelectedValue}&nbsp;&nbsp;&nbsp;</span> :
                type === 'line-height' ?
                  // <img
                  //   className='specing-icon'
                  //   src='https://static.thenounproject.com/png/313536-200.png'
                  //   alt="Specing"
                  // />
                  3 : <p>List Style &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>}

              <div className={!expanded ? 'rdw-dropdown-carettoopen' : 'rdw-dropdown-carettoclose'}></div>
            </a>
            {expanded ? this.renderModal() : undefined}
          </div>
        </div>
      </>;

    else
      return <><div className='indent-icon' title='Text Indent' onClick={() => this.toggleInlineHeight('ADD')}>
        <img
          className='specing-icon'
          src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNNS43MTYgMy4yMTFIMTd2MS4xOTdINS43MTZ6TTAgLjAyaDE3djEuMTk3SDB6TTAgMTIuNzgzaDE3djEuMTk3SDB6TTUuNzE2IDkuNTkzSDE3djEuMTk3SDUuNzE2ek01LjcxNiA2LjQwMkgxN3YxLjE5N0g1LjcxNnpNLjE4NyA5LjQ5MUwyLjUyIDcgLjE4NyA0LjUwOXoiLz48L2c+PC9zdmc+'
          alt="Specing"
        /> </div>
        <div className='indent-icon' title='Text Outdent' onClick={() => this.toggleInlineHeight('REMOVE')}>
          <img
            className='specing-icon'
            src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNNS4zOTYgMy4xOTNoMTAuNTczVjQuMzlINS4zOTZ6TS4wMzkuMDAzaDE1LjkzVjEuMkguMDM5ek0uMDM5IDEyLjc2NmgxNS45M3YxLjE5N0guMDM5ek01LjM5NiA5LjU3NWgxMC41NzN2MS4xOTdINS4zOTZ6TTUuMzk2IDYuMzg0aDEwLjU3M3YxLjE5N0g1LjM5NnpNMi4xODcgNC40OTFMMCA2Ljk4M2wyLjE4NyAyLjQ5MXoiLz48L2c+PC9zdmc+'
            alt="Specing"
          /> </div>
      </>;
  }


  render() { return this.getIcons() }
}

export const options ={
  lineHeight:[
    {title:1,value:1},
    {title:2,value:1.2},
    {title:3,value:1.5},
    {title:4,value:2},
    {title:5,value:2.5},
    {title:6,value:3},
    {title:7,value:3.5},
    {title:8,value:4}],
  listStyle:[
      {title:'UnOrder List',value:'disc'},
      {title:'Order List',value:'decimal'},
      {title:'Circle',value:'circle'},
      {title:'Square',value:'square'},
      {title:'Small Roman',value:'lower-roman'},
      {title:'Caps Roman',value:'upper-roman'},
      {title:'Small Alpha',value:'lower-alpha'},
      {title:'Caps Alpha',value:'upper-alpha'},
    ]
}

CustomStyle.propTypes = {
  /** Func description for tracking the State Changes */ 
  onChange: PropTypes.func,
  /** Current editorState and datas of content */ 
  editorState: PropTypes.object,
 /** Func description for tracking the modal open */ 
  modalHandler: PropTypes.object,
};