const CommonHelper = {

    isObjEmpty: ( ( obj ) => {
        return Object.keys( obj ).length === 0 ? true : false;
    } ),
    /**
     * This method calls iteratively
     * Please check
     */
    setUITpProgress: ( ( tps, flatten ) => {
        try {
            tps.forEach( ( el ) => {
                if ( flatten[ el.flatId ] ) {
                    flatten[ el.flatId ].p = el.percent
                    flatten[ el.flatId ].status = el.status
                }
            } );
        } catch ( err ) {
            console.debug( "progress", err )
        }
    } ),


    setCurrentStatus:((tps,node)=>{
        tps.forEach(el => {
            if(el.flatId === node.nodeId){
                el.status = ((el.status === -1 || el.status === undefined)  ? 0:el.status)
            }
        });
    }),

    setUICourseProgress:((tps)=>{
        let cp = 0;
        tps.forEach( ( el ) => {
            cp+=el.percent
        } );

        return Math.round( (cp/(tps.length*100)) *100);
    }),

      /** Deprecated */

    setTopicCompletion:((topic,trackInfo)=>{
        console.debug("topic,trackInfo",topic,trackInfo)
        if(!CommonHelper.isObjEmpty(topic)){
            if(trackInfo.courseTracking){
              trackInfo.courseTracking.topic.forEach(el => {
                if(el.flatId === topic.nodeId ){
                    CommonHelper.contentCom(topic,el);
                }
              });
            }
          }
    }),

    /** Deprecated */

    contentCom:((topic,tracked)=>{
        let scount = 1
        topic.lrncontent && topic.lrncontent.forEach((com)=>{
            if(com.props.track ){
                scount = com.props.track.status === 1 ? scount+1:scount;
                console.debug("count === statuscount",topic.lrncontent.length, scount)
                if(topic.lrncontent.length === scount){
                    tracked.percent = 100
                    tracked.status = 1
                    topic.status = 1 
                    topic.p = 100
                }
            }
        })
    }),

 

      /** Deprecated */

    updateCompItem:((track,selected,arr,type,fn)=>{
        try{
            track.state = {...track.state,c:selected.length}
            track.status = (arr.length === selected.length) ? 1:0;
            fn({id:track?.id,type:type,state:track.state});
            console.debug("Track ===",track,selected,arr,type)
        }catch(err){
            console.log(err);
        }
    }),

    



}

export default CommonHelper;