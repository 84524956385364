import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import DataHelper from '../../../utils/DataHelper';
import './dragandmatch.scss';

/**
 * 
 * @param {*} bucket 
 * @returns sequence list of string bucket
 */
/**
 * 
 * @param {*} drags 
 * @returns object contains array of drags in each object items
 */

/**
 * 
 * @param {*} description 
 * @returns  string it will contain the description
 */

/**
 * Dragandmatch component is used for items to drag and put it one box items 
 * Mainly used for some assessment component 
*/

const DragandMatch = (props) => {
    const { description, explanation,styleObj, bucket, drags, track, handler, instructions } = props;
    // status declarations
    const [status, setStatus] = useState({
        completed: false,
        text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE
    });
    const [instruction, setInstruct] = useState(instructions);
    const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
    const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

    const [dragCount, setDragCount] = useState([]);
    const [container, setContainer] = useState(bucket || []);
    const [item, setItems] = useState(DataHelper?.shuffle(drags) || []);
    const [successfulDrops, setSuccessfulDrops] = useState(0);

   // Reset when relevant props change
    const reset = () => {
        try {
            setContainer(bucket);
            setItems([...DataHelper?.shuffle(drags)]);
            setDragCount([]);
            setSuccessfulDrops(0);
            container.forEach(e => {
                e?.container?.forEach((drags) => drags.containerElem.style.display = 'block')
                e.container = [];
            });
        } catch (e) { }
    }

    useEffect(() => {
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bucket, drags, description, props])



    /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
    const updateProgress = (dropedItems) => {

        /**
         * View status, will update while navigating to next topic
         */
        track.state = { ...track.state, c: dropedItems }
        /**
         * Progress updated as and when its completed
         */

        if (!track.status) {

            track.status = 1
            setStatus({ completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE })
            if (track.status) {
                handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: props.cename })
            }
        }
        /**
         * If anything to be intimated always
         * call this
         */
        handler({ type: UPDATE_STATUS, id: track.id, name: props.cename })

    }

    useEffect(() => {
        if (successfulDrops === item?.length) {
            updateProgress(successfulDrops);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successfulDrops, item?.length])


    const onContainer = (dragEl, index) => {
        try {
            const isCheck = container[index]?.container?.some(el => el?.id === dragEl.dragData?.id);
            const isRight = String(dragEl?.dragData?.target) === String(container[index]?.id);

            if ((isRight && !isCheck)) { // adding the item's in side the box
                const newContinuer = [...container];
                newContinuer[index] = {
                    ...newContinuer[index],
                    container: [...newContinuer[index]?.container, dragEl]
                };

                setContainer(newContinuer);
                console.log("  ", dragEl);
                dragEl.containerElem.style.display = 'none';
                setDragCount(dragCount);

                if (isRight) { // score validating 
                    setSuccessfulDrops(prevDrops => prevDrops + 1);
                }
            }
        } catch (e) { }
    }


    return <>
        <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text} />
        <div className="drag-and-match-lrn">
            <div className="heading-result">
                {/*  after complete feedback */}
                <div className='dragging-result'>{successfulDrops === item?.length && (
                    <div dangerouslySetInnerHTML={{ __html: explanation?.right }} />
                )
                }</div>
                <div className='dragging-title' dangerouslySetInnerHTML={{ __html: description || '' }} />
            </div>
            <div className="row inner-container">
                {container?.length > 0 && container?.map((con, index) => {
                    return <div className="col-md-12 col-lg-12 drag-area" key={index}>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4  drag-question-box">
                                <div className="drag-question">
                                    <div className={`${con?.container?.length > 0 && "visible"} drag-result`}>
                                        {/* eslint-disable-next-line jsx-a11y/alt-text  */}
                                        <img src="https://resources.contentenablers.com/platform/intr_v2/317/img/GreenTick.svg" />
                                    </div>
                                    <div className='drop-box' dangerouslySetInnerHTML={{ __html: con?.text || '' }} />
                                </div>
                            </div>
                            <div className=" col-sm-12 col-md-4 col-lg-4 drop-container">
                                <DropTarget
                                    key={con.id}
                                    targetKey={String(con?.id || -1)}
                                    onHit={(e) => onContainer(e, index)}
                                >
                                    <div className="drop-items-container">
                                        <div className={`dropped-items`}>
                                            {con?.container?.map((dragData, eIndex) => {
                                                let isRight = dragData?.dragData?.target === con?.id;
                                                return (
                                                    <div className={`${(isRight || successfulDrops !== item?.length) ? 'right-ans' : 'wrong-ans'}`}>
                                                        <div dangerouslySetInnerHTML={{ __html: dragData?.dragData?.text || '' }} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </DropTarget>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 dop-item">
                                <DragDropContainer
                                    key={con.id}
                                    targetKey={String(item[index || 0]?.target || -1)}
                                    dragData={item[index || 0]}
                                >
                                    <div className='drag-item-container-text-box' style={{backgroundColor: styleObj?.dr_bg_color || "#f8f9fa"}}>
                                        <div dangerouslySetInnerHTML={{ __html: item[index || 0]?.text }} />
                                    </div>
                                </DragDropContainer>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    </>
}


DragandMatch.propTypes = {
    /** bucket will have number of drag objects  */
    bucket: PropTypes.array,
    /** drags items contains list of items for drag and drop */
    drags: PropTypes.array,
    /** string it will contain the description  */
    description: PropTypes.string,
    /** explanation it will contain the question  explanation */
    explanation: PropTypes.object,
    /** Tracking the component progress */
    track: PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler: PropTypes.func,
    /** styleObj it will contain styles */
    styleObj: PropTypes.object,
}

export default DragandMatch