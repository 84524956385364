import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import store from './redux/store';
import * as courseInfoAction from './redux/actions/courseInfoAction';
import * as courseTracking from './redux/actions/courseTrackingAction';
import * as userInfoAction from './redux/actions/userInfoAction';
import * as courseEditAction from './redux/actions/courseEditAction';
// import PlayerConstants from './utils/PlayerConstants';
import CExApi from './core/lrs/CExApi';
// import SCORMWrapper from './core/lrs/SCORMWrapper';
import BuildHelper from './utils/BuildHelper';
import DataHelper from './utils/DataHelper';
// import * as serviceWorker from './serviceWorker';

import App from './App';
import './index.css';

// Constants
const roleId = Number(window?.ce?.ceuser?.role_id || 4);
const isCEAuthor = roleId !== 4;
const theme = createMuiTheme({
  palette: {
    primary: { main: '#ffffff' },
    secondary: { main: '#4caf50' },
  },
  typography: { fontFamily: 'WorkSansSemiBold' },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 973,
      lg: 1366,
      xl: 1920,
    },
  },
});

// Function to handle requestProgress dispatch
const requestProgressIfLearner = async () => {
  if (roleId === 4) {
    await store.dispatch(courseInfoAction.requestProgress());
  }
};


requestProgressIfLearner();

// Helper Functions
const addPartnerClass = () => {
  const playerType = 'egadd'; // This should be dynamically set
  switch (playerType) {
    case "techuk": return "techuk";
    case "egadd": return "egadd";
    case "ioe": return "ioe";
    default: return 'ceplayer';
  }
};

// Initialize BuildHelper
BuildHelper.initialize({
  scorm: window?.ce?.isScormPack,
  roleId,
});

// Initialize CExApi and dispatch actions
const initializeCExApi = () => {
  if (!window?.ce) return;
  
  const cexapi = new CExApi(window.ce.ceuser, window.ce.cemodule, window.ce.platform_scorm, BuildHelper.isOnScorm());
  cexapi.initialise();
  
  store.dispatch(courseTracking.intialiseLrs(cexapi));
  store.dispatch(userInfoAction.storeUserInfo(window.ce));
  
  if (isCEAuthor) {
    store.dispatch(userInfoAction.userAclRequest(window.ce));
  }
  
  store.dispatch(courseEditAction.setEditState(roleId < 4));
  
  if (BuildHelper.isLearner()) {
    const supplimentaryRequestType = BuildHelper.isOnScorm() ? -1 : 0;
    store.dispatch(courseInfoAction.requestSupplimentaries(supplimentaryRequestType));
  }
};

initializeCExApi();

// Initialize SCORM if applicable
const initializeSCORM = () => {
  try {
    if (window?.ce?.isScormPack || window?.ce?.isStandalone) {
      DataHelper.initializeSCORM(userInfoAction, courseTracking, store.dispatch);
      let spdata = store.getState().courseTracking.scorm.getSuspendData();
      let pgrsdata = new Uint8Array(spdata?.split(','));
      let descormdata = store.getState().courseTracking.scorm.decryptProgress(pgrsdata)
      if(descormdata.lan) localStorage.setItem('defaultLn',JSON.stringify(descormdata?.lan))
    }
  } catch (error) {
    console.error("SCORM NOT FOUND:", error);
  }
};

initializeSCORM();

// // Initialize i18n if needed
const courseInfoIfNeeded = () => {
  if (!BuildHelper.isLearner()) {
    store.dispatch(courseInfoAction.requestCoursInfo(1));
  }
};

courseInfoIfNeeded();

// Clean up console logs in production
if (process?.env?.NODE_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.warn = () => {};
}

// Add partner class to root element
const rootEle = document.getElementById('root')
rootEle.classList.add(addPartnerClass())

// Render application
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  rootEle
);
// serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
