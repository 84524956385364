import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import UploaderPanel from "../UploaderPanel/UploaderPanel";
import Datahelper from "../../../utils/DataHelper";
import CustomSelect from "../../../components/ui/CustomSelect/CustomSelect";
import RadioSelect from "../../../components/ui/RadioSelect/RadioSelect";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
/**
 *
 * DocumentViewerPanel editor used to modify the propertis of DocumentViewer component
 */

const DocumentViewerPanel = ({
  header,
  description,
  enablePublicUrl,
  fileName,
  layout,
  thumbnail,
  textPosition,
  docPublicUrl,
  filePath,
  footer,
  styleObj,
  name,
  handleProperties,
}) => {
  const [data, setData] = useState({
    header,
    description,
    enablePublicUrl,
    layout,
    filePath,
    thumbnail,
    textPosition,
    docPublicUrl,
    fileName,
    name,
    footer,
    styleObj,
  });
  const [validUrl, setValidUrl] = useState(true)
  const [enableUrl, setEnableUrl] = useState(enablePublicUrl) 
  const timeoutRef = useRef(null);

const layoutOptions = [
    {
        value: 1,
        label: 'Column'
    },
    {
        value: 2,
        label: 'Row'
    },
    {
        value: 3,
        label: 'Embed'
    },
]

const textPositionOptions = {
    column: [
        {
            label: "Top",
            value: 2
        },
        {
            label: "Bottom",
            value: 1
        },
    ],
    row: [
        {
            label: "Left",
            value: 2
        },
        {
            label: "Right",
            value: 1
        },
    ]
}

//   console.log(data);
//   const handleChange = (e) => {
//     if (!header) {
//       delete data.header;
//     } else {
//       delete data.title;
//     }
//     setData({
//       ...data,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleColor = (e) => {
//     setData({
//       ...data,
//       styleObj: {
//         ...styleObj,
//         [e.target.name]: e.target.value,
//       },
//     });
//   };

const adjustHeight = (e) => {
  const textarea = e.target
  textarea.style.height = (textarea.scrollHeight + 10 > textarea.clientHeight) ? (textarea.scrollHeight)+"px" : "auto";
}

  const handleChangeInputTitleFooter = (e, type) => {
    setData({
      ...data,
      [type]: e.target.value,
    });
    // handleProperties(data)
  };

  const handleUrlChange = (e) => {
    adjustHeight(e)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      fetchdata(e.target.value)
    }, 500); 

  }

// const handleTogglePublicUrl = (e) => {
//   // console.log(e)
//   setEnableUrl(!enableUrl)
//   setData({
//     ...data,
//     enablePublicUrl: !enableUrl
//   })
// }
  
const handleTogglePublicUrl = (e) => {
  // Toggle enableUrl and update the data
  setEnableUrl((prevEnableUrl) => {
    const newEnableUrl = !prevEnableUrl;
    setData((data) => ({
      ...data,
      enablePublicUrl: docPublicUrl.length === 0 ? false : newEnableUrl
    }));
    return newEnableUrl;
  });
};

  const handleMedia = (mediaData, mediaType) => {
    // console.log("uploaded", mediaData, data);
    try {
      if (mediaData) {
        // const updatedData = { ...data, [mediaType]: mediaData.name};
        // console.log(updatedData)
        setData((data) => {
          return { ...data, [mediaType]: mediaData.name };
        });
        // handleProperties( {...data, [mediaType]: mediaData.name} )
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleChangeTextPosition = (event) => {
    // console.log(event.target.value)
    setData({ ...data, textPosition: Number(event.target.value) });
  };
  const handleChangeLayout = (option) => {
    if(Number(option?.value) === 3){
        setData({ ...data, textPosition: 1, layout: Number(option.value) });
        
    }
    else{
        setData({ ...data, layout: Number(option.value) });

    }
  };

  function endsWithOfficeDocExtension(url) {
    // Regular expression to match common office document extensions
    const officeExtensions = /\.(doc|docx|xls|xlsx|ppt|pptx)$/i;
    return officeExtensions.test(url);
  }

  function isValidURLDoc(url) {
    try {
      new URL(url);
      return endsWithOfficeDocExtension(url);
    } catch (e) {
      return false;
    }
  }


  useEffect(() => {
    handleProperties(data);
  }, [data]);
  // useEffect(() => {
  //   if(docPublicUrl.length === 0){
  //     setData({
  //       ...data,
  //       enablePublicUrl: false
  //     });
  //   }
  // }, []);

  const fetchdata = async (value) => {
    if( value.length === 0 ){
      setValidUrl(true)
      setData({
        ...data,
        docPublicUrl: value,
      });
      return
    }

    // validate and upadte url
    const updateDataUrl = (valid) => {
      setValidUrl(valid)
      
        setData({
          ...data,
          enablePublicUrl: valid,
          docPublicUrl: valid ? value : "",
        });
      
    }

    try{
      if( isValidURLDoc(value) ){
        const responce = await fetch(value)
        updateDataUrl(responce.ok)
      }
      else{
        updateDataUrl(false)
      } 
    }
    catch(err){
      console.log(err)
      updateDataUrl(false)
    }
  }

  /**
   *
   * @param {} value
   * data html string used for description of the component
   */
  const eventHandlers = (value) => {
    // console.log(value);
    // let contentValue = value === "<p></p>\n" ? "<p>Empty content</p>" : value;
    setData({
      ...data,
      description: value,
    });
    handleProperties(data);
  };
  return (
    <div className="doc-view-prop-container my-4">
       <div className="doc-view-prop-description x-align my-3">
        <label className="label dis_label">Title</label>
        <div className="docview-panel-text-holder">
          <textarea
            id="outlined-basic"
            className="doc-view-title w-100"
            spellCheck={false}
            name="title"
            defaultValue={header}
            rows="1"
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => handleChangeInputTitleFooter(e, "header")}
          />
        </div>
      </div>
      <div className="doc-view-prop-description x-align my-3">
      <FormControlLabel className='public-url-switch-btn'control={ <Switch checked={ enableUrl } onChange={(e)=> handleTogglePublicUrl(e) } /> } label={ 'Enable Public URL' }/>
        {enableUrl && <>
        <label className="label dis_label">Document Public Url</label>
        <div className="docview-panel-text-holder">
          <textarea
            id="outlined-basic"
            className="doc-view-title w-100"
            spellCheck={false}
            name="title"
            defaultValue={docPublicUrl}
            rows="1"
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => handleUrlChange(e)}
          />
        </div>
        {!validUrl && <p style={{color: "red", fontWeight:600}}>Please enter a valid document URL</p>}
        </>
        }
      </div>
      {/* <div className='topic-head-prop-title'>
            <TextField id="outlined-basic" spellCheck={false} name={header ? 'header': 'title'} label="Content title" variant="outlined" value={ header ? header: title} onChange={(e)=>{ handleChange(e) }}/>
        </div> */}

      <div className="doc-view-prop-description row x-align my-2">
        <div className="col-12 col-md-6 col-lg-4">
          <label className="label dis_label">Upload file</label>
          <UploaderPanel
            accept={".pptx, .ppt, .docx, .doc, "}
            fileName={fileName}
            name={`${Datahelper.getTime()}`}
            setUploadedData={(mediaData) => {
              handleMedia(mediaData, "fileName");
            }}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <label className="label dis_label">Upload Thumbnail</label>
          <UploaderPanel
            accept={"image"}
            fileName={thumbnail}
            name={`${Datahelper.getTime()}_img`}
            setUploadedData={(mediaData) => {
              handleMedia(mediaData, "thumbnail");
            }}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <CustomSelect label={"Layout"} className="w-100" value={ layout || 1 } options={layoutOptions} onChange={handleChangeLayout} />
        </div>
      </div>
      <div className="doc-view-prop-description x-align my-3 d-flex gap-4 p-2 flex-wrap txtpos-layout">
        {/* <div className="layout-select">
          <CustomSelect label={"Layout"} value={ layout || 1 } options={layoutOptions} onChange={handleChangeLayout} />
        </div> */}
        <div className="txtpos-radio">
          <div className="docview-txt-position-radio-label">Text Position</div>
          <RadioSelect selectedValue={ textPosition || 1 } name="text-position" options={layout===1? textPositionOptions['row']: textPositionOptions['column']} onChange={handleChangeTextPosition} color="#000"/>
        </div>
      </div>
      <div className="doc-view-prop-description x-align my-3">
        <label className="label dis_label">Description</label>
        <RichTextEditor
          data={data?.description || ""}
          eventHandler={eventHandlers}
        />
      </div>
    </div>
  );
};

DocumentViewerPanel.propTypes = {
  /** Heading title of the component */
  header: PropTypes.string,
  /** title content */
  title: PropTypes.string,
  /** related extended content of the component  */
  description: PropTypes.string,
  /** function handler used to save the edited props */
  handleProperties: PropTypes.func,
};

export default DocumentViewerPanel;
