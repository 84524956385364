import * as types from '../reducers/types'


export const applyTemplateToast = (payload) => {
    return {
        type: types.APPLY_TEMPLATE_TOAST,
        payload
    }
}


export const addLearningAidToast = (payload) => {
    return {
        type: types.ADD_LEARNINGAID_TOAST,
        payload
    }
}


export const removeLearningAidToast = (payload) => {
    return {
        type: types.REMOVE_LEARNINGAID_TOAST,
        payload
    }
}

export const savedAsTemplateToast = (payload) => {
    return {
        type: types.SAVED_AS_TEMPLATE_TOAST,
        payload
    }
}

export const learningAidClonedToast = (payload) => {
    return {
        type: types.LEARNING_AID_CLONED_TOAST,
        payload
    }
}

export const topicUpdatedToast = (payload) => {
    return {
        type: types.TOPIC_UPDATED_TOAST,
        payload
    }
}

export const globalStyleUpdatedToast = (payload) => {
    return {
        type: types.GLOBAL_STYLE_UPDATED_TOAST,
        payload
    }
}

export const authFault = (payload) => {
    return {
        type:types.AUTH_GOES_WRONG,
        payload
    }
}

export const socketWarning = (payload) => {
    return {
        type:types.SOCKET_WARNING,
        payload
    }
}