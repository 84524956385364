import React, { useState, useEffect } from 'react';
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import ContentTracking from '../../../core/progress/ContentTracking';
import PlayerConstants from '../../../utils/PlayerConstants';
import Instruction from '../../ui/Instruction/Instruction';
import DataHelper from '../../../utils/DataHelper';
import PropTypes from 'prop-types';
import './reorderinglist.scss';



/**
 * 
 * @param {*} list 
 * @returns it will contain oder list response text
 */


/**
 * 
 * @param {*} answer 
 * @returns it will contain oder list answer
 */

/**
 * 
 * @param {*} description 
 * @returns  string it will contain the description
 */

/**
 * Reordering component allows users to rearrange items by dragging and dropping them into designated boxes. 
 * It's commonly used in assessment scenarios where users need to organize items in a specific order to complete a task effectively
*/
const ReorderingList = (props) => {
  const { description, explanation,styleObj, answer, list, track, handler, instructions } = props;

  const [orderedItems, setOrderedItems] = useState(DataHelper.shuffle(list) || []);
  const [isCompleted, setIsCompleted] = useState(false);

  // status declarations
  const [status, setStatus] = useState({
    completed: false,
    text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE
  });
  const [instruction, setInstruct] = useState(instructions);
  const UPDATE_STATUS = PlayerConstants.COMPONENT_CONSTANTS.UPDATE_STATUS
  const SAVE_PROGRESS_STATUS = PlayerConstants.COMPONENT_CONSTANTS.SAVE_PROGRESS_STATUS

  useEffect(() => {
    setOrderedItems(DataHelper.shuffle(list));
  }, [props])


  /**
     * Manage the component update 
     * progress logic in this method
     * 
     * Update the view status when ever the user interacts
     * Update the progess status ONLY ONCE, when status completes
     * 
     */
  const updateProgress = () => {

    /**
     * View status, will update while navigating to next topic
     */
    track.state = { ...track.state }
    /**
     * Progress updated as and when its completed
     */

    if (!track.status) {

      track.status = 1
      setStatus({ completed: true, text: PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE })
      if (track.status) {
        handler({ type: SAVE_PROGRESS_STATUS, id: track.id, name: props.cename })
      }
    }
    /**
     * If anything to be intimated always
     * call this
     */
    handler({ type: UPDATE_STATUS, id: track.id, name: props.cename })

  }

  const handleDrop = (e, targetIndex) => {
    const draggedItem = e.dragData;
    const updatedItems = [...orderedItems];
    const removedIndex = updatedItems.findIndex(item => item.id === draggedItem.id);
    [updatedItems[removedIndex], updatedItems[targetIndex]] = [updatedItems[targetIndex], updatedItems[removedIndex]];
    setOrderedItems(updatedItems);
    if (String(JSON.stringify(answer)) === String(JSON.stringify(updatedItems?.map(e => e?.id) || [])) && !isCompleted) {
      setIsCompleted(true);
      updateProgress();

    }
  };


  return <>
    <Instruction isInstruction={instruction?.enabled} completed={(track.status === 1 || status.completed)} title={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.STATUS_COMPLETE : PlayerConstants.COMPONENT_CONSTANTS.STATUS_INCOMPLETE} classText={`${(track.status === 1 || status.completed) && PlayerConstants.COMPONENT_CONSTANTS.COMPLETED_CLASS}`} text={(track.status === 1 || status.completed) ? PlayerConstants.COMPONENT_CONSTANTS.INSTRUCTIONS_PASSED : instruction?.text} />
    <div className="reorder-list">
      <div className="inner-container">
        <div className="heading-result">
          {/*  after complete feedback */}
          <div className='dragging-result'>{isCompleted && (<div dangerouslySetInnerHTML={{ __html: explanation?.right }} />)}</div>
          <div className='dragging-title' dangerouslySetInnerHTML={{ __html: description || '' }} />
        </div>
        {orderedItems.map((item, index) => (
          <div className="ordering" key={index}>
            <DragDropContainer
              key={index}
              dragData={item}
            >
              <DropTarget
                onHit={(e) => handleDrop(e, index)}
                dropData={item}
                key={item?.id}
              >
                <div className="drag-item" style={{backgroundColor: styleObj?.dr_bg_color || "#f8f9fa"}}>
                  <div className={`drag-result ${answer[index] === item?.id && "visible"}`}>
                    <img src="https://resources.contentenablers.com/platform/intr_v2/317/img/GreenTick.svg" />
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: item?.text || '' }} />
                </div>
              </DropTarget>
            </DragDropContainer>
          </div>
        ))}
      </div>
    </div>
    </>
};

ReorderingList.propTypes = {
    /** choice contains oder contents*/
    list: PropTypes.array,
    /** array contains answer*/
    answer: PropTypes.array,
    /** string it will contain the description  */
    description: PropTypes.string,
    /** explanation it will contain the question  explanation */
    explanation: PropTypes.object,
    /** Tracking the component progress */
    track: PropTypes.instanceOf(ContentTracking),
    /** Func description for tracking*/
    handler: PropTypes.func,
    /** styleObj it will contain styles */
    styleObj: PropTypes.object,
}


export default ReorderingList