import BuildHelper from "../../utils/BuildHelper";

class CourseTracking {
    
   constructor()    {
       this.topic = [];
       this.visited = {};
       this.scorm = {};
       this.completed_mail = '0';
       this.lan = BuildHelper?.defaultLanguage()||null;
       this.lock_course=false
   }
   
}

export default CourseTracking;