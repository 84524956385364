import * as types from '../reducers/types'
import BuildHelper from '../../utils/BuildHelper';

const initialState = {
    isLoading:false,
    audios: [],
    videos: [],
    images: [],
    gif:[],
    wishlist: [],
    allCeAssets: [],
    archiveCourses: [],
    archiveSearchCrs: [],
    selectedArchiveCrs:[],
    quizQuestions: [],
}

const ceAssetsInfoReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CE_ASSETS_LIBARARY_RES:
            // let allAssets = action?.payload?.rows?.reverse();
            let allAssets = action?.payload?.rows.sort((a, b) => new Date(b.createdAt || b.updatedAt) - new Date(a.createdAt || a.updatedAt));

            let allImage= [];let allGif= [];let allVideos= []; let allAudios= []; let allCeAssets = [];
            allAssets.forEach(item => {
                if(item.status !== -1){
                    if (item.rtype === 1) allImage?.push(item); 
                    if (item.rtype === 2) allVideos?.push(item); 
                    if (item.rtype === 3) allAudios?.push(item); 
                    if (item.rtype === 5) allGif?.push(item); 
                    allCeAssets[item.id] = item;
                }
            });
            return {
                ...state,
                isLoading:false,
                audios: allAudios,
                images: allImage,
                videos: allVideos,
                gif:allGif,
                allCeAssets: allCeAssets
            }
        case types.CE_ASSETS_WISHLISTS_RES:
            return {
                ...state,
                isLoading:false,
                wishlist: action?.payload?.data
            }
        case types.CE_ASSETS_WISHLISTS_REQ:
        case types.CE_ASSETS_LIBARARY_REQ:
        case types.GET_ARCHIVE_SEARCH_REQ:
            return {
                ...state,
                isLoading:true
            }
        case types.QUIZ_QUESTIONS_RES:
            const quiz=action?.payload;
            let updatedQuiz=state?.quizQuestions;
            if(updatedQuiz.findIndex((e)=>e?._id===quiz?._id) !== -1){
                updatedQuiz= updatedQuiz.map((el)=>{
                    return el?._id === quiz?._id ? {...quiz} : el;
                });
            }else updatedQuiz.push(quiz);
            return{
                ...state,
                quizQuestions:updatedQuiz
            }
            
        case types.CE_ASSETS_LINKED_RES:
            const updateItem = action?.payload;
            let type = action?.payload?.rtype
            let updateImages = state?.images, updateVideos = state?.videos, upadateAudios = state?.audios;
            let index
            if (type === 1) {
                index = updateImages?.findIndex((item) => item?.id === updateItem?.id);
                updateImages[index] = updateItem;
            } else if (type === 2) {
                index = updateVideos?.findIndex((item) => item?.id === updateItem?.id);
                updateVideos[index] = updateItem;
            } else if (type === 3) {
                index = upadateAudios?.findIndex((item) => item?.id === updateItem?.id);
                upadateAudios[index] = updateItem;
            }
            return {
                ...state,
                isLoading:false,
                audios: upadateAudios,
                images: updateImages,
                videos: updateVideos
            }

        case types.GET_ARCHIVE_COURSES_RES:
            const contentRowData = action?.payload;
            const flatten = [];
            contentRowData?.forEach((item) => {
                flatten[item?.info?.config?.cid] = {
                    ...item,
                    id: item?.info?.config?.cid,
                    label: item?.coursename || 'next'
                };
            });
            return {
                ...state,
                isLoading:false,
                archiveCourses: flatten
            }


        case types.GET_QUIZ_QUESTIONS_RESPONSE:
            const key= BuildHelper.isConfig() ? "cnf_q" : "crs_q";
            const quizzes=action?.payload?.data[key] || [];
            return {
                ...state,
                isLoading:false,
                quizQuestions: quizzes
            }
        case types.GET_QUIZ_ADD_QUESTIONS_RESPONSE:
                return {
                    ...state,
                    quizQuestions: action?.payload?.data
                }
        case types.GET_QUIZ_UPDATE_QUESTIONS_RESPONSE:
                    return {
                        ...state,
                        quizQuestions: action?.payload?.data
                    }
         case types.SET_SEARCH_BY_CID_RES:
            let payloadData=action?.payload?.data || [];
            let shortedCrsList =[];
            let arcCrouse=state?.archiveCourses || [];
            try{
                 shortedCrsList = payloadData.sort(function (a, b) {
                    const textA = a.title.toUpperCase();
                    const textB = b.title.toUpperCase();
                    // eslint-disable-next-line no-nested-ternary
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                  });
                  shortedCrsList = shortedCrsList?.filter(
                    (item) => item?.filepath !== '0' && item.cid !== 0
                  );
                }catch(e){}
                arcCrouse[action?.payload?.id||-1]={...arcCrouse[action?.payload?.id||-1],selectedList:shortedCrsList};
                    return {
                        ...state,
                        selectedArchiveCrs: shortedCrsList,
                        archiveCourses:arcCrouse
                    }
        case types.GET_ARCHIVE_SEARCH_RES:
                let payload=action?.payload?.data || [];
                let shortedList =[]
                try{
                       shortedList = payload.sort(function (a, b) {
                        const textA = a.title.toUpperCase();
                        const textB = b.title.toUpperCase();
                        // eslint-disable-next-line no-nested-ternary
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                      });
                      shortedList = shortedList?.filter(
                        (item) => item?.filepath !== '0' && item.cid !== 0
                      );
                    }catch(e){}
                    return {
                        ...state,
                        isLoading:false,
                        archiveSearchCrs:shortedList 
                    }


        default:
            return state;
    }
}


export default ceAssetsInfoReducer