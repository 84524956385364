// icons.js
import {
    Delete,
    Edit,
    Add,
    Save,
    Search,
    PhoneAndroid,
    TabletMac,
    Laptop,
    Home,
    Menu,
    DesktopMac,
    PowerSettingsNew,
    Close,
    AccountCircle,
    ArrowDropUp,
    ArrowDropDown,
    CloudUpload,
    LinkedIn,
    GTranslate,
    DragIndicator,
    AspectRatio,
    Info,
    AppRegistration,
    QueryStats,
    Compare,
    BugReport,
    Numbers,
    Translate,
    RestartAlt,
    VisibilityOff,
    RemoveRedEye,
    BorderColor,
    Star,
    FilterAlt
  } from '@mui/icons-material';
  const Icons = {
    delete: Delete,
    edit: Edit,
    add: Add,
    save: Save,
    search: Search,
    phone: PhoneAndroid,
    tablet: TabletMac,
    laptop: Laptop,
    home: Home,
    menu: Menu,
    desktop: DesktopMac,
    power_settings: PowerSettingsNew,
    close: Close,
    account_circle: AccountCircle,
    arrow_drop: ArrowDropUp,
    down_arrow: ArrowDropDown,
    cloud_upload: CloudUpload,
    linkedin:LinkedIn,
    translate:Translate,
    drag:DragIndicator,
    resize:AspectRatio,
    info:Info,
    registration:AppRegistration,
    status:QueryStats,
    compare:Compare,
    bug:BugReport,
    number:Numbers,
    re_start:RestartAlt,
    eye_hide:VisibilityOff,
    eye_show:RemoveRedEye,
    pen:BorderColor,
    star:Star,
    filter:FilterAlt
    // Add other icons as needed
  };
  
  export default Icons;
  