import XAPIHelper from "./XAPIHelper";
import PlayerConstants from '../../utils/PlayerConstants';

class CExApi {
    constructor(user, course, platform, isScorm) {
        this.wrapper = null;
        this.statementQueue = [];
        this.stateId = 0
        this.userInfo = user;
        this.course = course
        this.platform = platform;
		this.isScorm = isScorm;
        this.stateId = this.userInfo?.uid + '_' + this.course.course_Id;
        this.helper = new XAPIHelper(user,course);

		 
    }

	// endpoint: "https://tradecompliancecourses.com/ceauthor/api/",
	// endpoint: "https://tradecompliance.training/xapice/api/",
    initialise = () => { 
        var ref = this;
        try {
            const ADL = window.ADL;
            ADL.launch(function (err, launchdata, xAPIWrapper) {
                console.log("------------->>",err)
                if (!err) {
                    this.wrapper = ADL.XAPIWrapper = xAPIWrapper;
                    console.log("--- content launched via xAPI Launch ---\n", this.wrapper.lrs, "\n", launchdata);
                } else {
                    ref.wrapper = ADL.XAPIWrapper;
                    ref.wrapper.changeConfig({
                        endpoint: PlayerConstants.STG_HOSTNAMES.indexOf(window.location.hostname) !== -1 ? "https://tradecompliance.training/xapice/api/" : "https://beta-cached.contentenablers.com/api/",
                        user: '',
                        password: ''
                    });
                    ref.wrapper.updateUserInfo(Object.assign({ auth: ref.platform.authtoken, cid: ref.platform.cid }, ref.userInfo))
                    console.log(ref.wrapper,"--- content statically configured ---\n");
                }
            }, true);

        } catch (error) {

        }
    }

    trackActivity = (type, isConnect, params) =>{
        let stmt ={};
        // deep cloning method
		stmt =JSON.parse(JSON.stringify(this.helper.getStatement(type, params)));
		const isDescriptionPresent = this.statementQueue?.some((interaction) => {
			return (interaction?.object?.definition?.description["en-US"]||"") === (stmt?.object?.definition?.description["en-US"] || "");
		  });

		if(isConnect === 1)	{
			//this.saveLearning(stmt)
		}
		else if(isConnect === -1 && !this.isScorm && !isDescriptionPresent)	{
		    this.statementQueue=[...this.statementQueue,{...stmt}];
			this.saveLearning(this.statementQueue)
		}
		else if(isConnect === 0)	{
			if(!isDescriptionPresent) this.statementQueue=[...this.statementQueue,{...stmt}];
		}

		console.log(type, "---------------" , this.statementQueue)
	}

	saveLearning = (stmt) =>{
		var ref = this;
		if(stmt.length)	{
			stmt.map(item =>{
				delete item.id;
				return item;
			})
			this.wrapper.sendStatements(stmt, (res, data) =>{
				ref.stateId = data.id;
				ref.statementQueue = []
				console.log(data)
			});
		}
		else	{
			this.wrapper.sendStatement(stmt, (res, data) =>{
				ref.stateId = data.id;
				console.log(data.id)
			});
		}
	}


	saveAppState = ( stateVal) =>{
		let bstate = this.helper.getBaseState();
		let stateId = this.helper.getStateId()
		this.wrapper.sendState(bstate.activityId,bstate.agent, stateId, null,{a:Math.random(), ...stateVal},null, null,(data)=>{
			console.log("SAVED DATA TO LRS ")
		})
	}

	getAppState = () =>	{
		console.log('getAppState ^^^^^^^^^^^^^^^^^^^^^^^')
		let bstate = this.helper.getBaseState();
		let stateId = this.helper.getStateId()
		console.log('getAppState',stateId,bstate)

		var state = this.wrapper.getState(bstate.activityId,bstate.agent, stateId, );
		console.log('getAppState')
		console.log(state)
		return state;
	}


}

export default CExApi;
