import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './savetemplate.scss'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import { Button } from '@material-ui/core';
import CustomCheckbox from '../../../components/ui/CustomCheckbox/CustomCheckbox';
import PlayerConstants from '../../../utils/PlayerConstants';
import DataHelper from '../../../utils/DataHelper';

const SaveTemplate = () => {

    const dispatch = useDispatch();
    const {courseInfo, userInfo} = useSelector( state => state );
    const [ tpName, setTemplateName ] = useState( '' )
    const [ template, setTemplate ] = useState( JSON.parse(JSON.stringify( courseInfo?.topic)))
    const [ compIdList, setCompIdList ] = useState([])
    const [ odoredList, setOdoredList ] = useState(courseInfo?.topic?.lrncontent)
    const handleChangeInput = ( e ) => {
        setTemplateName( e.target.value );
    }
    useEffect(()=>{
        setTemplate(JSON.parse(JSON.stringify(courseInfo?.topic)));
        setCompIdList(extractCompIds(courseInfo?.topic?.markup));
        if(compIdList?.length > 1){
            setOdoredList(cleanlrnList(courseInfo?.topic?.lrncontent, compIdList));
        }
    },[])

    const saveAsTemplate = () => {
        try {
            const shortedList=cleanlrnList(template?.lrncontent, compIdList)
            let data = {
                cmpdata:shortedList,
                markup:createSection(shortedList),
                name: tpName,
                type: 1, // - Default Template type
                status: 1
            }

           if(!DataHelper?.isCourse()){
             data.info={clientid:window?.ce?.ceuser?.pvtid,configid:window?.ce?.rlm?.config_id}
             data.status=2
           }
            // console.debug("data",data)
            dispatch( courseEditAction.saveAsTemplate( data ))

            closeDrawer()

        } catch ( e ) {
            console.log( "e", e )
        }
    }

    const closeDrawer = () =>{
        dispatch(courseEditAction.toggleDrawer({open:false, drawerWidth: 0}))
    }

    const createSection = (updatedTemplate) => {
        try {
            let markup=''
            updatedTemplate?.forEach(item => {
                if (item && typeof item?.name === 'string' && item?.compid) {
                    // Create section markup
                    markup += `<section name="${item?.name}" compid="com_${item.compid}"></section>`;
                } else {
                    console.warn('skipping invalid item:', item);
                }
            });
            return markup
        } catch (error) {
            console.error('error creating sections:', error.message);
            return courseInfo?.topic?.markup; // return the original template in case of error
        }
    }

    const handleSelection = (e, item) => {
        try {
            const hasChecked = e?.target?.checked
            const id = item?._id;
            let updatedTemplate = { ...template }
            if (hasChecked) {
                if (!updatedTemplate?.lrncontent?.some(e => e?._id === id)) {
                    updatedTemplate?.lrncontent?.push(item);
                }
            } else {
                updatedTemplate.lrncontent = updatedTemplate?.lrncontent?.filter(lrn => lrn?._id !== id);
            }
            setTemplate({ ...updatedTemplate })
        } catch (e) {
             
        }
    }

    const handleSelectAll=()=>{
        if(template?.lrncontent?.length === courseInfo?.topic?.lrncontent?.length){
            setTemplate({ ...template, lrncontent:[], markup:''  })
            return;
        }
        setTemplate({ ...courseInfo?.topic  })
    }

    const resetTpName = () => {
        setTemplateName( '' )
    }


    return (
        <div className='save-template px-4'>
            <TextField id="outlined-basic"
                name='name'
                inputProps={ { maxLength: 30 } }
                label="Template name"
                variant="outlined" defaultValue={ tpName }
                onClick={ ( e ) => e.stopPropagation() }
                onChange={ ( e ) => handleChangeInput( e ) } />
                <div className='template-list'>
                  <p className='template-title m-0 pt-3 pb-1'>Learning Elements List</p>
                  <ul className='mt-2 p-0'>
                      <li className='lrn d-flex justify-content-between align-item-center m-0'>
                      <CustomCheckbox
                        label={'Select all'}
                        checked={(template?.lrncontent?.length === courseInfo?.topic?.lrncontent?.length)}
                        name="rand_ques"
                        onChange={handleSelectAll}
                      />
                    </li>
                    {odoredList?.map((item, index) => {
                     const hasHide = template?.lrncontent?.some(e => e?._id === item?._id);
                    return (<li key={index} onClick={()=>DataHelper.scrollToLrnElement(item)}  className={`lrn d-flex align-items-center justify-content-between m-0 lrn-${hasHide ? 'show' : 'hide'}`}>
                        <p className='m-0  d-flex align-items-center'>
                            <span className='comp-list-icon'><img src={require("../../../assets/img/" +  (PlayerConstants?.COMP_ICONS?.[item?.name] || 'topic_header.png') ).default} alt='' /></span>
                            {index+1}. {PlayerConstants?.LRN_RE_NAME[item?.name] || item?.name}</p>
                        <CustomCheckbox
                        label=''
                        checked={hasHide}
                        name={`${index+1}. ${PlayerConstants?.LRN_RE_NAME[item?.name] || item?.name}`}
                        onChange={(e)=>handleSelection(e,item)}
                        />
                    </li>
                     );
                    })}
                </ul>
                </div>
            <div className='tp-action-info'>
                <Button className='cancel-btn' onClick={ () => resetTpName() }>Cancel</Button>
                <Button className={ (tpName === '' || template?.lrncontent?.length < 1) ? 'diabled-btn' : 'submit-btn' } disabled={(tpName === '' || template?.lrncontent?.length < 1) } onClick={ () => saveAsTemplate() }>Save </Button>
            </div>
        </div>
    )
}

/**
 * Extracts compId values from a string Markup.
 * 
 * The string containing Markup.
 * @returns {string[]} An array of extracted compId values.
 */
export const extractCompIds = (sectionsMarkup) => {
    try {
        if (typeof sectionsMarkup !== 'string') {
            throw new Error('Invalid input: sectionsMarkup must be a string');
        }

        // Split the string by the starting pattern of compId
        const parts = sectionsMarkup?.split("compId='com_");

        // Extract compId values from the parts
        const compIds = parts
            ?.slice(1) // Skip the first part, which does not contain compId
            ?.map(part => part?.split("'")[0]) // Extract the numeric part
            ?.filter(compId => !isNaN(compId) && compId.trim() !== ''); // Filter out non-numeric values

        return compIds;
    } catch (e) {
        console.error('Error extracting compIds:', e?.message);
        return []; 
    }
};

/**
* cleans and sorts the list of items based on a markup oder array of compIds.
* 
* @param {Object[]} list 
* @param {string[]} order - the array of markup compId
* @returns {Object[]} the cleaned and sorted list
*
*
* Author and logs props are not required 
* Removing the author and logs propety from lrn content
*
*/
export const cleanlrnList = (list, order) => {
 try{
    let cleanlrnList=[...list]
    // Author and logs props are not required 
    cleanlrnList = list.map(item => {
        let obj = { ...item };
        delete obj?.author;
        delete obj?.logs;
        obj.status=1
        return obj;
    })?.filter(item => item !== null);

    if(order?.length < 2 || !order){
        return cleanlrnList
    }

    // sort the cleaned data based on the provided order
    cleanlrnList = cleanlrnList?.sort((a, b) => {
        const indexA = order?.indexOf(String(a?.compid));
        const indexB = order?.indexOf(String(b?.compid));

        // handle cases where compId is not found in the order array
        if (indexA === -1 && indexB === -1) return 0; // Both not found, keep original order
        if (indexA === -1) return 1; //not found, at the end
        if (indexB === -1) return -1; //  not found, at the end

        return indexA - indexB;
    });

    return cleanlrnList;
 }catch(e){
   return list
 }
};

SaveTemplate.propTypes = {

}

export default SaveTemplate