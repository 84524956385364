import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import Acc from "@material-ui/core/Accordion";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import Typography from "@material-ui/core/Typography";
// import DeleteIcon from "@material-ui/icons/Delete";
import Datahelper from "../../../utils/DataHelper";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import PlayerConstants from "../../../utils/PlayerConstants";
import { AddCircle } from "@material-ui/icons";
import UploaderPanel from "../UploaderPanel/UploaderPanel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useToasts } from "react-toast-notifications";
import AccordionItem from '../../../components/ui/AccordionItem/AccordionItem'; // Assuming your AccordionItem component is in a separate file
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@material-ui/core';

const ImageHeight = [
  { name: "Small", value: 200 },
  { name: "Medium", value: 300 },
  { name: "Large", value: 450 },
  { name: "Extra Large", value: 600 },
];

const GridImages = [
  { name: "1", value: 1 },
  { name: "2", value: 2 },
  { name: "3", value: 3 },
  { name: "4", value: 4 },
  { name: "5", value: 5 },
];

const ImageGalleryPanel = ({
  items,
  rowimg,
  handleProperties,
  height,
  cename,
  id,
  // handleCeAssets - This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ). 
  handleCeAssets,
  ...rest
}) => {
  const [data, setData] = useState({ items, rowimg, height });
  const [expanded, setExpanded] = useState(0);
  const [drag, setDrag] = useState(false);

  const { addToast } = useToasts();
  let maxCount = 0;
  const compItems = Object.values(PlayerConstants.COMPONENTS_LIST).filter(
    (el) => el.name === cename
  );
  maxCount = compItems[0].max_count;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeGrid = (e) => {
    setData({ ...data, rowimg: e?.target?.value });
    handleProperties({ ...data, rowimg: e?.target?.value });
  };

  const addAccordion = () => {
    if (data?.items?.length < maxCount) {
      const newobj = {
        id: data?.items.length + 1,
        img: "*",
        desc: `captions ${data?.items?.length + 1}`,
      };
      data?.items?.push({ id: data?.items?.length + 1, ...newobj });
      setData(data);
      handleProperties({ ...data });
    }
  };

  /**
   *
   * @param {} value
   * data html string used for description of the component
   */
  const eventHandlers = (value, i) => {
    data.items[i]["desc"] = value;
    setData(data);
    handleProperties({ ...data });
  };

  /**
   *
   * @param {*} data
   * only file name sending here in player component its concat with default path
   */
  const handleMedia = (datas, index) => {
    try {
      if (datas) {
        const updatedData={...data};
        let pre=data.items[index]?.img;
        updatedData.items[index] = { ...updatedData?.items[index], img: datas.name };
        /** used to update the Assets info*/
        handleCeAssets(pre, datas?.name, updatedData);
        setData(updatedData);
        handleProperties({ ...updatedData });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = (event, index) => {
    if (data?.items?.length > 3) {
      const removed = data?.items?.filter((e, i) => i !== index);
      setData({ ...data, items: removed });
      handleProperties({ ...data, items: removed });
    } else {
      addToast("Three images are mandatory", { appearance: "warning" });
    }
    event.stopPropagation();
  };

  const imageStyleChange = (e, index) => {
    data.items[index]["styles"][e.target.name] = e?.target?.value || "";
    setData({ ...data });
    handleProperties({ ...data });
  };

  const handleDefaultHeight = (e) => {
    setData({ ...data, height: e?.target?.value });
    handleProperties({ ...data, height: e?.target?.value });
  };

  const handleUpdateAccList = (e, list) => {
    let updated={...data,items:[...list]}
    setData({...updated});
    handleProperties({...updated});
}

  return (
    <div className="carousels-panel-container">
      <div className={`carousel-global-property`}>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 mt-2 mb-2">
            <FormControl size="medium">
              <InputLabel id="demo-select-small">Image Size</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={Number(height) || 400}
                onChange={(e) => handleDefaultHeight(e)}
                className="dropdown"
                inputProps={{ "aria-label": "Without label" }}
                size="small"
                name="height"
              >
                {ImageHeight.map((item, idx) => (
                  <MenuItem
                    key={idx}
                    className="select-option-list"
                    value={item.value}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mt-2 mb-2">
            <FormControl size="medium">
              <InputLabel id="demo-select-grid">Image Grid</InputLabel>
              <Select
                labelId="demo-select-grid"
                id="demo-select-grid"
                value={rowimg || 3}
                onChange={(e) => handleChangeGrid(e)}
                className="dropdown"
                inputProps={{ "aria-label": "Without label" }}
                size="small"
                name="gridItems"
              >
                {GridImages.map((item, idx) => (
                  <MenuItem
                    key={idx}
                    className="select-option-list"
                    value={item.value}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className={`card-prop-main-ui acccordion-item-list`}>
      <div className="acccordion-item-switch"><FormControlLabel label={'Allow reorder'} className="ck-check-box" labelPlacement="end" name="isCharacter" control={<Checkbox checked={drag} onChange={(e) => setDrag(!drag)} />} /> </div>
      <DndProvider backend={HTML5Backend}>
        {data?.items &&
          data?.items?.length > 0 &&
          data?.items?.map((el, index) => {
            return ( <AccordionItem
              className='inner-summary'
              key={index}
              name={`${index+1} Card`}
              index={index}
              el={el}
              data={data?.items}
              listUpdate={handleUpdateAccList}
              expanded={expanded}
              handleAccordionChange={handleChange}
              isDrag={drag}
              summary={{delete: { min: 1 }}}
              dynamicDetailsComponent={<>
               <div className="card-prop-file">
              <UploaderPanel
                accept={"image"}
                fileName={data?.items[index]?.img || ""}
                name={`${id}_${Datahelper.getTime()}-${index + 1}`}
                setUploadedData={(el) => handleMedia(el, index)}
                styles={{
                  position:
                    data?.items[index]["styles"]?.position ||
                    "center  / contain no-repeat",
                  color:
                    data?.items[index]["styles"]?.color ||
                    "rgba(0, 0, 0, 0)",
                }}
                bgChange={(e) => imageStyleChange(e, index)}
                pChange={(e) => imageStyleChange(e, index)}
              />
            </div>
            <div className="card-prop-description">
              <p className="description-text label">Content</p>
              <RichTextEditor
                data={el?.desc || ""}
                eventHandler={(e) => eventHandlers(e, index)}
              />
            </div> </>}
              />
 






              // <Acc
              //   key={index}
              //   expanded={expanded === index}
              //   onChange={handleChange(index)}
              // >
              //   <AccordionSummary
              //     aria-controls="panel1a-content"
              //     id="panel1a-header"
              //   >
              //     <Typography className={`label-bold`}>
              //       {`Card ${index + 1}`}{" "}
              //     </Typography>
              //     <DeleteIcon
              //       className="delete-prop-card"
              //       onClick={(e) => handleDelete(e, index)}
              //     />
              //   </AccordionSummary>
              //   <AccordionDetails>
              //     <div className="card-prop-file">
              //       <UploaderPanel
              //         accept={"image"}
              //         fileName={data?.items[index]?.img || ""}
              //         name={`${id}_${Datahelper.getTime()}-${index + 1}`}
              //         setUploadedData={(el) => handleMedia(el, index)}
              //         styles={{
              //           position:
              //             data?.items[index]["styles"]?.position ||
              //             "center  / contain no-repeat",
              //           color:
              //             data?.items[index]["styles"]?.color ||
              //             "rgba(0, 0, 0, 0)",
              //         }}
              //         bgChange={(e) => imageStyleChange(e, index)}
              //         pChange={(e) => imageStyleChange(e, index)}
              //       />
              //     </div>
              //     <div className="card-prop-description">
              //       <p className="description-text label">Content</p>
              //       <RichTextEditor
              //         data={el?.desc || ""}
              //         eventHandler={(e) => eventHandlers(e, index)}
              //       />
              //     </div>
              //   </AccordionDetails>
              // </Acc>
            );
          })}
          </DndProvider>
        <div
          className="add-new-slide"
          title={"Add Card"}
          onClick={addAccordion}
        >
          <AddCircle className="add-card" /> Add New Card
        </div>
      </div>
    </div>
  );
};
ImageGalleryPanel.propTypes = {
  /** items array used for having items of ImageGalleryPanel data */
  items: PropTypes.array,
  /** function handler used to save the edited props */
  handleProperties: PropTypes.func,
};

export default ImageGalleryPanel;
