import React, { useCallback } from 'react'
import PropTypes from 'prop-types'


const HoverSelector = ({ onElementChange, onMouseOver, onMouseLeave, disabled, ...rest }) => {
    
    const handleEnter = useCallback((event) => {

        onMouseOver === null || onMouseOver === void 0 ? void 0 : onMouseOver(event);
        var target = event.target;
        if (!target)
            return;
        onElementChange === null || onElementChange === void 0 ? void 0 : onElementChange(target);
        
    }, [onMouseOver, onElementChange]);
    
    const handleLeave = useCallback((event) => {
        onMouseLeave === null || onMouseLeave === void 0 ? void 0 : onMouseLeave(event);
        onElementChange === null || onElementChange === void 0 ? void 0 : onElementChange(null);
    }, [onMouseLeave, onElementChange]);


    return (
        <div  className='highlighter'
            {...rest}
            onMouseOver={disabled ? onMouseOver : handleEnter}
            onMouseLeave={disabled ? onMouseLeave : handleLeave}
        />
    )

}


HoverSelector.propTypes = {
    onElementChange: PropTypes.func,
    disabled: PropTypes.bool,
}


export default HoverSelector;