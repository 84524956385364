import React, {useRef, useEffect, useState} from 'react'
import './printcertificate.scss'
import { Button } from '@material-ui/core'
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import DataHelper from '../../../utils/DataHelper';
import Celogo from '../../../assets/img/ce_logo_skill_comp.png'
import SkillCertificate from './SkillCertificate';
import t from '../../../translation/useTranslate';
import SocialShare from '../socialShare/SocialShare';
import * as moment from 'moment-js';
import ScharCertificate from './ScharCertificate';

const PrintCertificate = ({defaultUser, topic}) => {
    const courseInfo = useSelector(state => state.courseInfo)
    const {userInfo, courseTracking:{courseTracking}, globalStyle} = useSelector(state => state)
    const trackInfo = useSelector(state => state.courseTracking);
    const today = new Date();
    const date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear()
    const printRef = useRef('');
    // const ms=courseTracking?.scorm?.ll?.split('^')[0];
    const [totalTime,setTotalTime] = useState(DataHelper?.timeConvert(1000))
    const [logoPath, setLogoPath] = useState(window.ce.isStandalone ? window.ce.siPath : Celogo);
    useEffect(() => {
        try{
            const clientId = userInfo?.user?.pvtid 
            const fileExtension = userInfo?.user?.logo ? userInfo?.user?.logo.split(".").pop() : 'png';
            if(clientId && fileExtension && Number(clientId)!==222  && !window.ce.isStandalone){
                    setLogoPath('https://dg7zdxz92ywv.cloudfront.net/platform/clientlogo/'+clientId+'.'+fileExtension)
            }
        }catch{}
    }, [userInfo])
    useEffect(() => {
        trackInfo?.courseTracking?.scorm?.ll && setTotalTime(DataHelper?.timeConvert(trackInfo?.courseTracking?.scorm?.ll?.split('^')[0]));
    }, [trackInfo])
    const getTimeSpent = () => {
      return totalTime || DataHelper?.timeConvert(trackInfo?.courseTracking?.scorm?.ll?.split('^')[0]) || trackInfo?.scorm?.scormobj?.tspent || trackInfo?.scorm?.scormobj?.ll?.totaltime || DataHelper?.timeConvert(1000)
    }

    const getCeCertificate=(completedDate,courseName,userName,totalTime,cmoment)=>{
        return  <div className='print-vertificate-main ' ref={printRef}>
            <div className='background-img-container'>
                <img  src={require('../../../assets/img/certificate_print.png').default}  alt="" className='bg-image'/>
                <div className='print-content'>
                    <div className='print-certificate-logo'>
                        <img src={logoPath} alt="" />
                    </div>
                    <div className='print-certificate-heading'>
                        <p>{t("CRT_CRS_COMPLETION")}</p>
                    </div>
                    <div className='print-certificate-course-person'>
                        <p className="certifies-that">{t("THIS_CERTIFIES")}</p>
                        <p className="user-name">{userName}</p>
                    </div>
                    <div className='print-certificate-course-name'>
                        <p className='heading-course-name-text'>{t("SUCCESSFULLY_COMPLETED_CRS")}</p>
                        {/* <p className='course-name'>{ courseInfo?.courseAttributes?.coursename } </p> */}
                        <p className='course-name' dangerouslySetInnerHTML={{ __html:courseName }} />
                    </div>
                     <div className='print-certificate-course-info'>
                    <span className='course-time-spent'>{t("TOT_SPENT")}: <span>{getTimeSpent()}</span></span>
                    <span className='course-completion-date'>{t("COMPLETION_DATE")}: <span>{DataHelper.MonthDayYearFormat(date)}</span></span>
                </div>
            </div>
            <div className='print-certificate-course-info_outer'>
                    <span className='course-time-spent'>{t("TOT_SPENT")}: <span>{getTimeSpent()}</span></span>
                    <span className='course-completion-date'>{t("COMPLETION_DATE")}: <span>{completedDate}</span></span>
            </div>
        </div>
        </div>
    }
    const completedDate=DataHelper.MonthDayYearFormat(date);
    const courseName=courseInfo?.courseAttributes?.coursename || ''
    const userName=`${userInfo?.user?.firstname || window?.ce?.ceuser?.firstname ||  "Test User"} ${userInfo?.user?.lastname || window?.ce?.ceuser?.lastname || ''}`
    
    
    const getCertificate=()=>{
       const cmoment=moment(totalTime).format('MM/DD/YYYY h:mm')
        switch (globalStyle?.info?.cert?.c_type) {
            case 'ce':
             return getCeCertificate(completedDate,courseName,userName,totalTime,cmoment);
            case 'skill': 
            return   <SkillCertificate 
                completedDate={completedDate}
                userName={userName}
                courseName={courseName}
                totalTime={totalTime}
                social={globalStyle?.info?.cert?.soc_publish }
                ms={cmoment}
                printRef={printRef} />
              case 'schar': 
              return <ScharCertificate
                completedDate={completedDate}
                userName={userName}
                courseName={courseName}
                social={globalStyle?.info?.cert?.soc_publish }
                totalTime={totalTime}
                cmoment={cmoment}
                printRef={printRef} />
            default:
                return getCeCertificate(completedDate,courseName,userName,totalTime,cmoment)
        }
    }


    return (
        <>
        {topic === 'Certificate' &&<div className='print-certificate-container'>  
                <div className='print-certificate-btn text-right'>
                {globalStyle?.info?.cert?.soc_publish &&<SocialShare
                    completedDate={completedDate}
                    userName={userName}
                    courseName={courseName}
                    totalTime={totalTime}
                    style={{ top: '0%', width:'85%' }}
                  />
                  }
                    <ReactToPrint
                        trigger={() => <Button variant="contained" color="secondary" className='print-btn'>{t("PRINT")}</Button>}
                        content={() => printRef.current}
                    />
                 </div>
                { getCertificate()}
             </div>  }
        </>
    )
}

PrintCertificate.defaultProps = {
    defaultUser: {
        userName: 'Ben Watson',
        email: 'user@user.com'
    }
}

PrintCertificate.propTypes = {

}

export default PrintCertificate
