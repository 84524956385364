import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './propertypanel.scss'
import { Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import * as courseEditAction from '../../../redux/actions/courseEditAction'
import ComponentConstants from '../../../utils/ComponentConstants';
import * as ceAssetsInfoAction from '../../../redux/actions/ceAssetsInfoAction';
import * as globalStyleAction  from '../../../redux/actions/globalStyleAction'
import PlayerConstants from '../../../utils/PlayerConstants';


/**
 * 
 * @param {*} param0 
 * @returns 
 */

/** 
 * Selected Component props will be pass as input to this property panel 
 * handler : event to pass the properties
 * */

const PropertyPanel = ({ data, handler }) => {
    const [compntProperty, setCompntProperty] = useState({})
    const [editInfo, setEditStatus] = useState({ status: true })
    const dispatch = useDispatch()
    const {courseInfo,courseEdit} = useSelector(state => state);
    const ceAssetsInfo = useSelector(state => state.ceAssetsInfo);
    const courseTracking = useSelector(state => state.courseTracking);
    const globalStyle = useSelector(state => state.globalStyle);
    const lrn_Id = useSelector(state => state.courseEdit?.itemInfo?._id);
    // const [ saveFlag, setSaveFlag ] = useState(false)
    const itemInfo = useSelector(state => state.courseEdit?.itemInfo);
    const c_Id = window?.ce?.rlm?.config_id;
    const t_Id = courseInfo?.topic?.id

    /**
     * 
     * @param {*} val 
     * invoked from various component Panel
     * handleProperties props to the respecive loaded Panels
     * setting the value in compntProperty
     */
    const handleCompProperties = (val) => {
        setCompntProperty(val)
        if (hasUpdated(val, compntProperty)) {
            setEditStatus({ ...editInfo, status: false })
        }
        // setSaveFlag(!saveFlag)
    }
    useEffect(() => {
        handler({ type: ComponentConstants.WYSIWYG, data: compntProperty })
    }, [compntProperty]);

    //split the src to  get the Ceassets Id
    const assetsId = (src) => {
        try {
            let list = src.split('/');
            let nameList = list[list.length - 1].split('.');
            let FileName = -1;
            if (nameList?.length > 0) {
                FileName = nameList[0]
            }
            return FileName;
        } catch (e) {
            console.debug(e);
        }
    }
    let ceAssets = ceAssetsInfo?.allCeAssets;
    const updateAssetIds = async (id, type) => {
        const currentAsset = ceAssets[id];
        if (currentAsset) {
            let assetCIds = {
                lrn: currentAsset?.lrnids,
                tp: currentAsset?.topicids,
                crs: currentAsset?.cids,
            };
            let cIdxs = {
                lrn: assetCIds?.lrn.indexOf(itemInfo?._id),
                tp: assetCIds?.tp.indexOf(t_Id),
                crs: assetCIds?.crs.indexOf(c_Id)
            };
            try {
                if (type === 'ADD') {
                    if (cIdxs?.lrn === -1) {
                        assetCIds.lrn.push(itemInfo?._id);
                        if (cIdxs?.tp === -1) assetCIds.tp.push(t_Id);
                        if (cIdxs?.crs === -1) assetCIds.crs.push(c_Id);
                    }
                } else {
                    if (cIdxs?.lrn !== -1) {
                        assetCIds.lrn.splice(cIdxs?.lrn, 1);
                        //Course flatten Array
                        let topicflatten = courseInfo?.flatten;
                        const topicData = topicflatten[courseInfo?.topic?.nodeId];
                        if (topicData) {
                            //Topic lrncontent check -if any other used or not
                            const isTopicUsed = topicData.lrncontent.filter((lrn) => {
                                if (assetCIds?.lrn?.indexOf(lrn._id) > -1 && lrn._id !== itemInfo?._id) {
                                    return lrn._id
                                }
                            });
                            if (isTopicUsed.length === 0) {
                                assetCIds.tp.splice(cIdxs?.tp, 1);
                                const crsTopics = courseTracking?.courseTracking?.topic;
                                //Course Topic check -if any other used or not
                                const isCourseUsed = crsTopics.filter((tp) => {
                                    if (assetCIds?.tp?.indexOf(tp?.topicId) > -1 && tp?.topicId !== c_Id) {
                                        return tp
                                    }
                                });
                                if (isCourseUsed.length === 0) assetCIds.crs.splice(cIdxs?.crs, 1);
                            }
                        }
                    }
                }
                const updatedAssets = {
                    ...currentAsset,
                    lrnids: assetCIds.lrn,
                    topicids: assetCIds.tp,
                    cids: assetCIds?.crs,
                }
                dispatch(ceAssetsInfoAction?.ceAssetsLinkedRequest(updatedAssets));
                dispatch(ceAssetsInfoAction?.ceAssetsLinkedResponse(updatedAssets));
            } catch (e) {
                console.debug(e);
            }
        }
    }


    //This function handles the CE Library asset usage update actions ( previousAsset | newAsset | updatedProps ). 
    const updateAssets =async (previous, current, prop) => {
        try{
            function getBaseURL(url) {
                try {
                    const { protocol, hostname } = new URL(url);
                    return `${protocol}//${hostname}`;
                } catch {
                    // Return the original URL if it's invalid
                    return url;
                }
              }
            //base url's
            const pre = getBaseURL(previous);
            const crt=getBaseURL(current)??"";
                if (pre !== crt) {
                    let preAssetId = -1; let crtAssetId = -1;
                    const paths = PlayerConstants.ASSET_HOST
                    
                    if (paths.includes(pre)) preAssetId = assetsId(previous);
                    if (paths.includes(crt)) crtAssetId = assetsId(current);
                    if (preAssetId !== -1) await updateAssetIds(preAssetId, 'REMOVE') ;
                    if (crtAssetId !== -1) await updateAssetIds(crtAssetId, 'ADD') ;
                    if ((crtAssetId !== -1 || preAssetId !== -1) &&  JSON.stringify( prop ) !== '{}' ){
                        await handler({ type: ComponentConstants.SAVE_COMP_PROPS,data:prop, id: data._id });
                    } 
                }
                editInfo.status=false;
           }catch (e) {
            console.debug(e);
        }
    }

    const getFileName=(src)=>{
        try{
            let splitArr=src?.split('/');
            let file
            if(itemInfo?.name==="Video"){
                file=splitArr[splitArr?.length-1].split('.');
            }else{
                file=splitArr[splitArr?.length-2].split('.');
            }
            return file[0] 
        }catch(e){}
    }

    const updateCuePoint=(props)=>{
        try{
            let newcueList=props?.cuepoints.map((el)=>{
             if(el?.shown) delete el?.shown;
             return el
            })
            let cueList={
                // if its a config we need to send lrnId
                refid : (window?.ce?.ceuser?.type==="config" && (props?.isPersonalized || false)) ? props?.lrnId || "0": getFileName(props?.src),
                cid : window?.ce?.rlm?.config_id, 
                stype: window?.ce?.ceuser?.type==="config" ? 5 : 4,
                status:1,
                cue:newcueList
            }
            dispatch( courseEditAction.saveCuepoints(cueList));
        }catch(e){}
     }

    /*
     // Update the quiz configuration settings globally
    */
   const applyToGlobal=(name,value,lrn)=>{
    let lrnData=globalStyle?.lrnSettings||{}
    lrnData[lrn]= {...lrnData[lrn],[name]: value||false};
    dispatch(globalStyleAction.updateGlobalLrnSettings(lrnData || {}));
}



    const renderPropertyPanel = (comp) => {
        try {

            let Component = require( `./${ comp.name }Panel` ).default;
            comp.props[ 'id' ] = comp?._id
            comp.props['name'] = comp?.name
            // console.debug("comp.props",comp.props)
            let isAssets=PlayerConstants.ASSETS_USED_COM.indexOf(comp.props.cename);
            let newComProp=comp.props
            if(isAssets > -1){
                newComProp={...newComProp,handleCeAssets:updateAssets}
            }
            //Global LRN settings
            if(["Quizzes"].indexOf(comp.props.cename) > -1){
                newComProp={...newComProp,handleCeAssets:updateAssets,glob:globalStyle?.lrnSettings["Quizzes"],applyToGlobal:applyToGlobal}
            }
            return <Component {...newComProp} c_Id={c_Id} t_Id={t_Id} handleProperties={handleCompProperties} handleClose={resetProps}  />
        } catch (e) {
            console.debug(e.message)
        }
    }

    const handleSubmit = () => {
    try{
        if (JSON.stringify(compntProperty) === '{}') {
            delete data.props.handler
            delete data.props.track
            console.log(data, 'unchanged object is ')
            return ; 
        } else {

            console.log( 'comp property', compntProperty);
            if(["ArchiveVideo","Video"].indexOf(itemInfo?.name) > -1 && courseEdit?.isAddVideoSupplimentaries?.isActive){
                compntProperty["isPersonalized"] = data?.logs?.length > 0 ? (data?.logs[0].status === -1 || data?.logs[0].status === -2) ? false : true : false;
                compntProperty["lrnId"] = data?._id || '0';
                updateCuePoint(compntProperty);
            }else if(["ArchiveVideo","Video"].indexOf(itemInfo?.name) > -1){
                    handler( { type: ComponentConstants.SAVE_COMP_PROPS, data: {...compntProperty,cuepoints:[]}, id: data._id } )
            }else if(["Quizzes"].indexOf(itemInfo?.name) > -1){
                handler( { type: ComponentConstants.SAVE_COMP_PROPS, data:{...compntProperty,...getQuizIds(compntProperty?.items)}, id: data._id } )
            }else{
                handler( { type: ComponentConstants.SAVE_COMP_PROPS, data:compntProperty, id: data._id } )
            }
            dispatch( courseEditAction.toggleDrawer( { open: false, drawerWidth: 0 } ) )
        }
    }catch(e){}
    }
    const getQuizIds = (items) => {
        let qIds = {"items": []};
       items.forEach(el =>{
        qIds["items"].push(el?._id)
       })
       return qIds;
    }
    const resetProps = () => {
        dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }))
    }
    const customStringify = (value) => {
        const cache = new Set();
        return JSON.stringify(value, (key, val) => {
          if (typeof val === 'function' || val === undefined) {
            return null; // Replace functions and undefined with null
          }
          if (typeof val === 'object' && val !== null) {
            if (cache.has(val)) {
              return; // Handle circular references
            }
            cache.add(val);
          }
          return val;
        });
      };
    const hasUpdated = (compProps, newProps) => {
        const keys = new Set([...Object.keys(compProps), ...Object.keys(newProps)]);
        for (const key of keys) {
          if (customStringify(compProps[key]) !== customStringify(newProps[key])) {
            return true;
          }
        }
        return false;
    }



    return (
        <div>
            <div className="prop-panel card">
                {renderPropertyPanel(data)}
            </div>
            <div className='property-action-info'>
                <Button className='cancel-btn' onClick={resetProps}>Cancel</Button>
                <Button className='submit-btn' disabled={editInfo?.status} onClick={handleSubmit}>Save </Button>
            </div>
        </div>
    )
}


PropertyPanel.propTypes = {
    data: PropTypes.object,
}
PropertyPanel.defaultProps = {
    data: { props: {} }
};

export default PropertyPanel