 const BuildHelper = {
    config :{},
    initialize : (cnf) =>  {
        BuildHelper.config = {}
        BuildHelper.config.env = process?.env;
        BuildHelper.config.isscorm = cnf.scorm || false;
        BuildHelper.config.roleId = cnf.roleId || 4;
    },
    isOnScorm : () =>   {
        return BuildHelper.config.isscorm;
    },
    roleId: () => {
        return BuildHelper.config.roleId;
    },
    isLearner :()   =>{
        return Number(BuildHelper.config.roleId||window?.ce?.ceuser?.role_id) === 4
    },
    isLocalRun :() => {
        return  ( window.location.href.includes("localhost") ? true : false)
    },

    isConfig :() => {
        return  ( window?.ce?.ceuser?.type ==="config" ? true : false)
    },
    languageList:()=>{
        return (window?.ce?.platform_scorm?.lnlist)
    },
     defaultLanguage : () => {
        try {
            // Retrieve the default language from local storage
            let value = localStorage.getItem('defaultLn');
            value = value ? JSON.parse(value) : null;
    
            // Get the list of available languages from the list
            const languages = BuildHelper?.languageList()?.languages || [];
    
            // here is only one language available and no value in local storage, set it as the default
            if (languages?.length === 1 && !value) {
                const defaultLn = languages[0]?.lan;
                localStorage.setItem('defaultLn', JSON.stringify(defaultLn));
                return defaultLn;
            }
    
            // Return the value from local storage if available
            return value;
        } catch (e) {
            console.error('Error fetching or setting default language:', e);
            return false;
        }
    },
    isStandalone:()=>{
        return (window.ce.isStandalone)
    },
    getExternalPath: () =>{
        return  window.sessionStorage.getItem('external-path') || ''
    }
 

}


export default BuildHelper;
