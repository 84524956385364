import React, { useState, useEffect,useCallback } from 'react'
import AccordionItem from '../../../components/ui/AccordionItem/AccordionItem'; // Assuming your AccordionItem component is in a separate file
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AddCircle } from '@material-ui/icons';
import AssetUploader from '../UploaderPanel/AssetUploader/AssetUploader';
import { useDispatch, useSelector } from 'react-redux';
import * as ceAssetsInfoAction from '../../../redux/actions/ceAssetsInfoAction'
import ResizableTextArea from '../../../components/ui/ResizableTextArea/ResizableTextArea';
import CustomCheckbox from '../../../components/ui/CustomCheckbox/CustomCheckbox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RadioSelect from '../../../components/ui/RadioSelect/RadioSelect';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import AppButton from '../../../components/ui/AppButton/AppButton';
import CustomSweetAlert from '../../../components/ui/CustomSweetAlert/CustomSweetAlert'
import QuizConfig from "../../../components/ui/QuizConfig/QuizConfig";
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import PlayerConstants from '../../../utils/PlayerConstants';

// Initial quiz object structure
export const quizObj = {
    options: [
        {
            id: 0,
            text: ``,//our reference--while pushing we need to do map
            ans: true
        }
    ],
    answers: [], // we storing multiple answer's
    feedback: [
        'Correct.',// 0 right answer feedback
        'Sorry, that’s not correct.'// 1 wrong answer feedback
    ],
    question: ``,
    hasfeedback: 0, //0 - false, 1 - true
    qtype: null,// 0 true/ false - 1 single - 2- multiple question type 
}
/**
 * Handles the complete quiz question data.
 * @param {*} items
 * @returns
 * 
 * ########### One custom thing we follow because of our configuration flow:
 * ## type 0 (true/false) | 1 (single answer) | 2 (multiple answers)
 * ## For type 0, we use the answer array[0]+1 offset.
 * ## For example, the option at the 0th index is the answer, but in our JSON, it is at index [1].
 * 
 * We use 2 panels in this com. Based on the selection, we render the corresponding UI element.
 * For normal edits, we use quizQuestionPanel. For custom edits, we use customQuizQuestionPanel.
 * 
 */


const QuizzesPanel = ({ handleProperties, id, c_Id, items=[], t_Id, max_select, randomization, pers, glob, config, applyToGlobal, isPreTest=null }) => {
    const [data, setData] = useState({ items, max_select, randomization, pers, config });
    const { ceAssetsInfo: { quizQuestions }, courseInfo } = useSelector(state => state);
    const [quizForm, setQuizForm] = useState();
    const [questionData, setQuestionData] = useState();
    const [showQuizBank, setShowQuizBank] = useState(false);
    // const [expanded, setExpanded] = useState(0);
    const [isActive, setIsActive] = useState(null);
    const [isConfig, setIsConfig] = useState(false);

    //personalize value | 1- very first time personalize | 2 always global || 3 always personalize | -1 close non personalize state
    const [personalize, setPersonalize] = useState(pers || -1);
    const [globalCfg, setGlobalCfg] = useState(glob?.iscfg || false);
    const [popup, setPopup] = useState(false);
    const [updatedConfig, setUpdatedConfig] = useState(null);
    const dispatch = useDispatch();

    const editPanelFormat = (quizzes, type) => {
        try {
            const optionFormat = (quiz) => {
                if (typeof quiz?.options[0] === 'object') return quiz?.options;

                return quiz?.options?.map((e, index) => ({
                    text: e,
                    ans: Number(quiz?.qtype) === 0
                        ? quiz?.answers?.map(Number)?.includes(index + 1)//// check if index + 1 is included
                        : quiz?.answers?.map(Number)?.includes(index)
                })) || [];
            };
            // Initialize the formattedQuiz variable based on the type
            const formattedQuiz = (type === 0)
                ? items.map((quiz) => ({
                    ...quiz,
                    options: optionFormat(quiz),
                    answers: quiz?.answers||[]
                }))
                : [...questionData, {
                    ...quizzes,
                    options: optionFormat(quizzes),
                    answers:quizzes?.answers||[]
                }];

            setQuestionData(formattedQuiz);
        } catch (e) {
            console.error("Error formatting quiz:", e);
        }
    };


    useEffect(() => {
        editPanelFormat(items, 0);
        if (quizQuestions?.length === 0) {
            dispatch(ceAssetsInfoAction.getQuizQuestionsRequest(courseInfo))
        }
    }, [items?.length])

 /******** Personalize Functions ********/

    const handlePersonalize = () => {
        setIsConfig(true)
    }

    const globalChange = () => {
        if (personalize === 1) {
            applyToGlobal("iscfg", true, "Quizzes");
            applyToGlobal("config", updatedConfig, "Quizzes");
            setPersonalize(2);
            setData({ ...data, pers: 2 });
            handleProperties({
                ...data,
                //personalize value | 1- very first time personalize | 2  global || 3 always personalize | -1 close non personalize state
                pers: 2
            });
            setPopup(false);
            setGlobalCfg(true)
            return
        }
        if (personalize === -1 && !globalCfg) {
            setPersonalize(1);
            setData({ ...data, pers: 1 });
            handleProperties({
                ...data,
                //personalize value | 1- very first time personalize | 2 global || 3 always personalize | -1 close non personalize state
                pers: 1
            });
            setPopup(false);
            return
        }
        setPersonalize(3);
        setData({ ...data, pers: 3 });
        handleProperties({
            ...data,
            //personalize value | 1- very first time personalize | 2 global || 3 always personalize | -1 close non personalize state
            pers: 3
        });
        setPopup(false);
    }

    const onchangePersonalize = () => {
        if (globalCfg) {
            setPopup(true);
            return;
        }
        if (personalize === -1) globalChange()
    }


    const getConfirmationText = () => {
        if (personalize === 2 || glob?.iscfg) {
            return "Are you sure you want to personalize this quiz?"
        } else {
            return "Are you sure you want to make this quiz global and configure the change globally?"
        }
    }


    //to handle configuration changes
    const handleConfig = (cf) => {
        // Update configuration properties
        handleProperties({
            ...data,
            config: { ...cf } //config property with new configuration or empty object
        });
        setUpdatedConfig({ ...cf }); //updated configuration state
        setData({ ...data, config: { ...cf } });
    }

    const handleReset=()=>{
        setPersonalize(-1);
        setGlobalCfg(false);
    }

    // to close the SweetAlert and reset state
    const closeAlert = () => {
        setGlobalCfg(false); // Reset global configuration 
        // setMoveGlobal(false); // Reset move global 
        setPopup(false)
    }


  /******** Normal Quiz Action Functions ********/

    // Handle accordion expand/collapse
    const handleChange = (panel) => (event, isExpanded) => {
        // setExpanded((isExpanded) ? panel : false);
    };
    const handleUpdateAccList = (e, updated) => {
        setQuestionData([...updated]);
        const idsInArray2 = new Set(updated.map(item => item?._id));
        let updatedItem = items?.filter(item => idsInArray2.has(item?._id));
        handleProperties({ ...data, items: [...updatedItem] });
        setIsActive(null)
        setQuizForm({ ...quizObj });
        setData({ ...data, items: [...updatedItem]})
        e?.stopPropagation();
    }

    // Handle adding a new quiz question
    const handleAddNewQuiz = () => {
        const cloneObj = JSON.parse(JSON.stringify(quizObj));
        setQuizForm({ ...cloneObj });
        document.getElementById("myDiv").scrollIntoView();
    }

    // Handle hiding/showing the quiz bank
    const hideShowQuizBank = () => {
        setShowQuizBank(false)
    }

    const onClickAddQuestion = (queData) => {
        // Handle adding a question from the quiz bank
        handleProperties({ ...data, items: [...items, { ...queData }] });
        setData({ ...data, items: [...items, { ...queData }] })
        editPanelFormat(queData, 1)
    }

    const handleType = (e) => {
        let Form = { ...quizForm, [e?.target?.name]: Number(e?.target?.value) }
        if (Number(e?.target?.value) === 0) { // type true false default option 
            Form = {
                ...Form, options: [
                    { id: 0, text: 'True', ans: true },
                    { id: 1, text: 'False', ans: false },
                ]
            }
        } else if (Number(e?.target?.value) === '1' && Form?.options.length > 0) { // single answer only allow single answer
            let option = Form?.options?.map(e => { return { ...e, ans: false } });
            option[0].ans = true
            Form = { ...Form, options: option }
        }
        setQuizForm({ ...Form })
        e.stopPropagation()
    }

    const handleQuestion = (e) => {
        let Form = { ...quizForm, [e?.target?.name]: e?.target?.value };
        setQuizForm({ ...Form });
    }

    // Handle checkbox change for feedback
    const handleCheck = (e) => {
        setQuizForm({ ...quizForm, [e?.target?.name]: (e?.target?.checked ? 1 : 0) })
        e.stopPropagation()
    }
    // Handle feedback change
    const handleFeedBack = (e, key) => {
        quizForm[e?.target?.name][key] = e?.target?.value || ""
        setQuizForm({ ...quizForm })
        e.stopPropagation()
    }

    const handleOption = (e, key) => {
        quizForm[e?.target?.name][key]['text'] = e?.target?.value || ""
        setQuizForm({ ...quizForm })
        e.stopPropagation()
    }

    const handleEdit = (event, index) => {   // Handle quiz edit
        setIsActive(index);
        setQuizForm(questionData[index] || quizObj);
        document.getElementById("myDiv").scrollIntoView();
        event.stopPropagation()
    }

    const handleSave = (event) => {
        let updatedList = [...questionData];
        let action=''
        if (isActive || isActive === 0) {
            updatedList[isActive] = { ...quizForm }
            setQuestionData([...updatedList])
            action='edit'
            setIsActive(null);
        } else {
            updatedList = [...updatedList, quizForm];
             action='add'
        }
        handleApiRequest(action)
        setQuestionData([...updatedList])
        setQuizForm(null);
        event.stopPropagation()
    }

    const handleCancel = (event) => {
        setQuizForm(null);
        setIsActive(null)
        event.stopPropagation()
    }

    const handleAddNewOptions = () => {
        const maxId = Math.max(...quizForm?.options?.map(item => item?.id), 0) || 1;
        quizForm?.options.push({
            id: maxId,
            text: ''
        })
        setQuizForm({ ...quizForm })
    }

    const handleDelete = (index) => {
        const removed = quizForm?.options?.filter((e, i) => i !== index)
        setQuizForm({ ...quizForm, options: [...removed] })
    }

    const handleCorrection = (e, index) => {
        if ([0, 1].includes(Number(quizForm.qtype))) {
            // For True/False and Single Answer question types only one answer
            let options = quizForm?.options?.map(e => { return { ...e, ans: false } })
            options[index].ans = true;
            setQuizForm({ ...quizForm, options: [...options] })
            return;
        }
        // For Multiple Answer question type
        quizForm.options[index].ans = !(quizForm?.options[index]?.ans)
        setQuizForm({ ...quizForm })
    }


    const handleApiRequest = async (type,updatedData=null) => {
        let requestFormat = { ...quizForm };
        let answer = [];
        
        let updatedItem = questionData?.map((item) => {
            return {
              ...item,
              options: item?.options?.map((option) => (option?.text||option)),
            };
          });
          
        // Map options and collect answers
        const optionAr = requestFormat?.options?.map((e, index) => {
            if (e.ans) {
                answer.push(Number(requestFormat.qtype) === 0 ? index + 1 : index);
            }
            return e.text;
        });

        // Prepare the request format
        requestFormat = {
            ...requestFormat,
            options: optionAr,
            answers: answer,
            status: 1,
            refid: t_Id,
            info: { cid: (c_Id||window?.ce?.rlm?.config_id) ,isPreTest:isPreTest},
        };
        if(isPreTest)requestFormat.isPreTest=isPreTest;

        if (type === 'edit') {
            // Update the item in the array if it matches the requestFormat._id
            const itemIndex = updatedItem.findIndex(e => e?._id === requestFormat?._id);
            if (itemIndex !== -1) {
                updatedItem[itemIndex] = requestFormat;
            }
            // Cleanup requestFormat
            const { createdAt, __v, ...cleanedRequestFormat } = requestFormat;
            dispatch(ceAssetsInfoAction.addQuizInBankReq(cleanedRequestFormat));
            dispatch(ceAssetsInfoAction.addedQuizResponse(requestFormat))
        } else if (type === 'add') {
            // Add a new item
            const path = `${PlayerConstants?.API_DESTINATION_STAGING || ''}learning_aids/addquizquestion`;
            const payload = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestFormat),
            };
            try {
                const response = await fetch(path, payload);
                const res = await response.json();
                if (res?.data) {
                    updatedItem.push(res.data);
                    dispatch(ceAssetsInfoAction.addedQuizResponse(res.data))
                }
            } catch (error) {
                console.error("Error adding quiz question:", error);
            }
        }

        // Update state with new items
        const newData = { ...data, items: [...updatedItem] };
        setData({ ...newData });    
        handleProperties({ ...newData });
    };


    const options = [
        { label: 'True/false', value: '0' },
        { label: 'Single Answer', value: '1' },
        { label: 'Multiple Answer', value: '2' },
    ];

    /**
    # Add and edit action screen UI elements. We keep it in this function.
    # Based on our selection, we render some custom options.
    */
    const quizQuestionPanel = () => {
        return <>
            <DndProvider backend={HTML5Backend}>
                {questionData && questionData?.length > 0 &&
                    questionData?.map((el, index) => {
                        const isDisable = index !== isActive;
                        let editItem = isDisable ? el : quizForm
                        return <AccordionItem
                            className={`inner-summary`}
                            key={index}
                            name={`${editItem?.question}`}
                            index={index}
                            el={editItem}
                            isDrag={false}
                            listUpdate={handleUpdateAccList}
                            data={questionData}
                            handleAccordionChange={handleChange}
                            expanded={false}
                            summary={{
                                delete: { min: 1 },
                                custom: {
                                    element: <div className='edit-prop-accordion'>
                                        {isDisable ? <span data-tooltip='Click to Edit your quiz' data-position='bottom' onClick={(e) => handleEdit(e, index)}><EditIcon /></span>
                                            : <span data-tooltip='Click to upload & update the quiz' data-position='bottom' onClick={(e) => handleSave(e, index)} > <SaveIcon /></span>}
                                    </div>
                                }
                            }}
                        // dynamicDetailsComponent={editPanel(editItem)}
                        //   handleAccordionChange={handleChange}
                        //   isDrag={drag}
                        />
                    })}
            </DndProvider>
            {!quizForm && <div>
                <div className='add-new-question' title={'Add Option'} onClick={() => handleAddNewQuiz()}><AddCircle className='add-accordion' /> Add New Question </div>
                <button className='Quiz-Bank' onClick={() => setShowQuizBank(true)} type='submit'>Quiz Bank</button>
            </div>}
            {showQuizBank && <AssetUploader model={hideShowQuizBank} data={quizQuestions} asset={onClickAddQuestion} type="question" />}
        </>
    }

    const customQuizQuestionPanel = () => {
        return <>
            {/* 1 and 3 personalize values 2 global config */}
            {((personalize === 1 || personalize === 3)) ?
                <QuizConfig
                    configObj={updatedConfig || config || null}
                    max_select={data?.max_select}
                    randomization={data.randomization}
                    glob={glob}
                    handleReset={handleReset}
                    handleConfig={(cf) => handleConfig(cf)} /> :
                <div>
                    {(glob?.iscfg && personalize !== 3) && <h6 className='p-3'><InfoIcon /> This setting is currently configured globally. If you wish to personalize it, you can enable the option and proceed to edit it accordingly.</h6>}
                </div>
            }
            {(personalize === 1 && !globalCfg && !isPreTest) &&  <CustomCheckbox
                label={'Move to Global'}
                className="py-2"
                checked={popup || false}
                name="randomization"
                onChange={(e) => setPopup(true)}
            />}
        </>
    }

    const formValidation = useCallback(() => {
     try{
        if (quizForm?.options?.length < 2 || !(Number(quizForm?.qtype) || Number(quizForm?.qtype) === 0) || quizForm.question.length < 2) { // Check that qtype is not null
            return false;
        }
        // Check that at least one option has ans - true
        let hasTrueAnswer = false;
        for (let i = 0; i < quizForm?.options?.length; i++) {
            if (quizForm?.options[i]?.test?.length < 2) {
                hasTrueAnswer = false;
            }
            if(quizForm?.options[i]?.ans) hasTrueAnswer = true;
        }
        if (!hasTrueAnswer) {
            return false;
        }
        // Check that feedback array has at least 2 non-empty strings with minimum length of 2
        if (Number(quizForm?.hasfeedback)=== 1) {
            for (let i = 0; i < quizForm?.feedback?.length; i++) {
                if (quizForm?.feedback[i]?.trim()?.length < 1) {
                    return false;
                }
            }
        }

        for (let i = 0; i < quizForm?.options?.length; i++) {
            if (quizForm?.options[i]?.text?.trim()?.length < 1) {
                return false;
            }
        }

        // If all conditions are met, return true
        return true;
     }catch(e){}
    },[{...quizForm}])


    const editPanel = (editItem) => {
        const type = Number(editItem?.qtype);
        const hasValidation=!formValidation()
        return <>
            <div className={`quiz-summary`}>
                <div className='edit-area label'>
                    <label>Question Type </label>
                    {/* <div className={`${!formValidation()&& 'disabled'}`}><SaveIcon  onClick={handleSave}  /></div> */}
                    <div className='px-2'>
                        < AppButton size="small" theme="secondary2" className="mx-2" onClick={handleCancel} >Cancel  </AppButton>
                        < AppButton size="small" theme="secondary2" onClick={handleSave} disabled={hasValidation}>{(isActive || isActive == 0) ? 'Update' : <><AddIcon />Add</>}</AppButton>
                    </div>
                </div>
                {/* <label className='label'>Question Type <SaveAltIcon onClick={(e)=>handleUpload(e, questionData?.length)} /> </label> */}
                <RadioSelect
                    options={options}
                    name="qtype"
                    direction='horizontal'
                    selectedValue={String(editItem?.qtype)}
                    onChange={handleType}
                />
                <ResizableTextArea
                    placeholder="Question"
                    onChange={handleQuestion}
                    value={editItem?.question}
                    name="question"
                    minRows={6}
                />
                <CustomCheckbox
                    checked={(Number(editItem?.hasfeedback) === 1)}
                    label="Explanation / Feedback"
                    className="py-2"
                    name="hasfeedback"
                    onChange={handleCheck}
                />
                {Number(editItem?.hasfeedback) === 1 && <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <label className='label'>Right Answer</label>
                        <ResizableTextArea
                            placeholder="Right Answer"
                            name="feedback"
                            minRows={4}
                            value={editItem?.feedback[0] || ""}
                            onChange={(e) => handleFeedBack(e, 0)}
                        />
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-6'>
                        <label className='label'>Wrong Answer</label>
                        <ResizableTextArea
                            placeholder="Wrong Answer"
                            name="feedback"
                            value={editItem?.feedback[1] || ""}
                            onChange={(e) => handleFeedBack(e, 1)}
                            minRows={4}
                        />
                    </div>
                </div>}
                {/* <br/> */}
                <label className='label d-flex'>Options</label>
                <div className='options-list'>
                    {editItem?.options?.map((e, idx) => (<>
                        {/*/<div className="option-selection" key={idx}><DeleteIcon onClick={()=>handleDelete(idx)} /></div> */}
                        <div className="option-container" key={idx}>
                            {editItem?.options[idx]?.ans ? <TaskAltIcon className='correct-ans option-ans' onClick={(e) => handleCorrection(e, idx)} /> : <UnpublishedIcon onClick={(e) => handleCorrection(e, idx)} className='option-ans' />}
                            <ResizableTextArea
                                placeholder="Enter your Option"
                                name="options"
                                className={'option-textarea'}
                                value={e?.text}
                                onChange={(event) => handleOption(event, idx)}
                                minRows={3}
                            />
                            {quizForm?.options?.length > 2 && <DeleteIcon className='option-delete p-0 m-0' onClick={() => handleDelete(idx)} />}
                        </div>
                    </>
                    ))}
                    {(hasValidation) && <p className='option-error'>Select a question type and add options, and ensure all fields are required with at least 2 characters each.</p>}

                </div>
                {(type !== 0 && type) && <div className='add-quiz-option py-2' title={'Add Option'} onClick={handleAddNewOptions} ><AddCircle className='add-accordion' /> Add New Option </div>}
            </div>

        </>

    }

    /**
     * Based on our selection, we render custom and normal options.
     * quizQuestionPanel - This function handles the question list UI.
     * editPanel - This function handles the edit question UI.
     * customQuizQuestionPanel - This function handles the custom question UI.
     */

    return (
        <div className='row p-2'>
            <div className='col-sm-12 col-md-12 col-lg-6 list-area knowledge-check-prop quizzes-panel-prop card-prop-main-ui acccordion-item-list'>
                {quizQuestionPanel()}
            </div>
            <div className='col-sm-12 col-md-12 col-lg-6'>
                <div className='knowledge-check-prop edit-area quizzes-panel-prop card-prop-main-ui acccordion-item-list' id='myDiv'>
                    <div className='quiz-actions'>
                        {window?.ce?.ceuser?.type === "config" && <>
                            <div className="btn-group" role="group" aria-label="Basic example">
                                <button type="button" className={`${!isConfig && 'active '} btn-g`} onClick={() => setIsConfig(false)}>Questions</button>
                                <button type="button" className={`${isConfig && 'active '} btn-g`} onClick={() => handlePersonalize()}>Customize</button>
                            </div>
                        </>}
                        {(isConfig && personalize !== 3) &&  <div>
                            <CustomCheckbox
                                label={'Customize Quiz'}
                                checked={([1]?.includes(personalize) || popup) || false}
                                name="Personalize"
                                onChange={(e) => onchangePersonalize()}
                            />
                        </div>}
                    </div>
                    {!isConfig ? (quizForm && editPanel(quizForm)) : customQuizQuestionPanel()}
                </div>
            </div>
            <div className='warning-popup'>
                <CustomSweetAlert
                    warning
                    showCancel
                    closeOnClickOutside={false}
                    show={popup}
                    style={{ color: '#000' }}
                    title={<p className="sweet-title">{getConfirmationText()}</p>}
                    cancelBtnText="Close"
                    confirmBtnBsStyle="success"
                    confirmBtnText="Move"
                    className="course-completed-success"
                    onConfirm={() => globalChange()}
                    onCancel={() => closeAlert()} />
            </div>
        </div>
    )
}

export default QuizzesPanel