import React, { useEffect, useState ,useCallback} from 'react'
import PropTypes from 'prop-types'
import {
  convertToRaw, EditorState, ContentState
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './RichTextEditor.scss'
import CustomStyle from './CustomStyle';
import WysiwygPickr from './WysiwygPickr';
import { useSelector } from 'react-redux';
import {
  setBlockData,
  getSelectedBlocksMetadata,  toggleCustomInlineStyle
} from 'draftjs-utils';
import useDebounce from '../../../utils/useDebounce'

const RichTextEditor = ({ data, eventHandler , styles, maxText}) => {
  const globalStyle = useSelector(state => state?.globalStyle);


  const getData = useCallback( (initialData) => {
   try{
    let defaultFontsize=`<span class="4#V" style="font-size:${globalStyle?.comspace?.gfs || '24'}px;">${initialData||''}</span>`
    if(!data){
        return defaultFontsize;
    }
    const tempElement = document.createElement('div');
    tempElement.innerHTML = initialData || '';
    const firstParagraph = tempElement?.querySelector('p');
    if (!firstParagraph || firstParagraph?.firstElementChild?.tagName !== 'SPAN') {
      return defaultFontsize;
    }
    return initialData;
  } catch(e){}
  }, [globalStyle]);


  //using to control two-time function calls 
  const [customStyles, setCustomStyles] = useState([]);
  const [chunkRendereCheck, setChunkRendereCheck] = useState(false);
  const [newstyleUpdateCheck, setnewstyleUpdateCheck] = useState(false);
  const [isToolbar, setIsToolbar] = useState(false);


  // node.attributes converted to Style Objects
  const nodeAttributesToObj = attrs => {
    const objAttrs = { style: null }
    for (let i = attrs.length - 1; i >= 0; i--) {
      if (attrs[i].name !== 'style') {
        if (attrs[i].name && attrs[i].value) {
          objAttrs[attrs[i].name] = attrs[i].value
        }
      } else {
        const stylesInText = attrs[i].value.split(';')
        const styles = stylesInText.reduce((acum, style) => {
          const components = style.split(':')
          if (components[0] && components[1]) {
            let styleName=components[0].replace('-', '_');
            if(styleName.includes('-')) styleName=styleName.replace('-', '_');
            acum[styleName] = `${components[1]}`
          }
          return acum
        }, {})
        objAttrs.style = styles
      }
    }
    return objAttrs
  }


  //htmlToDraft Package optional Function
  function customChunkRenderer(nodeName, node) {
    const allowedNode = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'li']
    if (allowedNode.includes(nodeName)) {
      let objAttrs = {};
      let blockCount = {};
      if (node.hasAttributes()) {
        objAttrs = nodeAttributesToObj(node.attributes);
      }
      blockCount = ({ nodeName: nodeName, node: node, style: objAttrs.style })
      customStyles.push(blockCount);
      setChunkRendereCheck(true);
    }
  }


  //Adding CustomStyles to the ContentBlock inside 
  const newstyleUpdate = (content) => {
    setnewstyleUpdateCheck(true);
    let styledata = []
    if (customStyles.length > 0) {
      customStyles.forEach((el, index) => {
        let styleArray = []
        if (el?.style?.line_height || el.style?.list_style_type || el.style?.list_style_position || el.style?.padding_left ) {
          el.style?.line_height && styleArray.push(['line-height', el.style?.line_height]);
          el.style?.text_align && styleArray.push(['text-align', el.style?.text_align]);
          el.style?.list_style_type && styleArray.push(['list-style-type', el.style?.list_style_type]);
          el.style?.list_style_position && styleArray.push(['list-style-position', el.style?.list_style_position]);
          el.style?.padding_left && styleArray.push(['padding-left', el.style?.padding_left]);
          styledata.push({
            blockindex: index,
            blockStyle: styleArray
          })
        }
      })
    }
    styledata.forEach((e) => {
      if(content.contentBlocks[e.blockindex]._map._root.entries[3][1]._root){
      content.contentBlocks[e.blockindex]._map._root.entries[3][1]._root.entries = e.blockStyle;
      }
    })
    return content
  }


  // ContentBlock rewrite to Add New Style 
  const contentBlock = htmlToDraft(getData(data), !chunkRendereCheck && customChunkRenderer);
  const updatedContentBlock = !newstyleUpdateCheck ? newstyleUpdate(contentBlock) : contentBlock;
  const contentState = ContentState.createFromBlockArray(updatedContentBlock.contentBlocks);
  const [editorState, setEditorstate] = useState(EditorState.createWithContent(contentState));

  const onEditorStateChange = (state) => {
    setIsToolbar(true); 
    setEditorstate(state);
  }

/**
 * This custom hook is used to delay updates, preventing frequent calls to 
 * handleProperties when state changes rapidly
 */
const handleUpdate = useDebounce(editorState, 800);

  /** Initial save button active check TODO */
  useEffect(() => {
    try {
      if(isToolbar){
        let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        eventHandler(html);
      }
    } catch (e) {
      console.debug(e);
    }
  }, [handleUpdate, isToolbar])

  // useEffect(()=>{
  //     setEditorstate(toggleCustomInlineStyle(editorState, 'fontSize', 24))
  // },[])


  const editorStyle = {
    // height: height ? height : window.innerHeight - 500,
    padding: '.5rem',
    backgroundColor:'#e9ecef',
    minHeight: styles?.minHeight ? `${styles?.minHeight}px` : '200px'
  }

  /* text align time this function handle the list points align as well */
  const listPointAlign =()=>{
    try{
      let nextStyleObject = {};
      const currentStyleMap = getSelectedBlocksMetadata(editorState)
      nextStyleObject = Object.assign({}, nextStyleObject, {'list-style-position': 'inside'});
      currentStyleMap.forEach((value, key) => {
        nextStyleObject[key] = value;
      });
      const newState = setBlockData(editorState, nextStyleObject);
      if (newState) {
        onEditorStateChange(newState);
      }
    }
  catch(e){
    console.log("error",e)
  }
  }


  /**
   * concentrate inside line-height applied after 
   * it will be fetched and add the class name to editor div
   * */
  function blockStyleFn(block) {
    try{
    const blockAlignment = block.getData() && block.getData().get('text-align');
    const blockLineHeight = block.getData() && block.getData().get('line-height');
    const blockLineType = block.getData() && block.getData().get('list-style-type');
    const padding_left = block.getData() && block.getData().get('padding-left');
    let listPosition=getSelectedBlocksMetadata(editorState)?.get('list-style-position');
    if((blockAlignment=== 'center' || blockAlignment=== 'right') && !listPosition) listPointAlign();
   
    const styleString = [
      blockAlignment && `rdw-${blockAlignment}-aligned-block`,
      blockLineHeight && `rdw-lineheight-${blockLineHeight.replace('.', '-')}`,
      padding_left && `rdw-pdl-${padding_left.replace('.', '-')}`,
      blockLineType && `rdw-list-style-type-${blockLineType.replace('.', '-')}`
    ]?.filter(Boolean)?.join(' ');

    return styleString||'';
  }
  catch(e){
    console.log("error",e)
  }
  }
  // const handleBeforeInput=(val)=>{
  //   if(maxText){
  //     const textLength = editorState.getCurrentContent().getPlainText().length;
  //     if ((val && textLength >=( maxText || 400)) && maxText) {
  //       return 'handled';
  //     }
  //     return 'not-handled';
  //   }
  //  }
   
  //  const handlePastedText=(val)=>{
  //   if(maxText){
  //   const textLength = editorState.getCurrentContent().getPlainText().length;
  //   return ((val.length + textLength) >= maxText || 600);
  //   }
  //  }
   const handleToolbar=()=> setIsToolbar(!isToolbar);

   const editorLabels = {
    // BlockType
    'components.controls.blocktype.h1': 'H1A',
    'components.controls.blocktype.h2': 'H2A',
    'components.controls.blocktype.h3': 'H3A',
    'components.controls.blocktype.h4': 'H1B',
    'components.controls.blocktype.h5': 'H2B',
    'components.controls.blocktype.h6': 'H3B',
  };

  return (
    <div className={`ck-editor-container  ${isToolbar ? 'show-tool':'hide-tool'}`}>
      <div className='tool-bar-handle' onClick={()=>handleToolbar()} >
        <p>{isToolbar?'Hide':'Show'} Toolbar</p>
      </div>
      <Editor
        editorState={editorState}
        wrapperClassName="richtxt-editor-wrapper-class"
        editorStyle={editorStyle}
        blockStyleFn={blockStyleFn}
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
        // handleBeforeInput={val =>handleBeforeInput(val)}
        // handlePastedText={val =>handlePastedText(val)}
        toolbar={{
          // options: ['inline', 'blockType', 'fontSize', 'textAlign', 
          //           'history', 'colorPicker'],
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'colorPicker', 'link', 'textAlign', 'remove', 'history'],
          inline: {
            options: ['italic', 'bold'],
            bold: { className: 'demo-option-custom' },
            italic: { className: 'demo-option-custom' },
            underline: { className: 'demo-option-custom' },
            strikethrough: { className: 'demo-option-custom' },
            monospace: { className: 'demo-option-custom' },
            superscript: { className: 'demo-option-custom' },
            subscript: { className: 'demo-option-custom' }
          },
          link: { defaultTargetOption: "_blank" },
          blockType: {
            className: 'demo-option-custom-wide',
            dropdownClassName: 'demo-dropdown-custom'
          },
          fontSize: {
          className: 'demo-option-custom-medium',
          options: [8, 9, 10, 11, 12, 14, 16, 18,20, 21, 24, 26,28, 30, 36, 48, 60, 72, 96], 
         },
         colorPicker: { component: WysiwygPickr },
          // fontFamily: {
          //   options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'WorkSansRegular', 'Roboto'],
          //   className: undefined,
          //   component: undefined,
          //   dropdownClassName: undefined,
          // },

        }}
        localization={{ locale: 'en', translations: editorLabels }}
        toolbarCustomButtons={[<CustomStyle type={'indent'}/>, <CustomStyle type={'line-height'}/>, <CustomStyle type={'list-style-type'}/>, <CustomStyle type={'quote'}/>, <div className='hide-toolbar'/>]}
      />
    </div>
  )
}



RichTextEditor.propTypes = {
  /** data props used to map the content innerhtml to the  server*/
  data: PropTypes.string
}

export default RichTextEditor
