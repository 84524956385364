import cstdtrcks from '../../ce-std-jsons/cestd_tracks.json'

    let collections = [
        { id: 1, name: "Professional", url: 'professional_matrix', courses: [] ,countries:{}},
        { id: 2, name: "Practitioner", courses: [], url: 'practitioner_matrix' ,countries:{}},
        { id: 3, name: "Companion", courses: [], url: "companion_matrix" ,countries:{}},
        { id: 4, name: "Import", courses: [], url: "import_matrix" ,countries:{}},
        { id: 5, name: 'Functional', courses: [], url: "function_matrix",countries:{} }
      ]
      
      
      /** Object creation */
      collections.forEach( ( el ) => {
        if(el.id === 1){
          cstdtrcks['collection']['prof'].forEach(prf => {
            const jrds = cstdtrcks['jurisdiction'].find(j =>{ return j.id === prf.jr;});
            /** Professional course data fetcher */
            const json = require('../../ce-std-jsons/track_matrix_'+prf['id']+'_jr_'+jrds['id']+'.json');
            cstdtrcks.jurisdiction.forEach((jrItem)=>{
              for(let tr in json.data){
            if(tr === 'track'){
              if(Number(jrItem.id) === Number(json.data.jrs)){
                if(!el.countries[jrItem.name]){
                  el.countries[jrItem.name]=[json.data]
                  el.countries[jrItem.name]["id"] = jrItem.id
                }else{
                  el.countries[jrItem.name].push(json.data);
                  el.countries[jrItem.name]["id"] = jrItem.id
                }
              }
            }
                
              }
            });
          });
        }
        else{
            /** Other course data fetcher */
          let data = require( '../../ce-std-jsons/' + el.url + '.json' ).data;
          cstdtrcks.jurisdiction.forEach((jrItem)=>{
            data.forEach((tr)=>{
                if(Number(jrItem.id) === Number(tr.jrs)){
                  if(!el.countries[jrItem.name]){
                    el.countries[jrItem.name]=[tr]
                    el.countries[jrItem.name]["id"] = jrItem.id
                  }else{
                    el.countries[jrItem.name].push(tr);
                    el.countries[jrItem.name]["id"] = jrItem.id
                  }
                }
            });
          });
        }
        
      } );
      
export default collections;