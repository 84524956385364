import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import * as courseInfoAction from '../../../redux/actions/courseInfoAction';
import * as courseEditAction from '../../../redux/actions/courseEditAction';
import '../SearchLrnAids/searchlrnaids.scss';
import CommentIcon from '@mui/icons-material/Comment';
import PlayerConstants from '../../../utils/PlayerConstants';
import DataHelper from '../../../utils/DataHelper';
import MuiIcons from "../../../components/ui/MuiIcons/MuiIcons"

/**
 * 
 * We have logs [-1, -2,-3] in the LRN, which indicate customized
 * -1: Customized LRN (applied during cloning)
 * -2: New LRN (created after cloning)
 * -3: LRN after applying the template
 * 1: newly added one(using + add)
 * [-1,-2,-3,7]- resolved (7 we have edit option)
 * 1- active
 * 6- ToDo
 */
const LearningAidComments = ({ data }) => {

    const dispatch = useDispatch()
    const { courseInfo: { flatten } } = useSelector(state => state);
    const [preview, setPreview] = useState({});
    const [selected, setSelected] = useState({});
    const [searchResults, setSearchResult] = useState([]);
    const [selectedLElement, setSelectedLElement] = useState({});
    const [expanded, setExpanded] = useState(0);
    const [CrsList, setCrsList] = useState([]);
    const [cmtStatus, setCmtStatus] = useState(0);
    const [filteredResult, setFilteredResult] = useState([]);


    const onTopicChange = (e) => {
        setSelected(e);
        const filtered = e?.logs?.filter(({ status }) => 
            PlayerConstants?.COMMENTS_STATUS['1']?.id?.includes(status)
        );      
        setCmtStatus('1');
        setFilteredResult([...filtered])
        setSearchResult(e);
        setSelectedLElement({});
    }

    const openAcc = (el) => {
        if (expanded === el) {
            setExpanded(0)
        } else {
            setExpanded(el);
        }
    }

    const selectLearningElement = (lrn) => {
        setSelectedLElement(lrn);
        setPreview(lrn)
    }


    const renderLeaningElements = (lrains) => {
        return (<ul className='learing-element-list'>
            {lrains.map((lrn, index) => {
                return <li key={index} className={lrn?._id === preview?._id ? "flex-item selected-le-item" : "flex-item"} onClick={() => selectLearningElement(lrn.lrn)}>
                    {/* <div>{lrn._id} </div> */}
                    <div className='last-cmt'>
                    <span className='comp-list-icon'><img src={require("../../../assets/img/" +  (PlayerConstants?.COMP_ICONS?.[lrn?.lrn?.name] || 'topic_header.png') ).default} alt='' /></span>
                    {index+1}. {lrn?.comments?.replace(
                    'Copied from course for editing',
                    'Started customizing')} </div>
                    <div className='last-cmt-author'>{lrn?.name}</div>
                    <MuiIcons iconName='star' data-tooltip={PlayerConstants.COMMENTS_STATUS[lrn?.status]?.status||'Resolved'} data-position='left' color={PlayerConstants.COMMENTS_STATUS[lrn?.status]?.color || '#13984B' } className="p-0 pl-2 status-icon" />
                    <div className='last-cmt-date'>{moment(lrn?.modified).format('MMMM D, YYYY') } <strong className='fw-600'>{(moment(lrn?.modified).fromNow())}</strong></div>
                </li>
            })}
        </ul>)
    }


    const topicSelected = (tp) => {
        try {
            dispatch(courseEditAction.toggleDrawer({ open: false, drawerWidth: 0 }))
            dispatch(courseInfoAction.selectedTopic(selected));
            setTimeout(() => {
                dispatch(courseEditAction.toggleDrawer({ open: true, target: {}, itemInfo: tp, panel: 1, drawerWidth: 25 }))
                DataHelper.scrollToLrnElement(tp);
            }, [1000])
        } catch (e) {

        }
    }

    useEffect(() => {
        try {
            const flattenCRS = Object.keys(flatten)?.map((key) => {
                let logs = [];
                const aidTracker = {};
                //const hasConfig=!DataHelper.isCourse()
                flatten[key]?.lrncontent?.forEach((el) => {
                    let lrn = {...el};
                    let currentLogs = el?.logs ?? [];
                    if(currentLogs?.length > 0){
                        currentLogs= currentLogs?.map(e=>{return{...e,lrn:lrn}});
                    }
                    logs = [...logs,...currentLogs];

                    if (lrn?.logs?.length > 0) {
                        if (aidTracker[lrn?.name]) {
                            aidTracker[lrn?.name].lrnaids.push(lrn);
                        } else {
                            aidTracker[lrn?.name] = { name: lrn?.name, lrnaids: [lrn] };
                        }
                    }
                });

                // Sort logs based on the 'modified' date in descending order
                 logs = logs?.sort((a, b) => new Date(b?.modified) - new Date(a?.modified));

                const lrnList = Object.values(aidTracker);
                return { ...flatten[key], logs, lrnList };
            })?.filter(item => item?.logs?.length > 0 && item?.lrnList?.length > 0);
    
            setCrsList(flattenCRS);
        } catch (error) {
            console.error(error);
        }
    }, [flatten]);
    
    const handleStatusFilter=()=>{
        let statusIds=["7",'1','6'];
               // Find the current index of the previous status ID
       const currentIndex = statusIds.indexOf(cmtStatus||'1');
       // Calculate the next index and wrap around using modulus
       const nextIndex = (currentIndex + 1) % statusIds.length;
       setCmtStatus(statusIds[nextIndex]);

       const filtered = searchResults?.logs?.filter(({ status }) => 
        PlayerConstants?.COMMENTS_STATUS[statusIds[nextIndex]]?.id?.includes(status)
      );      
      setFilteredResult([...filtered])
    }
    
    

    return (
        <div className='lrn-comment-container lrnaid-search-panel px-4'>
            <div className='header-comments'>
            {searchResults?.logs?.length > 0 && <MuiIcons iconName='filter' onClick={handleStatusFilter} data-tooltip={PlayerConstants.COMMENTS_STATUS[cmtStatus||'1']?.status} color={PlayerConstants.COMMENTS_STATUS[cmtStatus||'1']?.color}data-position='left' className="p-0 pl-2 status-icon" />}
            </div>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-6 p-0'>
                {CrsList.length > 0 ? <ul className='courselist'>
                        { CrsList.map((el, index) =>{
                            let isComments=el?.logs?.length > 0
                            return <li key={index} className={(el?.id) === selected?.id ? 'course-li-active course-li' : `course-li ${el?.id}`} onClick={() => onTopicChange(el, index)}> {el?.title}  <span><CommentIcon  className={(isComments) ? 'active-cmt cmt-tp' : 'cmt-tp'}/></span></li>
                        })}
                    </ul> : <p className='mx-2 fw-bold'>Comments not available... </p>}
                </div>

                <div className='col-lg-6 col-md-6 col-sm-6 topic-header'>
                    {/* Preview conatiner start*/}
                    {
                        (selectedLElement?.logs?.length > 0) && Object.keys(selectedLElement).length > 0 ?
                            <div className='preview-container cmd-preview'>
                                <div className='preview-head'>
                                    <h6 className='preview-title'>{`${PlayerConstants?.LRN_RE_NAME[selectedLElement?.name] ??selectedLElement.name}`}-<p className='cmt-last-update'>(Last Updated on - {moment(selectedLElement?.logs[selectedLElement?.logs?.length - 1]?.modified).format('MMMM D, YYYY')} {moment(selectedLElement?.logs[selectedLElement?.logs?.length - 1]?.modified).fromNow()})</p></h6>
                                    <div>
                                        <span className='lrn-cmd-edit'><EditIcon className="tp-edit" onClick={() => topicSelected(selectedLElement)} />Edit</span>
                                        <CloseIcon className='close-pre' onClick={() => { setSelectedLElement({}) }} />
                                    </div>
                                </div>  
                                <div id='preview'>
                                    {selectedLElement?.logs.length > 0 && selectedLElement?.logs.map((item, index) => {
                                        return <div className='author-comment-section' key={item._id.toString()}>
                                            <div className='comment-row row'>
                                                <div className='profile-badge col-md-1'>
                                                    <img src={require('../../../assets/img/profile_icon.png').default} alt="" className='badge-tag' />
                                                </div>
                                                <div className='col-md-5'>
                                                    <p className='profile-name '>{item?.name}</p>
                                                </div>
                                                <div className='col-md-1'>
                                                <MuiIcons iconName='star' data-tooltip={PlayerConstants.COMMENTS_STATUS[item?.status]?.status||'Resolved'} data-position='left' color={PlayerConstants.COMMENTS_STATUS[item?.status]?.color || '#13984B' } className="p-0 pl-2 status-icon" />
                                                </div>
                                                <div className='profile-comment col-md-4'>
                                                    <p className='modified-date'>{moment(item.modified).format('MM/DD/YYYY h:mm')} </p>
                                                </div>
                                                <div className='col-md-1'></div>
                                                <div className='comt-preview col-md-10'>
                                                    {item?.comments?.replace(
                                                        'Copied from course for editing',
                                                        'Started customizing')}
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                                {/* Preview conatiner end */}
                            </div>
                            : (filteredResult?.length > 0) ? renderLeaningElements(filteredResult) :( searchResults?.logs?.length > 0 && <h4>Comments not found...</h4>)
    
                    }
                    {(Object.keys(selected).length > 0 && searchResults?.logs?.length < 1) && <div><p> Comments not found in Learning elements.</p></div>}
                </div>
            </div>

        </div>
    )
}

export default LearningAidComments