import * as types from '../reducers/types'

const initialState = {
    tplAssigned: false,
    lAidAdded: false,
    lAidDeleted: false,
    isSavedTp: false,
    isCloned: false,
    tpUpdated: false,
    isGlobalStyle:false,
    authFault:false,
    // any new socket warning are there will go 1,2,3..
    socketWarning:0,// 0 is off (1 is publish happened so we are telling some warning message to the user)
}


const utilityReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.APPLY_TEMPLATE_TOAST:
            let isAssigned = false;
            if ( action?.payload?.status === 'success' ) {
                isAssigned = true;
            }
            return {
                ...state,
                tplAssigned: isAssigned,
                lAidAdded: false,
                lAidDeleted: false
            }
        case types.ADD_LEARNINGAID_TOAST:
            let isAdded = false;
            if ( action?.payload?.status === 'success' ) {
                isAdded = true;
            }
            return {
                ...state,
                lAidAdded: isAdded,
                tplAssigned: false,
                lAidDeleted: false,
            }
        case types.REMOVE_LEARNINGAID_TOAST:
            let isDeleted = false;
            if ( action?.payload?.status === 'success' ) {
                isDeleted = true;
            }
            return {
                ...state,
                lAidDeleted: isDeleted,
                tplAssigned: false,
                lAidAdded: false,
            }
        case types.SAVED_AS_TEMPLATE_TOAST:
            let isSavedTp = false
            if ( action?.payload?.status === 'success' ) {
                isSavedTp = true;
            }
            return {
                ...state,
                isSavedTp: isSavedTp
            }
        case types.LEARNING_AID_CLONED_TOAST:
            let isCloned = false
            if ( action?.payload?.status === 'success' ) {
                isCloned = true;
            }
            return {
                ...state,
                isCloned: isCloned
            }
        case types.TOPIC_UPDATED_TOAST:
            let updated = false
            if ( action?.payload?.status === 'success' ) {
                updated = true;
            }
            return {
                ...state,
                tpUpdated: updated
            }
        case types.GLOBAL_STYLE_UPDATED_TOAST:
            let isGlobalStyle = false
            if ( action?.payload?.status === 'success' ) {
                isGlobalStyle = true;
            }
            return {
                ...state,
                isGlobalStyle: isGlobalStyle
            }
        case types.AUTH_GOES_WRONG:
            let isAuth = false
            if (action?.payload?.status === 'fault' && !state?.authFault) {
                isAuth = true;
            }
            return {
                ...state,
                authFault: isAuth
            }
        case types.SOCKET_WARNING:
                return {
                    ...state,
                    socketWarning: action?.payload
                }
        default:
            return state
    }
}

export default utilityReducer