import './cuepoints.scss'
import AssignmentIcon from '@material-ui/icons/Assignment';
import TabIcon from '@material-ui/icons/Tab';

const CuePoints =  (props) => {  


    const onCueClick=(item)=>{
        props.onClick(item);
    }

    const getImagebyType = (item) =>{
      try{
        let style = {'marginLeft':''};
        style['marginLeft'] = String(calcLeft(item.time));
        let parentEel=props?.parent?.parentEel||{};
        let custom=props?.parent?.cueEle ||{};
        //const existingImg = custom.querySelector(`img[style*="left: ${style.marginLeft}%"]`);
        const existingImg = Array.from(custom?.querySelectorAll('img')).filter((img) =>{
        const roundedNumber = Math.round(style.marginLeft * 10000) / 10000;  
        return img.style.left === `${roundedNumber.toFixed(4)}%`}
        );
        const marginLeftNumber = parseFloat(style.marginLeft);
        // Add your content,such as an image
        if(existingImg?.length ===0 && isFinite(marginLeftNumber) && marginLeftNumber > 0){
        const imgTag = document.createElement("img");
        imgTag.style.cssText = `left: ${style.marginLeft}%`;

        if (item.type === 1) imgTag.src =require('../../../assets/img/cuepoints_window.png').default;
        else if (item.type === 0) imgTag.src =require('../../../assets/img/cuepoints_notes.png').default;

        imgTag.alt = "cue-notes";
        imgTag.onclick = () => onCueClick({...item});
        // Append the <img> tag to the <span> tag
        custom.appendChild(imgTag);
        custom.style.width ="100%";
        parentEel.appendChild(custom);
        }
        // if (item.type === 1) {
        //     return {name:'widget', img: <span >
        //            <img style={style} src={require('../../../assets/img/cuepoints_window.png').default} alt="cue-window"/>
        //         </span> }
        // } else if (item.type === 0) {
        //     return {name:'supplement', img: <span  onClick={()=>onCueClick({...item})}><img  style={style} src={require('../../../assets/img/cuepoints_notes.png').default} alt="cue-notes"/></span>}
        // }
     }catch(e){
        console.error("An error occurred:", e);
     }
    }
    function calcLeft(cuepoint) {
        return ((cuepoint / props?.duration) * 100)-1.5;
        // (cuepoint * 100 / props?.duration) - 2;
}
    let width ,marginPosition,iconWidth
    

    if(props.viewInDevice=="Desktop"){
        // width = "80%"
        // marginPosition = "7%"
        iconWidth ="35px"
    }
    if(props.viewInDevice=="Mobile"){
        // width = "48%"
        // marginPosition = "25%"
        iconWidth ="25px"
    }if(props.viewInDevice=="Laptop"){
        // width = "70%"
        // marginPosition = "11%"
        iconWidth ="30px"
    }if(props.viewInDevice=="Tablet"){
        // width = "59%"
        // marginPosition = "14%"
        iconWidth ="25px"
    }   
    // const renderCueIcon = (time, duration) => {
    //     if (time > duration) {  
    //         return {
    //             display: 'none'
    //         }
    //     }
    // }        
    // <div className="cue-item" key={el.id} style={{'width':'100%' }}>
    //          {getImagebyType(el)?.img}
    //     </div>
    // <div className="cuepoints" style={{width:`${width}`,marginLeft:`${marginPosition}`}}>  </div>
    
    return  <> {props?.data?.length > 0 && props.data.map((el)=>{
            return getImagebyType(el)?.img
        })}
    </>

}

export default CuePoints;